import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NewReceptionMaterialModalPage} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';
import {NewMaterialModalPage} from '@pages/material/components/new-material-modal/new-material-modal.page';
import {NewProviderModalPage} from '@pages/provider/components/new-provider-modal/new-provider-modal.page';
import {NewWarehouseModalPage} from '@pages/warehouse/component/new-warehouse-modal/new-warehouse-modal.page';
import {NewReceptionThroughXlsxPage} from '@pages/reception/components/new-reception-through-xlsx/new-reception-through-xlsx.page';
import {ApprovedMaterialModel} from '@pages/approved-material/_models/approved-material.model';
import {
    NewApprovedMaterialModalPage
} from '@pages/approved-material/components/new-approved-material-modal/new-approved-material-modal.page';
import {AuthService} from '@modules/auth';
import {
    NewReceptionThroughPdfPage
} from "@pages/reception/components/new-reception-through-pdf/new-reception-through-pdf.page";

@Component({
    selector: 'app-new-reception-modal',
    templateUrl: './new-reception-modal.page.html',
    styleUrls: ['./new-reception-modal.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewReceptionModalPage implements OnDestroy {
    id: number;
    hasChange = false;
    ready = false;
    haveProvider = true;
    haveMaterial = true;
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;

    totalScanned = this.currentUser.profile.company.total_delivery_notes;
    monthlyScanned = this.currentUser.profile.company.monthly_delivery_notes;

    constructor(
        private modalController: ModalController,
        private authService: AuthService,
    ) {
    }

    async closeModal() {
        await this.modalController.dismiss(this.hasChange);
        this.authService.getUserByToken();
    }

    async openNewReception() {
        const modal = await this.modalController.create({
            component: NewReceptionMaterialModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.hasChange = true;
            }
        });

        return await modal.present();
    }

    async openNewReceptionXlsx() {
        const modal = await this.modalController.create({
            id: 'NewReceptionThroughXlsx',
            component: NewReceptionThroughXlsxPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.hasChange = true;
            }
        });

        return await modal.present();
    }

    async openNewReceptionPdf() {
        const modal = await this.modalController.create({
            id: 'NewReceptionThroughPdf',
            component: NewReceptionThroughPdfPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.hasChange = true;
            }
        });

        return await modal.present();
    }

    async openMaterial() {
        const modal = await this.modalController.create({
            component: NewMaterialModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        return await modal.present();
    }

    async openProvider() {
        const modal = await this.modalController.create({
            component: NewProviderModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        return await modal.present();
    }

    async openApprovedRawMaterial(approvedMaterial?: ApprovedMaterialModel) {
        const modal = await this.modalController.create({
            component: NewApprovedMaterialModalPage,
            backdropDismiss: false,
            componentProps: {
                id: approvedMaterial ? approvedMaterial.id : undefined,
            },
            cssClass: 'right-side-modal-css',
        });

        return await modal.present();
    }

    async openWarehouse() {
        const modal = await this.modalController.create({
            component: NewWarehouseModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: undefined
            }
        });

        return await modal.present();
    }

    ngOnDestroy(): void {
    }

}
