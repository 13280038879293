import {BaseModel} from '@core/models/_base.model';
import {MaterialModel} from '@pages/material/_models/material.model';

export class CookingRecipeItemModel extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    raw_material: MaterialModel;
    cant: number;

    static jsonToModel(object) {
        const cookingRecipeItemModel = new CookingRecipeItemModel();
        cookingRecipeItemModel.raw_material = MaterialModel.jsonToModel(object.raw_material);
        cookingRecipeItemModel.cant = +object.cant;
        cookingRecipeItemModel.id = object.id;

        return cookingRecipeItemModel;
    }
}
