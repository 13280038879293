import {BaseModel} from '@core/models/_base.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';

export class ProviderModel extends BaseModel {
  id: number;
  name: string;
  address: string;
  enabled = true;
  sanitary_registration_number: string;
  contact_name: string;
  contact_phone: string;
  approved: boolean;
  quantity: number;
  homologate: boolean;
  allergen: any[];
  raw_material_has_provider_id: number;

  static jsonToModel(object) {
    const provider = new ProviderModel();
    if (!object) { return provider; }
    provider.id = object.id;
    provider.name = object.name;
    provider.enabled = object.enabled;
    provider.address = object.address;
    provider.sanitary_registration_number = object.sanitary_registration_number;
    provider.contact_name = object.contact_name;
    provider.contact_phone = object.contact_phone;
    provider.approved = object.approved;
    provider.quantity = +object.quantity;
    provider.homologate = object?.homologate || false;
    provider.allergen = object?.allergen || [];
    provider.raw_material_has_provider_id = object?.raw_material_has_provider_id || null;

    return provider;
  }
}
