import {BaseModel} from '@core/models/_base.model';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {CookingRecipeModel} from '@pages/cooking-recipe/_models/cooking-recipe.model';
import {ProductionItem} from '@pages/production/_models/productionItem.model';
import {MaterialModel} from '@pages/material/_models/material.model';
import {LotModel} from '@pages/lot/_models/lot.model';
import {AllergenModel} from '@common/_models/allergen.model';
import {AllergenItemModel} from '@common/_models/allergenItem.model';
import {format} from "date-fns";

export class ProductionModel extends BaseModel {
    id: number;
    // tslint:disable-next-line:variable-name
    total: number;
    // tslint:disable-next-line:variable-name
    decrease: number;
    // tslint:disable-next-line:variable-name
    warehouse: WarehouseModel;
    // tslint:disable-next-line:variable-name
    cooking_recipe: CookingRecipeModel;
    // tslint:disable-next-line:variable-name
    lot: LotModel;
    // tslint:disable-next-line:variable-name
    checklist_id: number;
    // tslint:disable-next-line:variable-name
    production_items: ProductionItem[];

    allergens: AllergenItemModel[];
    planned: boolean;
    plannedProduced: boolean;

    // tslint:disable-next-line:variable-name
    date_expiry: any;

    static jsonToModel(object) {
        // tslint:disable-next-line:variable-name
        const cooking_recipe = CookingRecipeModel.jsonToModel(object.cooking_recipe);
        const lot = LotModel.jsonToModel(object.lot);
        const warehouse = WarehouseModel.jsonToModel(object.warehouse);
        const production = new ProductionModel();
        production.id = object.id;
        production.total = +object.total;
        production.decrease = object.decrease;
        production.cooking_recipe = cooking_recipe;
        production.warehouse = warehouse;
        production.lot = lot;
        production.date_expiry = new Date(object.date_expiry);
        production.checklist_id = object.checklist_id;
        production.planned = object.planned;
        production.plannedProduced = object.plannedProduced;
        production.production_items = [];
        production.allergens = [];
        object?.production_items?.forEach((item: any) => {
            const productionItem = ProductionItem.jsonToModel(item);
            production.production_items.push(productionItem);
        });
        object?.allergens?.forEach((item: any ) => {
           const allergenItem = AllergenItemModel.jsonToModel(item);
           production.allergens.push(allergenItem);
        });

        return production;
    }

    saveToAPI(){
        const toJson = {
            id: this.id,
            lot_id: this.lot.id,
            cooking_recipe_id: this.cooking_recipe.id,
            warehouse_id: this.warehouse.id,
            total: this.total,
            decrease: this.decrease,
            planned: this.planned,
            plannedProduced: this.plannedProduced,
            date_expiry: format(this.date_expiry, 'yyyy-MM-dd'),
            production_items: []
        };

        toJson.production_items = this.production_items.map((item) => {
            const production_item =  {
                id: item?.id,
                cant: item.cant,
                receptions_items: []
            };
            production_item.receptions_items = item.production_item_has_reception_items.map((item) => {
                return {
                    id: item?.id,
                    cant: item.cant,
                    reception_item_id: item.reception_item.id
                };
            });
            return production_item;
        });

        return toJson;
    }
}
