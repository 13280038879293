export class Media {
    id: number;
    mediaInfo: any;
    reference: string;
    square: string;
    small: string;
    medium: string;
    big: string;

    constructor() {
        this.id = null;
        this.mediaInfo = null;
        this.reference = null;
        this.square = null;
        this.small = null;
        this.medium = null;
        this.big = null;
        return this;
    }

    static jsonToModel(object) {
        const media = new Media();
        media.id = object?.id;
        media.mediaInfo = object?.media;
        media.reference = object?.reference;
        media.square = object?.square;
        media.small = object?.small;
        media.medium = object?.medium;
        media.big = object?.big;
        return media;
    }

}

