import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatStepper} from '@angular/material/stepper';
import {DeliveryService} from '@pages/delivery/_services';
import {DeliveryModel} from '@pages/delivery/_models/delivery.model';
import {catchError, first} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {Subscription, throwError} from 'rxjs';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {returnCantValidators} from '@pages/delivery/validators/returnCantValidators';
import {DeliveryReturnModel} from '@pages/delivery/_models/deliveryReturn.model';
import {DeliveryReturnItemModel} from '@pages/delivery/_models/deliveryReturnItem.model';
import {DeliveryReturnService} from '@pages/delivery/_services/deliveryReturn.service';

@Component({
    selector: 'app-new-delivery-return-modal',
    templateUrl: './new-delivery-return-modal.page.html',
    styleUrls: ['./new-delivery-return-modal.page.scss'],
})
export class NewDeliveryReturnModalPage implements OnInit, OnDestroy {
    deliveryId: number;

    isLoading$;
    delivery: DeliveryModel;
    deliveryReturn: DeliveryReturnModel;
    updated = false;

    formGroup: UntypedFormGroup;
    cantSelect = new UntypedFormArray([]);

    @ViewChild('stepper') stepper: MatStepper;

    private subscriptions: Subscription[] = [];

    constructor(
        private modalController: ModalController,
        private deliveryService: DeliveryService,
        private deliveryReturnService: DeliveryReturnService,
        private notificationService: NotificationService,
        public typesUtils: TypesUtilsService,
        private fb: UntypedFormBuilder,
    ) {
    }

    ngOnInit() {
        this.isLoading$ = this.deliveryService.isLoading$;
        this.loadDelivery();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadForm(){
        this.formGroup = this.fb.group({
            id: [this.delivery.id],
            cantSelect: this.fb.array([]),
        });
        this.cantSelect = this.formGroup.get('cantSelect') as UntypedFormArray;

        // tslint:disable-next-line:variable-name
        this.delivery.delivery_items.forEach((delivery_item) => {
            const control = this.fb.group({
                id: [delivery_item.id],
                items:  this.fb.array([])
            });
            const controlItem = control.get('items') as UntypedFormArray;
            delivery_item.delivery_item_has_reception_items.forEach((item) => {
                const formItem = this.fb.group({
                    reception_item: [item.reception_item],
                    cantStock: [0],
                    cantDecrease: [0],
                }, {validators: returnCantValidators((item.cant * 1))});
                controlItem.push(formItem);
            });
            this.cantSelect.push(control);
        });
    }

    private prepareDeliveryReturn() {
        this.deliveryReturn = new DeliveryReturnModel();
        this.deliveryReturn.delivery = this.delivery;
        const formData = this.formGroup.value;
        this.deliveryReturn.delivery_return_items = [];
        formData.cantSelect.map((item: any) => {
            this.deliveryReturn.delivery_return_items.push({
                delivery_return_item_has_reception_items: item.items,
            } as DeliveryReturnItemModel);
        });
    }

    save() {
        this.prepareDeliveryReturn();
        this.create();
    }

    private create() {
        const sbCreate = this.deliveryReturnService.createDeliveryReturn(this.deliveryReturn).pipe(
            catchError((errorMessage) => {
                this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                return throwError(errorMessage);
            }),
        ).subscribe(async (res: DeliveryReturnModel) => {
            this.deliveryReturn = res;
            this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
            await this.modalController.dismiss(true);
        });
        this.subscriptions.push(sbCreate);
    }

    loadDelivery() {
        if (!this.deliveryId) {
            this.delivery = new DeliveryModel();
        } else {
            const sb = this.deliveryService.getItemById(this.deliveryId).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((delivery: DeliveryModel) => {
                this.delivery = DeliveryModel.jsonToModel(delivery);
                this.loadForm();
            });
            this.subscriptions.push(sb);
        }
    }

    async closeModal() {
        await this.modalController.dismiss(this.updated);
    }

    returnAllStock(){
        this.delivery.delivery_items.forEach((delivery_item, i) => {
            const form = (this.formGroup.get('cantSelect') as UntypedFormArray).controls[i];
            delivery_item.delivery_item_has_reception_items.forEach((item, x) => {
                (form.get('items') as UntypedFormArray).controls[x].patchValue({cantStock: (item.cant * 1), cantDecrease: 0});
            });
        });
    }

    returnAllDecrease(){
        this.delivery.delivery_items.forEach((delivery_item, i) => {
            const form = (this.formGroup.get('cantSelect') as UntypedFormArray).controls[i];
            delivery_item.delivery_item_has_reception_items.forEach((item, x) => {
                (form.get('items') as UntypedFormArray).controls[x].patchValue({cantStock: 0, cantDecrease: (item.cant * 1)});
            });
        });
    }

    isFormArrayInvalid(controlName: string, position: number, controlName2: string, position2: number, field): boolean {
        const form = (this.formGroup.get(controlName) as UntypedFormArray).controls[position];
        const formError = (form.get(controlName2) as UntypedFormArray).controls[position2].errors;
        return formError && formError[field];
    }

    isFormArrayValid(controlName: string, position: number, controlName2: string, position2: number, field): boolean {
        const form = (this.formGroup.get(controlName) as UntypedFormArray).controls[position];
        const formError = (form.get(controlName2) as UntypedFormArray).controls[position2].errors;
        return !formError;
    }

    getFormControlCanDecrease(index1: number, index2: number){
        return (this.cantSelect.controls[index1].get('items') as UntypedFormArray).controls[index2].get('cantDecrease');
    }

    getFormControlCanStock(index1: number, index2: number){
        return (this.cantSelect.controls[index1].get('items') as UntypedFormArray).controls[index2].get('cantStock');
    }
}
