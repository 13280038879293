import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NotificationModel} from '@modules/notifications/_models/notification.model';
import {NotificationService} from '@modules/notifications/_services';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {finalize} from 'rxjs/operators';
import {MessagingService} from '@modules/notifications/_services/messaging.service';
import { AuthService } from "@modules/auth";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.page.html',
    styleUrls: ['./notifications.page.scss'],
})
export class NotificationsPage implements OnInit, OnDestroy {
    notifications: NotificationModel[] = [];
    page = 0;
    isLoading$;
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;

    constructor(private modalController: ModalController,
                private notificationService: NotificationService,
                private meesageService: MessagingService,
                private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.isLoading$ = this.notificationService.isLoading$;

        const queryParams = new QueryParamsModel('', 'asc', '', this.page, 25, {});
        this.notificationService.getItems(queryParams).subscribe((response) => {
            this.notifications = response.results?.map((item: NotificationModel) => NotificationModel.jsonToModel(item));
        });
    }

    loadNotifications(event) {
        this.page = this.page + 1;
        setTimeout(() => {
            const queryParams = new QueryParamsModel('', 'asc', '', this.page, 25, {});
            this.notificationService.getItems(queryParams).pipe(
                finalize(() => event.target.complete())
            ).subscribe((response) => {
                if (response?.results){
                    this.notifications = this.notifications.concat(
                        response.results?.map(
                            (item: NotificationModel) => NotificationModel.jsonToModel(item)
                        )
                    );
                }
            });

        }, 500);
    }

    async closeModal() {
        await this.modalController.dismiss(false);
    }

    removeNotification(notificationId: number) {
        this.notifications = this.notifications.filter((item) => item.id !== notificationId);
        this.notificationService.markAsDelete(notificationId).subscribe((_) => {});
        this.meesageService.emitChanges(); // for reload badge
    }

    ngOnDestroy(): void {
        this.notifications.forEach((item) => {
            if (!item.isRead) {
            this.notificationService.markAsRead(item.id).subscribe((_) => {});
            }
        });
        this.meesageService.emitChanges(); // for reload badge
    }
}
