import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import * as _moment from 'moment';
const moment = _moment;

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'dd/MM/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'dd/MM/yyyy',
        monthYearA11yLabel: 'MMMM yyyy',
    },
};

@Injectable()

export class CustomDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }

    getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
        let momentLocaleData = moment.localeData('es');
        return momentLocaleData.weekdaysMin();
    }

    parse(value: string): Date {
        const parsedDate = /\d{1,2}\/\d{1,2}\/\d{4}/.test(value) ? value : null;

        if (parsedDate) {
            const [parsedDay, parsedMonth, parsedYear] = parsedDate?.split('/');
            const day = Number(parsedDay);
            const month = Number(parsedMonth) - 1;
            const year = Number(parsedYear);

            if (day > 31 || month > 12) {
                return null
            }  
            
            return new Date(year, month, day);
        } else {
            return null
        }
    }


    format(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    isValid(date: Date): boolean {
        return super.isValid(date);
    }

}
