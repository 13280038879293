import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {ProviderModel} from '../_models/provider.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';

@Injectable({
    providedIn: 'root'
})
export class ProviderService {
    API_URL = `${environment.api_host}/api/provider/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    private _isLoadingOnboarding$ = new BehaviorSubject<null | boolean>(null);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    findProviders(queryParams?: QueryParamsModel): Observable<QueryResultsModel<ProviderModel>> {
        this._isLoading$.next(true);
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        if (queryParams?.sortField.length > 0 && queryParams?.sortOrder.length > 0) {
            params = params.append('sort_direction', queryParams ? queryParams?.sortOrder : '');
            params = params.append('sort_field', queryParams ? queryParams?.sortField : '');
        }

        if ((queryParams?.search?.length) || 0 > 0 ) {
            params = params.append('search_term', queryParams ? queryParams?.search : '');
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<ProviderModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<ProviderModel>) => {
                const queryResults = new QueryResultsModel<ProviderModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }),
            finalize(() => {
                this._isLoading$.next(false);
                if (this._isLoadingOnboarding$.getValue() === true) {
                    this._isLoadingOnboarding$.next(false);
                }

            })
        );
    }

    createProvider(provider: ProviderModel) {
        this._isLoading$.next(true);
        this._isLoadingOnboarding$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ProviderModel>(url, provider, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createProviderThroughXlsx(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-through-xlsx`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM ', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateProvider(provider: ProviderModel): Observable<ProviderModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<ProviderModel>(url, provider, {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', provider, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<ProviderModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<ProviderModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    validationName(value: string, id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-name/?name=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(true);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getTemplateXlsx(): any {
        const url = this.API_URL + `download-template-create`;
        return this.http.get(url, {responseType: 'blob'});
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    get isLoadingOnboarding$() {
        return this._isLoadingOnboarding$.asObservable();
    }
}
