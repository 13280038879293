import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription, throwError} from 'rxjs';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {catchError} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {ReceptionService} from '@pages/reception/_services';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import {DetailReceptionMaterialModalPage} from '@pages/reception/components/detail-reception-material-modal/detail-reception-material-modal.page';
import {WarehouseService} from '@pages/warehouse/_services';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import { AuthService } from "@modules/auth";

@Component({
  selector: 'app-new-warehouse-though-xlsx',
  templateUrl: './new-warehouse-though-xlsx.page.html',
  styleUrls: ['./new-warehouse-though-xlsx.page.scss'],
})
export class NewWarehouseThoughXlsxPage implements OnInit {
  private subscriptions: Subscription[] = [];
  warehouse: WarehouseModel;
  uploadForm: UntypedFormGroup;
  hasChange = false;
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;
  
  @ViewChild('myPond') myPond: any;
  @ViewChild('stepper') private stepper: MatStepper;

  pondOptions = {
    credits: false,
    allowMultiple: false,
    labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        this.uploadForm.get('profile').setValue(file);
        const formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);

        const sbCreate = this.warehouseService.createWarehouseThroughXlsx(formData).pipe(
            catchError((errorMessage) => {
              console.log(errorMessage);
              this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
              error();
              return throwError(errorMessage);
            }),
        ).subscribe(async (res: WarehouseModel) => {
          this.warehouse = res;
          this.hasChange = true;
          this.stepper.next();
          await this.wait(2500);
          await this.modalController.dismiss(true, undefined, 'NewWarehouseThoughXlsxPage');
        });
        this.subscriptions.push(sbCreate);

      }
    }
  }

  wait(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  constructor(
      private warehouseService: WarehouseService,
      private modalController: ModalController,
      private translate: TranslateService,
      private formBuilder: UntypedFormBuilder,
      private notificationService: NotificationService,
      private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  async closeModal() {
    await this.modalController.dismiss(this.hasChange);
  }

  download() {
    this.warehouseService.getTemplateXlsx().subscribe(response => {
      let blob: any = new Blob([response]);
      fileSaver.saveAs(blob, 'warehouse.xlsx');
    }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
  }

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event)
  }

}
