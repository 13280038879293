import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NewMaterialModalPage} from '@pages/material/components/new-material-modal/new-material-modal.page';
import {NewProviderModalPage} from '@pages/provider/components/new-provider-modal/new-provider-modal.page';
import {ProviderService} from '@pages/provider/_services';
import {ApprovedMaterialModel} from '../../_models/approved-material.model';
import {MasterAllergenService} from '@common/_services';
import {AllergenModel} from '@common/_models/allergen.model';
import {MaterialService} from '@pages/material/_services';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ApprovedMaterialService} from '@pages/approved-material/_services';
import {of, Subscription, throwError} from 'rxjs';
import {catchError, debounceTime, finalize, first, switchMap, tap} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import { AuthService } from "@modules/auth";

@Component({
    selector: 'app-new-approved-material-modal',
    templateUrl: './new-approved-material-modal.page.html',
    styleUrls: ['./new-approved-material-modal.page.scss'],
})
export class NewApprovedMaterialModalPage implements OnInit, OnDestroy {
    id: number;
    material: MaterialModel;
    provider: ProviderModel;
    isLoading$;
    isSearchProvider = true;
    isSearchMaterial = true;

    filteredMaterialOptions: MaterialModel[];
    filteredProviderOptions: ProviderModel[];

    selectedProvider: ProviderModel;
    selectedRawMaterial: MaterialModel;

    approvedMaterial: ApprovedMaterialModel;
    materialList: MaterialModel[];
    providerList: ProviderModel[];
    allergensList: any = [];
    formGroup: UntypedFormGroup;
    private subscriptions: Subscription[] = [];
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;

    readyAllergens = false;

    constructor(
        private modalController: ModalController,
        private allergenService: MasterAllergenService,
        private materialService: MaterialService,
        private providerService: ProviderService,
        private approvedMaterialService: ApprovedMaterialService,
        private fb: UntypedFormBuilder,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.isLoading$ = this.approvedMaterialService.isLoading$;
        this.loadApprovedMaterial();
        this.loadRawMaterialList();
        this.loadProviderList();
        // Load First Material And Provider
        this.loadFirstMaterial();
        this.loadFirstProvider();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadApprovedMaterial() {
        if (!this.id) {
            this.approvedMaterial = new ApprovedMaterialModel();
            if (this.material) {
                this.approvedMaterial.raw_material = MaterialModel.jsonToModel(this.material);
            }
            if (this.provider) {
                this.approvedMaterial.provider = ProviderModel.jsonToModel(this.provider);
            }
            this.loadForm();
            if (this.provider) {
                this.formGroup.controls['provider'].disable();
            }
            this.loadAllergensList();
        } else {
            const sb = this.approvedMaterialService.getItemById(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((approvedMaterial: ApprovedMaterialModel) => {
                this.approvedMaterial = ApprovedMaterialModel.jsonToModel(approvedMaterial);
                this.loadForm();
                this.loadAllergensList();
                this.formGroup.controls['provider'].disable();
            });
            this.subscriptions.push(sb);

        }
    }

    async closeModal() {
        await this.modalController.dismiss(false);
    }

    loadForm() {
        const control = this.fb.group({
            raw_material: [this.approvedMaterial.raw_material, Validators.compose([Validators.required])],
            provider: [this.approvedMaterial.provider, Validators.compose([Validators.required])],
        },);
        //selector de materia prima siempre bloqueado
        control.controls['raw_material'].disable();

        this.formGroup = control;

        control?.get('provider').valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isSearchProvider = true),
            switchMap(value => this.providerService.findProviders(new QueryParamsModel(value, 'asc', '', 0, 50,)).pipe(
                finalize(() => this.isSearchProvider = false),
            ))
        ).subscribe(data => this.filteredProviderOptions = data.results);

        control?.get('raw_material').valueChanges.pipe(
            debounceTime(300),
            tap(() => this.isSearchMaterial = true),
            switchMap(value => this.materialService.findMaterials(new QueryParamsModel(value, 'asc', '', 0, 50, {show_products: false})).pipe(
                finalize(() => this.isSearchMaterial = false),
            ))
        ).subscribe(data => this.filteredMaterialOptions = data.results);
    }

    save() {
        this.prepareApprovalMaterial();
        this.create();
    }

    create() {
        const sbCreate = this.approvedMaterialService.createApprovedMaterials(this.approvedMaterial).pipe(
            catchError((errorMessage) => {
                console.log(errorMessage);
                this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                return throwError(errorMessage);
            }),
        ).subscribe(async (res: ApprovedMaterialModel) => {
            this.approvedMaterial = res;
            this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
            await this.modalController.dismiss(true);
        });
        this.subscriptions.push(sbCreate);
    }

    private prepareApprovalMaterial() {
        if (this.selectedProvider) {
            this.approvedMaterial.provider = this.selectedProvider;
        }
        if (this.selectedRawMaterial) {
            this.approvedMaterial.raw_material = this.selectedRawMaterial;
        }
        this.approvedMaterial.allergens = this.allergensList;
    }

    onClick(allergen) {
        if (allergen.status === 1 || allergen.status === 2) {
            this.allergensList.find((item) => item.id === allergen.id).status = 0;
        } else {
            this.allergensList.find((item) => item.id === allergen.id).status = 1;
        }
    }

    onDblClick(allergen) {
        if (allergen.status === 2) {
            this.allergensList.find((item) => item === allergen).status = 0;
        } else {
            this.allergensList.find((item) => item === allergen).status = 2;
        }
    }

    setProvider(value: ProviderModel) {
        this.selectedProvider = value;
        this.formGroup.patchValue({
            provider: value?.name,
        });
        this.formGroup.get('provider').markAsTouched();
    }

    setRawMaterial(value: MaterialModel) {
        this.selectedRawMaterial = value;
        this.formGroup.patchValue({
            raw_material: value?.name,
        });
        this.formGroup.get('raw_material').markAsTouched();
    }

    onSelectAutoCompleted(event, field: string) {
        if (field === 'provider') {
            const value = event.option.value;
            this.setProvider(value);
        }
        if (field === 'raw_material') {
            const value = event.option.value;
            this.setRawMaterial(value);
        }
    }


    async newMaterial() {
        const name = this.getValue('raw_material');
        const modal = await this.modalController.create({
            component: NewMaterialModalPage,
            backdropDismiss: false,
            componentProps: {
                id: undefined,
                name
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.setRawMaterial(response.data?.raw_material);
                this.loadRawMaterialList();
            }
        });

        return await modal.present();
    }

    async newProvider() {
        const name = this.getValue('provider');
        const modal = await this.modalController.create({
            component: NewProviderModalPage,
            backdropDismiss: false,
            componentProps: {
                id: undefined,
                name
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.setProvider(response.data?.provider);
                this.loadProviderList();
            }
        });

        return await modal.present();
    }

    loadAllergensList() {
        this.allergenService.findAllergen().subscribe(
            (data) => {
                if (this.approvedMaterial.allergens && this.approvedMaterial.allergens.length !== 0) {
                    this.approvedMaterial.allergens.map((allergen) => {
                        this.allergensList.push({
                            id: allergen.allergen_id,
                            allergen_id: allergen.allergen_id,
                            name: data.find((al: AllergenModel) => allergen.allergen_id === al.id).name,
                            status: allergen.contain ? 1 : allergen.traces ? 2 : 0,
                        });
                    });
                } else {
                    data.map((allergen: any) =>
                        this.allergensList.push({
                            id: allergen.id,
                            allergen_id: allergen.id,
                            name: allergen.name,
                            status: 0,
                        })
                    );
                }
                this.readyAllergens = true;
            },
            (error) => {
                console.log('ERROR:' + error);
            }
        );
    }


    loadFirstProvider() {
        const loadFirstProvider = this.providerService.findProviders(new QueryParamsModel('', 'asc', '', 0, 25)).pipe(
            finalize(() => this.isSearchProvider = false),
        ).subscribe(data => this.filteredProviderOptions = data.results);
        this.subscriptions.push(loadFirstProvider);
    }

    loadFirstMaterial() {
        const loadFirstMaterial = this.materialService.findMaterials(
            new QueryParamsModel('', 'asc', '', 0, 25, {show_products: false})
        ).pipe(
            finalize(() => this.isSearchMaterial = false),
        ).subscribe(data => this.filteredMaterialOptions = data.results);
        this.subscriptions.push(loadFirstMaterial);
    }


    loadRawMaterialList() {
        this.materialService.findMaterials(new QueryParamsModel('', 'asc', '', 0, 100, {show_products: false})).subscribe(
            (data) => {
                this.materialList = data.results;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    loadProviderList() {
        this.providerService.findProviders().subscribe(
            (data) => {
                this.providerList = data.results;
            },
            (error) => {
                console.log('ERROR:' + error);
            }
        );
    }

    // helpers for View
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation, controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    isControlTouched(controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }

    objectComparisonFunction(option, value): boolean {
        return option?.id === value?.id;
    }

    getValue(field: string): any {
        const control = this.formGroup.get(field);
        return control.value;
    }

    _displayWithName = () => (value: any | string): string => {
        return !value
            ? ''
            : typeof value === 'string'
                ? value
                : value.name;
    }
}
