import {BehaviorSubject, of} from 'rxjs';
import {WarehouseService} from '../_services';
import {catchError, finalize, tap} from 'rxjs/operators';
import {BaseDataSource} from '@core/models/data-source/_base.datasource';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';

export class WarehouseMaterialDatasource extends BaseDataSource {


    constructor(private warehouseService: WarehouseService) {
        super();
    }

    loadWarehouseMaterials(
        queryParams: QueryParamsModel
    ) {
        this.loadingSubject.next(true);
        this.warehouseService.findWarehouseRawMaterials(queryParams).pipe(
            tap(res => {
                this.entitySubject.next(res.results);
                this.paginatorTotalSubject.next(res.total);
            }),
            catchError(err => of(new QueryResultsModel([], err))),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe();
    }
}
