import { AuthModel } from './auth.model';
import {CompanyModel} from '@modules/auth/register-company/_models/company.model';

export interface UpdateProfileRequest {
  profile: {
    firstName: string;
    lastName: string;
    email: string;
    showOnboarding: boolean;
  };
}
export class UserModel extends AuthModel {
  id: number;
  profile: {
    username: string,
    email: string,
    first_name: string,
    last_name: string,
    roles: string[],
    company: CompanyModel,
    have_company: boolean,
    enabled: boolean,
    force_change_password: boolean,
    show_onboarding: boolean,
  };

  static jsonToModel(object) {
    const user = new UserModel();
    user.id = object.id;
    user.profile.username = object.username;
    user.profile.email = object.email;
    user.profile.first_name = object.first_name;
    user.profile.last_name = object.last_name;
    user.profile.roles = object.roles;
    user.profile.company = object.company;
    user.profile.have_company = object.have_company;
    user.profile.enabled = object.enabled;
    user.profile.force_change_password = object.force_change_password;
    user.profile.show_onboarding = object.show_onboarding;
    return user;
  }

  saveToAPI(){
    return {
      id: this.id,
      profile: {
        username: this.profile.username,
        email: this.profile.email,
        first_name: this.profile.first_name,
        last_name: this.profile.last_name,
        roles: this.profile.roles,
        company: this.profile.company,
        have_company: this.profile.have_company,
        enabled: this.profile.enabled,
      }

    };
  }

  setUser(user: any) {
    this.id = user.id;
    this.profile = user.profile;
  }
}
