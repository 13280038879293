
import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {SplashScreenService} from '@core/components/splash-screen/splash-screen.service';
import {TranslationService} from '@modules/i18n/translation.service';
import { locale as esLang } from '@modules/i18n/vocabs/es';
import { locale as enLang } from '@modules/i18n/vocabs/en';
@Component({
    selector: 'app-root', 
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy{
    private unsubscribe: Subscription[] = [];
    constructor(
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private router: Router,
    ) {
        this.translationService.loadTranslations(esLang, enLang);
    }

    ngOnInit() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
