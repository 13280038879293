import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, switchMap, finalize, tap } from 'rxjs/operators';
import { UpdateProfileRequest, UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import {RegisterModel} from '@modules/auth/register/_models/register.model';
import {CompanyModel} from '@modules/auth/register-company/_models/company.model';
import {ChangePasswordModel} from '@modules/auth/change-password/_models/change_password.model';
import { HttpStatusCode } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
        map((auth: AuthModel) => {
          const result = this.setAuthFromLocalStorage(auth);
          return result;
        }),
        switchMap(() => this.getUserByToken()),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }


  loginWithGoogle(id_token: string, access_token: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.loginWithGoogle(id_token, access_token).pipe(
        map((auth: AuthModel) => {
          const result = this.setAuthFromLocalStorage(auth);
          return result;
        }),
        switchMap(() => this.getUserByToken()),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }

  register(register: RegisterModel): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.register(register).pipe(
        map((auth: AuthModel) => {
          const result = this.setAuthFromLocalStorage(auth);
          return result;
        }),
        switchMap(() => this.getUserByToken()),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }

  registerCompany(company: CompanyModel): Observable<CompanyModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.registerCompany(company).pipe(
        map((Company: CompanyModel) => {
          const currentUser = this.currentUserValue;
          currentUser.profile.have_company = true;
          this.currentUserSubject = new BehaviorSubject<UserModel>(currentUser);
          return Company;
        }),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }

  changePassword(changePassword: ChangePasswordModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.changePassword(changePassword).pipe(
        map(() => {
          const currentUser = this.currentUserValue;
          currentUser.profile.force_change_password = false;
          this.currentUserSubject = new BehaviorSubject<UserModel>(currentUser);
        }),
        finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    // Clean object user
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    // remove session and storage data
    localStorage.removeItem(this.authLocalStorageToken);
    // redirect
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {

    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  updateProfile(status: boolean): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    const value = this.currentUserValue;
    value.profile.show_onboarding = status;

    const requestBody: UpdateProfileRequest = {
      profile: {
        firstName: value.profile.first_name,
        lastName: value.profile.last_name,
        email: value.profile.email,
        showOnboarding: status,
      },
    };

    return this.authHttpService.updateUser(requestBody).pipe(
      tap((response: any) => {
        if (response.status === HttpStatusCode.Ok) {
          this.currentUserSubject.next(value);
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
}
