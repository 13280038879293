import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription, throwError} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {catchError} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import {MaterialModel} from '@pages/material/_models/material.model';
import {MaterialService} from '@pages/material/_services';
import { AuthService } from "@modules/auth";

@Component({
  selector: 'app-new-material-though-xlsx',
  templateUrl: './new-material-though-xlsx.page.html',
  styleUrls: ['./new-material-though-xlsx.page.scss'],
})
export class NewMaterialThoughXlsxPage implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  material: MaterialModel;
  uploadForm: UntypedFormGroup;
  hasChange = false;
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;
  
  @ViewChild('myPond') myPond: any;
  @ViewChild('stepper') private stepper: MatStepper;

  pondOptions = {
    credits: false,
    allowMultiple: false,
    labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        this.uploadForm.get('file').setValue(file);
        const formData = new FormData();
        formData.append('file', this.uploadForm.get('file').value);

        const sbCreate = this.materialService.createMaterialThroughXlsx(formData).pipe(
            catchError((errorMessage) => {
              console.log(errorMessage);
              this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
              error();
              return throwError(errorMessage);
            }),
        ).subscribe(async (res: MaterialModel) => {
          this.material = res;
          this.hasChange = true;
          this.stepper.next();
          await this.wait(2500);
          await this.modalController.dismiss(true, undefined, 'NewMaterialThoughXlsxPage');
        });
        this.subscriptions.push(sbCreate);

      }
    }
  }

  wait(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  constructor(
      private materialService: MaterialService,
      private modalController: ModalController,
      private translate: TranslateService,
      private formBuilder: UntypedFormBuilder,
      private notificationService: NotificationService,
      private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      file: ['']
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  async closeModal() {
    await this.modalController.dismiss(this.hasChange);
  }

  download() {
    this.materialService.getTemplateXlsx().subscribe(response => {
      let blob: any = new Blob([response]);
      fileSaver.saveAs(blob, 'raw_material.xlsx');
    }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
  }

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event)
  }

}
