import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {LotModel} from '../../_models/lot.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import {catchError, first, tap} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {nameAsyncValidator} from '@core/validators/AsyncName.validators';
import {LotService} from '@pages/production/_services';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from '@core/adapters/custom-date-adapter.service';

@Component({
    selector: 'app-new-lot-modal',
    templateUrl: './new-lot-modal.page.html',
    styleUrls: ['./new-lot-modal.page.scss'],
    providers: [
        {
            provide: DateAdapter, 
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
    ]
})
export class NewLotModalPage implements OnInit, OnDestroy {
    id: number;
    name: string; // Si se envía el parámetro al mostrar el modal se toma como nombre para inicializar el formulario
    isLoading$;
    lot: LotModel;
    formGroup: UntypedFormGroup;

    private subscriptions: Subscription[] = [];

    constructor(private modalController: ModalController,
                private fb: UntypedFormBuilder,
                private lotService: LotService,
                private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.isLoading$ = this.lotService.isLoading$;
        this.loadLot();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadLot() {
        if (!this.id) {
            this.lot = new LotModel();
            this.loadForm();
        } else {
            const sb = this.lotService.getItemById(this.id).pipe(
                first(),
                catchError((errorMessage) => {
                    console.log('error', errorMessage);
                    return of(new LotModel());
                })
            ).subscribe((lotModel: LotModel) => {
                this.lot = LotModel.jsonToModel(lotModel);
                this.loadForm();
            });
            this.subscriptions.push(sb);
        }
    }

    async closeModal() {
        await this.modalController.dismiss(false);
    }

    loadForm() {
        this.formGroup = this.fb.group({
            name: [this.id ? this.lot.name : this.name,
                Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
                [nameAsyncValidator(this.lotService, this.lot.id)]
            ],
            date_of_create: [this.lot?.date_of_create, Validators.compose([Validators.required])],
            date_of_close: [this.lot?.date_of_close, Validators.compose([Validators.required])],
        });
    }

    save() {
        this.prepareLot();
        if (this.lot.id) {
            this.edit();
        } else {
            this.create();
        }
    }

    edit() {
        const sbUpdate = this.lotService.updateLot(this.lot).pipe(
            tap(async () => {
                this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
                await this.modalController.dismiss(true);
            }),
            catchError((errorMessage) => {
                this.notificationService.showActionNotification('COMMON.DATA_UPDATED_ERROR', MessageType.Error);
                return of(this.lot);
            }),
        ).subscribe(res => this.lot = res);
        this.subscriptions.push(sbUpdate);
    }

    create() {
        const sbCreate = this.lotService.createLot(this.lot).pipe(
            catchError((errorMessage) => {
                this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                return of(this.lot);
            }),
        ).subscribe(async (res: LotModel) => {
            this.lot = res;
            this.notificationService.showActionNotification('COMMON.DATA_CREATED', MessageType.Success);
            await this.modalController.dismiss({lot: this.lot});
        });
        this.subscriptions.push(sbCreate);
    }

    private prepareLot() {
        const formData = this.formGroup.value;
        this.lot.name = formData.name;
        this.lot.date_of_create = new Date(formData.date_of_create);
        this.lot.date_of_close = new Date(formData.date_of_close);
    }

    // helpers for View
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation, controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    isControlTouched(controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.dirty || control.touched;
    }
}
