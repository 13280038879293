import {Component, OnInit, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {of, Subscription, throwError} from 'rxjs';
import {ReceptionService} from '@pages/reception/_services';
import {catchError, first} from 'rxjs/operators';
import {
    NewReceptionMaterialModalPage,
} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {ChecklistComponent} from '@core/components/checklist/checklist.component';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import { AuthService } from '@modules/auth';


export interface Observations {
    date: Date;
    text: string;
}
@Component({
    selector: 'app-detail-reception-material-modal',
    templateUrl: './detail-reception-material-modal.page.html',
    styleUrls: ['./detail-reception-material-modal.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DetailReceptionMaterialModalPage implements OnInit, OnDestroy {

    id: number;
    isLoading$;
    reception: ReceptionModel;
    updated = false;
    checklist = false;
    editable = true;
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;

    private subscriptions: Subscription[] = [];
    observations: Observations[] = [];


    constructor(
        private modalController: ModalController,
        private receptionService: ReceptionService,
        public typesUtils: TypesUtilsService,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.isLoading$ = this.receptionService.isLoading$;
        this.loadReception();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    async closeModal() {
        await this.modalController.dismiss(this.updated);
    }

    async editChecklist() {
        const modal = await this.modalController.create({
            component: ChecklistComponent,
            backdropDismiss: false,
            componentProps: {
                idChecklist: 1,
                idResultChecklist: this.reception.checklist_id,
                entity: 'RECEPTION',
                entity_id: this.reception.id,
                action: 'detail',
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((response) => {
            this.updated = response.data;
        });

        return await modal.present();
    }


    loadReception() {
        if (!this.id) {
            this.reception = new ReceptionModel();
        } else {
            const sb = this.receptionService.getItemById(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((reception: ReceptionModel) => {
                this.reception = ReceptionModel.jsonToModel(reception);
            });
            this.subscriptions.push(sb);
        }
    }

    async editReception() {

        const modal = await this.modalController.create({
            component: NewReceptionMaterialModalPage,
            cssClass: 'right-side-modal-css',
            backdropDismiss: false,
            componentProps: {
                id: this.id
            }
        });

        modal.onDidDismiss().then((response) => {
            this.updated = this.updated || !!response.data;
            if (response.data) {
                this.loadReception();
            }
        });

        return await modal.present();
    }
}
