import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashScreenComponent } from './splash-screen.component';
import {IonicModule} from '@ionic/angular';
import {CoreComponentsModule} from '@core/components/core-components.module';

@NgModule({
  declarations: [SplashScreenComponent],
  imports: [CommonModule, IonicModule, CoreComponentsModule],
  exports: [SplashScreenComponent],
})
export class SplashScreenModule {}
