import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {MaterialPage} from '@pages/material/material.page';
import {NewProviderModalPage} from '@pages/provider/components/new-provider-modal/new-provider-modal.page';
import {ProviderPage} from '@pages/provider/provider.page';
import {NewReceptionMaterialModalPage} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';
import {NewReceptionModalPage} from '@pages/reception/components/new-reception-modal/new-reception-modal.page';
import {ReceptionPage} from '@pages/reception/reception.page';
import {WarehousePage} from '@pages/warehouse/warehouse.page';
import {NewMaterialModalPage} from '@pages/material/components/new-material-modal/new-material-modal.page';
import {NewWarehouseModalPage} from '@pages/warehouse/component/new-warehouse-modal/new-warehouse-modal.page';
import {WalkthroughComponent, WalkthroughContainerComponent, WalkthroughTextI} from 'angular-walkthrough';
import {AuthService} from '@modules/auth';
import {Router} from '@angular/router';
import {MenuController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  providers: [
    NewProviderModalPage,
    ProviderPage,
    MaterialPage,
    ReceptionPage,
    TypesUtilsService,
    NewReceptionModalPage,
    NewReceptionMaterialModalPage,
    NewWarehouseModalPage,
    NewMaterialModalPage,
    WarehousePage,
  ],
})
export class OnboardingComponent {
  @ViewChild('walk1') walk1: WalkthroughComponent;

  private listener: Subscription[] = [];

  constructor(
      private router: Router,
      private menu: MenuController,
      private authService: AuthService,
      public typesUtils: TypesUtilsService,
      private translate: TranslateService,
  ) {
    this.listener.push(
        WalkthroughComponent.onClose.subscribe((item) => {
          // Realiza la actualización en el backend
          this.updateOnboardingStatus();
        }),
        WalkthroughComponent.onFinish.subscribe(() => {
          // Realiza la actualización en el backend
          this.updateOnboardingStatus();
        })
    );
  }

  customText: WalkthroughTextI = {
    close: (this.translate.instant('WALKTHROUGH.SKIP') + ' x'),
  };
  finishText: WalkthroughTextI = {
    close: this.translate.instant('WALKTHROUGH.COMMON.FINISH'),
  };

  @Output() openOnboarding = new EventEmitter();

  onClick() {
    this.openOnboarding.emit(null);
    this.walk1.open().then();
  }

  private updateOnboardingStatus() {
    this.authService.updateProfile(false).subscribe(
        (response) => {
        },
        (error) => {
          console.error('Error updating onboarding status:', error);
        }
    );
  }

  // Functions that haves name finished with focus using for [Focus Click]
  // Functions that haves a normal name using in the buttons acction
  openSideMenuContinueFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    this.menu.open();
    setTimeout(() => {
      content.next();
    }, 250);
    return;
  }

  closeSideMenuNavToWarehouseFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    this.menu.close();
    this.router.navigate(['/warehouse']);
    setTimeout(() => {
      content.next();
    }, 250);
    return;
  }

  walk3Focus = (event: Event, content: WalkthroughContainerComponent) => {
    const button = document.getElementById('step-warehouse2');
    button?.click();
    setTimeout(() => {
      content.next();
    }, 550);
    return;
  }

  openWarehouseMenu() {
    const button = document.getElementById('step-warehouse6');
    button?.click();
    setTimeout(() => {
      this.continue();
    }, 550);
    return;
  }

  closeWarehouseMenuxlsx() {
    const button = document.getElementById('new-warehouse-xlsx-modal-close');
    button?.click();
    this.continue();
    return;
  }

  navToProviderCloseMenuFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    this.router.navigate(['/provider']);
    this.menu.close();
    setTimeout(() => {
      content.next();
    }, 250);
    return;
  }

  walk11Focus = (event: Event, content: WalkthroughContainerComponent) => {
    const button = document.getElementById('step-three');
    button?.click();
    setTimeout(() => {
      content.next();
    }, 550);
    return;
  }

  walk14Focus = (event: Event, content: WalkthroughContainerComponent) => {
    const button = document.getElementById('step-six');
    button?.click();
    setTimeout(() => {
      content.next();
    }, 550);
    return;
  }

  openNewProviderXlsxContinueFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    const button = document.getElementById('step-seven');
    button?.click();
    setTimeout(() => {
      content.next();
    }, 550);
    return;
  }

  navToMaterialCloseMenuFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    this.router.navigate(['/material']);
    this.menu.close();
    setTimeout(() => {
      content.next();
    }, 250);
    return;
  }

  openModalMaterialContinueFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    const button = document.getElementById('step-ten');
    button?.click();
    setTimeout(() => {
      this.continue();
    }, 550);
    return;
  }


  closeMenuNavToReceptionContinueFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    this.menu.close();
    this.router.navigate(['/reception']);
    setTimeout(() => {
      content.next();
    }, 500);
    return;
  }

  walk27Focus = (event: Event, content: WalkthroughContainerComponent) => {
    const button = document.getElementById('step-seventeen');
    button?.click();
    setTimeout(() => {
      content.next();
    }, 550);
    return;
  }

  openNewReceptionContinueFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    const button = document.getElementById('step-eighteen');
    button?.click();
    setTimeout(async () => {
      content.next();
    }, 550);
    return;
  }

  navToHomeContinue() {
    this.router.navigate(['/home']);
    setTimeout(() => {
      this.continue();
    }, 250);
  }

  openNewProviderXlsxAndContinue() {
    const button = document.getElementById('step-seven');
    button?.click();
    setTimeout(() => {
      this.continue();
    }, 550);
  }

  closeNewProviderXlsxAndContinue() {
    const button = document.getElementById('new-provider-xlsx-modal-close');
    button?.click();
    this.continue();
  }

  openNewMaterialXlsxAndContinue() {
    const button = document.getElementById('step-fourteen');
    button?.click();
    setTimeout(() => {
      this.continue();
    }, 550);
  }

  closeNewMaterialXlsxAndContinue() {
    const button = document.getElementById('new-material-xlsx-modal-close');
    button?.click();
    this.continue();
  }

  openSideMenuContinue() {
    this.menu.open();
    setTimeout(() => {
      this.continue();
    }, 250);
  }

  pauseWalkOnFocus = (
      event: Event,
      content: WalkthroughContainerComponent
  ) => {
    content.stop();
    return;
  }

  continue() {
    WalkthroughComponent.walkthroughNext();
  }

  pause() {
    WalkthroughComponent.walkthroughStop();
  }


}
