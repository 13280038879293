import { Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {AllergenModel} from '../_models/allergen.model';
import {environment} from '@environments/environment';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';

@Injectable({
  providedIn: 'root'
})
export class MasterAllergenService {
  API_URL = `${environment.api_host}/api/master/allergen/`;
  constructor(private http: HttpClient) {}

  findAllergen(queryParams?: QueryParamsModel): Observable<AllergenModel[]> {
      // Para añadir el search o clauquier filtro solo falta añadir el queryParams en la petición
      const url = this.API_URL + `list/`;
      return this.http.get<AllergenModel[]>(url).pipe(
        mergeMap(res => {
          return of(res);
        })
    );
  }
}
