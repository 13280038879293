import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rm-stock',
  templateUrl: './rm-stock.component.html',
  styleUrls: ['./rm-stock.component.scss'],
})
export class RmStockComponent implements OnInit {
  @Input() minimum: number;
  @Input() current: number;
  @Input() maximum: number;
  @Input() showLabel: boolean = true;

  constructor() {}

  ngOnInit() {}
}
