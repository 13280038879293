import {BaseModel} from '@core/models/_base.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';

export class ClientModel extends BaseModel {
  id: number;
  name: string;
  address: string;
  enabled = true;
  sanitary_registration_number: string;
  contact_name: string;
  contact_phone: string;
  email: string;

  static jsonToModel(object) {
    const client = new ClientModel();
    if (!object) { return client; }
    client.id = object.id;
    client.name = object.name;
    client.enabled = object.enabled;
    client.address = object.address;
    client.sanitary_registration_number = object.sanitary_registration_number;
    client.contact_name = object.contact_name;
    client.contact_phone = object.contact_phone;
    client.email = object.email;

    return client;
  }
}
