export class QueryParamsModel {
    // fields
    search: string;
    filter: any;
    sortOrder: string; // asc || desc
    sortField: string;
    pageNumber: number;
    pageSize: number;

    // constructor overrides
    // tslint:disable-next-line:variable-name
    constructor(_search: string = '',
                // tslint:disable-next-line:variable-name
                _sortOrder: string = 'asc',
                // tslint:disable-next-line:variable-name
                _sortField: string = '',
                // tslint:disable-next-line:variable-name
                _pageNumber: number = 0,
                // tslint:disable-next-line:variable-name
                _pageSize: number = 50,
                // tslint:disable-next-line:variable-name
                _filter: any = {}) {
        this.search = _search;
        this.filter = _filter;
        this.sortOrder = _sortOrder;
        this.sortField = _sortField;
        this.pageNumber = _pageNumber;
        this.pageSize = _pageSize;
    }
}
