import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, Router,
} from '@angular/router';
import { AuthService } from '@modules/auth';
import {HttpUtilsService} from '@core/utils/http-utils.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (route.data.roles && !HttpUtilsService.hasPermission(route.data.roles, currentUser?.profile?.roles)) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      if (!currentUser.profile.have_company){
        this.router.navigate(['/auth/register-company']);
        return false;
      }
      if (currentUser.profile.force_change_password){
        this.router.navigate(['/auth/change-password']);
        return false;
      }
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
