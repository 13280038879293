import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaterialModel} from '@pages/material/_models/material.model';
import {MaterialDetailPage} from '@pages/material/components/material-detail/material-detail.page';
import {ModalController} from '@ionic/angular';


@Component({
    selector: 'app-raw-material-card',
    templateUrl: './raw-material-card.component.html',
    styleUrls: ['./raw-material-card.component.scss'],
})
export class RawMaterialCardComponent implements OnInit {
    @Input() providerId: number;
    @Input() cardType: string;
    @Input() material: MaterialModel;
    @Input() allergens = null;
    @Input() showClear = true;
    @Output() callback = new EventEmitter<boolean>();
    @Output() clear = new EventEmitter<any>();

    providerHomologate = false;


    constructor(
        public modalController: ModalController, ) {
    }


    ngOnInit() {
        this.getAllergenByProvider();
        this.isProviderHomologate();
    }

    isProviderHomologate() {
        this.material.providers.forEach((item: any) => {
            if (item.id === this.providerId){
                this.providerHomologate = item.homologate;
            }
        });
    }

    getAllergenByProvider() {
        if (!this.allergens){
            this.material.providers.forEach((item: any) => {
                if (item.id === this.providerId){
                    this.allergens = item.allergen;
                }
            });
        }

    }


    async openDetailModal(material: MaterialModel) {
        const modal = await this.modalController.create({
            component: MaterialDetailPage,
            backdropDismiss: false,
            componentProps: {
                id: material.id,
                measurement_unit: material.measurement_unit,
                providers: material.providers,
            },
            cssClass: 'right-side-modal-css',
        });
        modal.onDidDismiss().then((response) => {
            this.callback.emit(response.data);
        });

        return await modal.present();
    }

    onClear(){
        this.clear.emit(true);
    }

}
