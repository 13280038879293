import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {WarehouseModel} from '../../_models/warehouse.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {Subscription, throwError} from 'rxjs';
import {WarehouseService} from '@pages/warehouse/_services';
import {catchError, first} from 'rxjs/operators';
import {MessageType, NotificationService,} from '@core/_services/notification/notification.service';
import {nameAsyncValidator} from '@core/validators/AsyncName.validators';
import {AuthService} from '@modules/auth';
import {WalkthroughComponent} from 'angular-walkthrough';
import { notEmptyValidator } from '@core/validators/NotEmpty.validators';

@Component({
  selector: 'app-new-warehouse-modal',
  templateUrl: './new-warehouse-modal.page.html',
  styleUrls: ['./new-warehouse-modal.page.scss'],
})
export class NewWarehouseModalPage implements OnInit, OnDestroy {
  id: number;
  isLoading$;
  warehouse: WarehouseModel;
  formGroup: UntypedFormGroup;

  private subscriptions: Subscription[] = [];
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;

  constructor(
      private modalController: ModalController,
      private fb: UntypedFormBuilder,
      private warehouseService: WarehouseService,
      private notificationService: NotificationService,
      private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this.warehouseService.isLoading$;
    this.loadWarehouse();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  loadWarehouse() {
    if (!this.id) {
      this.warehouse = new WarehouseModel();
      this.loadForm();
    } else {
      const sb = this.warehouseService
          .getItemById(this.id)
          .pipe(
              first(),
              catchError(async (errorMessage) => {
                console.log(errorMessage);
                this.notificationService.showActionNotification(
                    'COMMON.DATA_SERVER_ERROR',
                    MessageType.Error
                );
                await this.modalController.dismiss(false);
                return throwError(errorMessage);
              })
          )
          .subscribe((warehouseModel: WarehouseModel) => {
            this.warehouse = WarehouseModel.jsonToModel(warehouseModel);
            this.loadForm();
          });
      this.subscriptions.push(sb);
    }

  }

  async closeModal() {
    await this.modalController.dismiss(false);
  }

    loadForm() {
        this.formGroup = this.fb.group({
            name: [
                this.warehouse.name,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100),
                    notEmptyValidator
                ]),
                [nameAsyncValidator(this.warehouseService, this.warehouse.id)],
            ],
            enabled: [this.warehouse.enabled],
        });
    }

  save() {
    this.prepareWharehouse();
    if (this.warehouse.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  edit() {
    const sbUpdate = this.warehouseService
        .updateWarehouse(this.warehouse)
        .pipe(
            catchError((errorMessage) => {
              console.log(errorMessage);
              this.notificationService.showActionNotification(
                  'COMMON.DATA_UPDATED_ERROR',
                  MessageType.Error
              );
              return throwError(errorMessage);
            })
        )
        .subscribe(async (res: WarehouseModel) => {
          this.warehouse = res;
          this.notificationService.showActionNotification(
              'COMMON.DATA_UPDATED',
              MessageType.Success
          );
          await this.modalController.dismiss(true);
        });
    this.subscriptions.push(sbUpdate);
  }

  create() {
    const sbCreate = this.warehouseService
        .createWarehouse(this.warehouse)
        .pipe(
            catchError((errorMessage) => {
              console.log(errorMessage);
              this.notificationService.showActionNotification(
                  'COMMON.DATA_CREATED_ERROR',
                  MessageType.Error
              );
              return throwError(errorMessage);
            })
        )
        .subscribe(async (res: WarehouseModel) => {
          this.warehouse = res;
          this.notificationService.showActionNotification(
              'COMMON.DATA_CREATED',
              MessageType.Success
          );
          this.continueOnboarding();
          await this.modalController.dismiss(true);
        });
    this.subscriptions.push(sbCreate);
  }

  private prepareWharehouse() {
    const formData = this.formGroup.value;
    this.warehouse.name = formData.name;
    this.warehouse.enabled = formData.enabled;
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

  private continueOnboarding() {
    WalkthroughComponent.walkthroughContinue();
    WalkthroughComponent.walkthroughNext();
  }
}
