import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-circle-button-text',
    templateUrl: './circle-button-text.component.html',
    styleUrls: ['./circle-button-text.component.scss'],
})
export class CircleButtonTextComponent implements OnInit {
    @Input() text: string;
    @Input() class: string;
    @Input() disabled = false;
    @Output() action = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    onAction() {
        this.action.emit(null);
    }

}
