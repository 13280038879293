// English
export const locale = {
    lang: 'en',
    data: {
        ABBREVIATION_DAYS: 'd.',
        ERROR_PAGE: {
            404: {
                TITLE: 404,
                SUBTITLE: 'OOPS! Algo salió mal aquí.'
            }
        },
        COMMON: {
            UPDATE: 'Actualizar',
            CREATE: 'Crear',
            NEXT: 'Siguiente',
            AUTO_COMPLETED: 'Autocompletar',
            AUTO_COMPLETED_TOOLTIP: 'Se rellenarán todos los elementos según criterio del software, el más caducado primero',
            OK: 'Ok',
            CANCEL: 'Cancelar',
            ALERT: '¡Alerta!',
            PREVIOUS: 'Anterior',
            ADMINISTRATION: 'Administración',
            RAW_MATERIAL: 'Materia prima',
            RAW_MATERIALS: 'Materias primas',
            PROVIDER: 'Proveedor',
            CLIENT: 'Cliente',
            PRODUCT: 'Producto',
            PROVIDERS: 'Proveedores',
            CLIENTS: 'Clientes',
            CLIENT_LIST: 'Listado de clientes',
            EMPLOYEE: 'Empleado',
            EMPLOYEES: 'Empleados',
            WAREHOUSE: 'Almacén',
            RAW_MATERIAL_APPROVED: 'Materia prima homologada',
            NAME: 'Nombre',
            MAX_STOCK: 'Stock óptimo',
            MIN_STOCK: 'Stock Mínimo',
            DECREASE: 'Merma',
            UNITS_MEASURE: 'Ud. de medida',
            STOCK: 'Stock',
            REAL_STOCK: 'Stock real',
            RECEPTION: 'Recepción',
            PRODUCTION: 'Producción',
            PRODUCTION_PLANNING: 'Planificar producción',
            LOT: 'Lotes',
            LOT_lIST: 'Listado de lotes producidos',
            DELIVERY: 'Entregas',
            RECIPE: 'Recetas',
            DASHBOARD: 'Dashboard',
            TRACEABILITY: 'Trazabilidad',
            ADDRESS: 'Dirección',
            ADD_NEW: 'Añadir nueva',
            ADD_NEW_XLSX: 'Añadir por xlsx',
            EDIT_MASS: 'Edición masiva de recetas',
            REGISTER: 'Dar de alta',
            LOADING: 'Cargando...',
            CLOSE: 'Cerrar',
            NO_RESULTS: 'No hay elementos que mostrar',
            DATA_UPDATED: 'Actualizado correctamente',
            DATA_CREATED: 'Guardado correctamente',
            DATA_REMOVED: 'Eliminado correctamente',
            DATA_CREATED_ERROR: 'Ha habido un error al crear el registro',
            DATA_CREATED_ERROR_EMAIL_ALREADY_USED: 'Este correo ya ha sido registrado',
            DATA_UPDATED_ERROR: 'Ha habido un error al actualizar el registro',
            DATA_SERVER_ERROR: 'Ha habido un error de conexión con la API',
            NO_GRAPH_DATA: 'No hay datos para el grafico en este período',
            NO_RECEPTION_ITEMS: 'No se han detectado líneas en el albarán, por favor, revisa el documento.',
            TODAY: 'hoy',
            ENABLED: 'Activado',
            SEARCH: 'Buscar',
            FILEPOND: {
                DROP_FILES: 'Suelte el archivo aquí (máx 10 páginas y 5MB).',
                DROP_ZIP: 'Suelte el archivo .ZIP aquí.',
                DROP_IMAGES: 'Suelte la imagen aquí ...',
                UPLOAD_OK: 'Archivo subido correctamente',
                UPLOADING: 'Subiendo archivo...',
                INVALID_FILE: 'El archivo no es válido. Por favor, verifica el archivo y vuelve a intentarlo.',
            },
            FILTER: {
                FILTER: 'Filtrar',
                FILTER_LABEL: 'Filtrar por:',
                PROVIDERS: 'Proveedores',
                RAW_MATERIALS: 'Materias primas',
                FILTER_DATE: 'Filtrar por fecha',
                FILTER_RESET: 'Reiniciar filtros',
                COOKING_RECIPE: 'Recetas',
                LOT: 'Lotes',
                CLIENT: 'Cliente',
                PRODUCT: 'Producto'
            },
            TOOLTIP: {
                CHECKLIST_STATUS: 'Estado del checklist',
                TOTAL_RAW_MATERIALS: 'Total de materias primas',
                TOTAL_RAW_PRODUCTS: 'Total de productos',
                TOTAL_ALLERGENS: 'Total de alérgenos',
                RECIPE_APPROVED: 'Receta homologada',
                RECIPE_NOT_APPROVED: 'Falta asignar alérgenos',
                MATERIAL_APPROVED: 'Materia prima homologada',
                MATERIAL_NOT_APPROVED: 'Materia prima por homologar'
            },
            USER_UPDATE_OK: 'Se ha actualizado correctamente los datos del usuario',
            USER_UPDATE_KO: 'Ha ocurrido un error al actualizar los datos, verifique todo y vuelva a intentarlo',
            TERMSANDCONDITIONS: {
                PART1: 'Confirmo la lectura, comprensión y aceptación plena de los ',
                LINK: '<a href=\'https://totalfoodcontrol.com/condiciones-legales/\' target=\'_blank\'>términos y condiciones legales</a>',
                PART3: ' de Total Food Control.',
            },
        },
        AUTH: {
            PROJECT_NAME: 'Total Food Control',
            LOGOUT: 'Cerrar sesión',
            LOGIN: {
                TITLE: 'Bienvenido a Total Food Control',
                FORGOT_PASS_LINK: '¿Contraseña olvidada?',
                REGISTER: 'Registarse',
                FORM: {
                    FIELDS: {
                        EMAIL: 'Email',
                        PASSWORD: 'Contraseña',
                        SUBMIT: 'Entrar',
                        GOOGLE_LOGIN: 'Entrar con Google',
                    },
                    ERRORS: {
                        USER_NOT_FOUND: 'El correo electrónico no existe en el sistema',
                        USER_NOT_ENABLED: 'El usuario no está activado',
                        WRONG_PASSWORD: 'Contraseña incorrecta',
                        EMAIL_REQUIRED: 'Se require correo electrónico',
                        EMAIL_INVALID: 'El correo electrónico no es válido',
                        EMAIL_MINLENGTH: 'El correo electrónico debe tener al menos 3 símbolos',
                        EMAIL_MAXLENGHT: 'El correo electrónico debe tener un máximo de 360 símbolos',
                        PASS_REQUIRED: 'Se requiere contraseña',
                        PASS_MINLENGTH: 'La contraseña debe tener al menos 3 símbolos',
                        PASS_MAXLENGHT: 'La contraseña debe tener un máximo de 100 símbolos',
                        undefined: 'Error en la API',
                        EMAIL_NOT_EXISTS: 'El correo electrónico no existe',
                    }
                }
            },
            REGISTER: {
                TITLE: 'Crear cuenta',
                SUBTITLE: 'Rellena los siguientes campos para una cuenta de prueba',
                USER_LABEL: 'Usuario',
                COMPANY_LABEL: 'Empresa',
                FORM: {
                    FIELDS: {
                        NAME: 'Nombre',
                        SURNAME: 'Apellido',
                        EMAIL: 'Email',
                        PASSWORD: 'Contraseña',
                        REPEAT_PASSWORD: 'Repetir contraseña',
                        REGISTER_AND_CREATE_COMPANY: 'Siguiente',
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres'
                    }
                },
                REGISTER_COMPANY: {
                    FREELANCER: '<i>Como autónomo utiliza tu nombre y dni<br/> para registrar la empresa.</i>',
                    FORM: {
                        FIELDS: {
                            NAME: 'Nombre',
                            CIF: 'CIF',
                            ADDRESS: 'Dirección',
                            NUM_RECIPES_OR_PRODUCTS: 'Número de recetas o productos',
                        },
                        ERRORS: {
                            CORRECT: 'El campo es correcto',
                            REQUIRED: 'Este campo es requerido',
                            MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                            MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                            EMPTY: 'El nombre no puede estar vacío',
                            CIF: 'El CIF no es válido',
                            NUMBER: 'El número no es válido',
                        }
                    },
                },
            },
            CHANGE_PASSWORD: {
                TITLE: 'Cambio de contraseña',
                SUBTITLE: 'Por su seguridad debe cambiar su contraseña que hemos generado de forma automática.',
                FORM: {
                    FIELDS: {
                        OLD_PASSWORD: 'Contraseña actual',
                        NEW_PASSWORD: 'Nueva contraseña',
                        REPEAT_PASSWORD: 'Repetir nueva contraseña',
                        SUBMIT: 'Cambiar contraseña'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        ARE_EQUAL: 'Las contraseñas nuevas no coinciden'
                    }
                },
            },

            RESET_PASS: {
                TITLE: '¿Contraseña olvidada?',
                SUBTITLE: 'Ingrese su correo electrónico para restablecer su contraseña',
                FORM: {
                    FIELDS: {
                        EMAIL: 'Email',
                        SUBMIT: 'Enviar',
                        CANCEL: 'Cancelar',
                    },
                    ERRORS: {
                        EMAIL_NOT_EXISTS: 'El correo electrónico no existe',
                        EMAIL_REQUIRED: 'Se require correo electrónico',
                        EMAIL_INVALID: 'El correo electrónico no es válido',
                        EMAIL_MINLENGTH: 'El correo electrónico debe tener al menos 3 símbolos',
                        EMAIL_MAXLENGHT: 'El correo electrónico debe tener un máximo de 360 símbolos',
                    }
                },
                SUCCESS_CARD: {
                    TITLE: '¡El correo electrónico es correcto!',
                    MESSAGE_LINE_1: 'Mensaje con instrucción de \'recuperación\'',
                    MESSAGE_LINE_2: 'ha sido enviado',
                    SUBMIT: '¡Ok lo tengo!'
                }
            }
        },
        NOTIFICATIONS: {
            LIST_MODAL: {
                TITLE: 'Notificaciones',
                SUBTITLE: '',
            }
        },
        HOME: {
            NEW_HOME_MODAL: {
                MODAL_TITLE: 'Añadir Nueva',
                LIST_TITLE_RECEPTION: 'Recepción',
                LIST_TITLE_PRODUCTION: 'Producción',
                LIST_TITLE_DELIVERY: 'Entrega',
                LIST: {
                    NEW_RECEPTION: 'Nueva Recepción MP',
                    NEW_RECEPTION_XLSX: 'Nueva Recepción MP en xlsx (excel)',
                    NEW_MATERIAL: 'Nueva MP',
                    NEW_PROVIDER: 'Nuevo Proveedor',
                    NEW_APPROVED_RAW_MATERIAL: 'Nueva Materia prima homologada',
                    NEW_WAREHOUSE: 'Nuevo Almacén',
                    NEW_RECIPE: 'Crear receta',
                    NEW_PRODUCTION_RECIPE: 'Nueva producción  con receta',
                    NEW_PRODUCTION_WITHOUT_RECIPE: 'Nueva producción  sin receta',
                    REUSE_PRODUCTION: 'Reutilizar producción existente',
                    NEW_DELIVERY: 'Nueva entrega',
                    NEW_RETURN: 'Devolución',
                    NEW_CUSTOMER: 'Alta cliente'
                }
            },
            RECEPTION_BLOCK: {
                TITLE: 'Recepción',
                TITLE_LAST_RECEPTIONS: 'Últimas recepciones',
                ADD_RECEPTION: 'Nueva Recepción',
                RAW_MATERIAL_GRAPH: {
                    TITLE: 'Ranking de materiales',
                    SUBTITLE: 'Compras / Proveedor',
                    TOOLTIP: 'Diferencia de stock con el periodo anterior'
                },
                STOCK_GRAPH: {
                    TITLE: 'Ranking de Stock',
                    SUBTITLE: 'Stock / MP',
                    LEGEND: 'Stock por debajo del minimo.'
                },
                EXPIRATION_GRAPH: {
                    TITLE: 'Lote más próximos a caducar',
                    SUBTITLE: 'Caducidad',
                    TOOLTIP: 'MP proximas a caducar'
                }
            },
            PRODUCTION_BLOCK: {
                TITLE: 'Producción',
                TITLE_LAST_PRODUCTIONS: 'Últimas producciones',
                ADD_PRODUCTION: 'Nueva Producción',
                PRODUCED_RECIPES_GRAPH: {
                    TITLE: 'Recetas más producidas',
                    SUBTITLE: 'Producción / recetas',
                    COLUMN_NAME: 'Unidades producidas',
                    TOOLTIP: 'Diferencia de unidades producidas con el periodo anterior.'
                },
                PRODUCTION_DECREASE_GRAPH: {
                    TITLE: 'Producciones con más merma',
                    SUBTITLE: 'Merma / producción',
                    COLUMN_NAME: 'Merma',
                    TOOLTIP: 'porcentaje de merma total en el periodo seleccionado.'
                },
                RECIPE_STOCK_GRAPH: {
                    TITLE: 'Recetas con más stock',
                    SUBTITLE: 'Stock / recetas',
                    COLUMN_NAME: 'Stock',
                }
            },
            DELIVERY_BLOCK: {
                TITLE: 'Entregas',
                TITLE_LAST_DELIVERIES: 'Últimas entregas',
                ADD_DELIVERY: 'Nueva entrega',
                PRODUCTS_DELIVERED_GRAPH: {
                    TITLE: 'Productos más entregados',
                    SUBTITLE: 'Productos / entregas',
                    COLUMN_NAME: 'Productos',
                    TOOLTIP: 'Diferencia de productos entregados con el periodo anterior.'
                },
                CLIENTS_DELIVERED_GRAPH: {
                    TITLE: 'Clientes con más entregas',
                    SUBTITLE: 'Clientes / entregas',
                    COLUMN_NAME: 'productos',
                    TOOLTIP: 'Diferencia de productos entregados con el periodo anterior.'
                }
            }
        },
        EMPLOYEE: {
            EMPLOYEE_PAGE: {
                EMAIL: 'Correo electrónico',
                NAME: 'Nombre',
                ENABLED: 'Activado',
                CAN_CREATE: 'Puede crear trabajadores',
                ADD_NEW: 'Añadir nuevo',
                HELP_TOOLTIP: 'Video tutorial para crear un trabajador.',
            },
            EMPLOYEE_CREATE_MODAL: {
                NEW_EMPLOYEE: 'Nuevo empleado',
                EDIT_EMPLOYEE: 'Editar un empleado',
                SEND_WELCOME_EMAIL: 'Reenviar email bienvenida',
                FORM: {
                    INFO: 'Rellena el siguiente formulario para dar de alta un nuevo empleado',
                    FIELDS: {
                        EMAIL: 'Correo electrónico',
                        NAME: 'Nombre',
                        LAST_NAME: 'Apellido',
                        ENABLED: 'activado',
                        CAN_CREATE: 'Puede crear trabajadores',
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        ASYNC_EMAIL: 'Ya existe un usuario con este correo electrónico',
                        NOT_EMPTY: 'Este campo no puede estar vacío'
                    }
                },
                SEND_WELCOME_EMAIL_CORRECT: 'Email de bienvenida enviado correctamente',
                SEND_WELCOME_EMAIL_ERROR: 'Error al enviar el email de bienvenida',

            },
        },
        ALLERGENS: {
            GLUTEN: 'Gluten',
            EGGS: 'Huevos',
            MILK: 'Leche',
            PEANUTS: 'Cacahuetes',
            SOY: 'Soja',
            NUTS: 'Frutos secos',
            CELERY: 'Apio',
            MUSTARD: 'Mostaza',
            SESAME: 'Sésamo',
            FISH: 'Pescado',
            CRUSTACEAN: 'Crustaceos',
            MOLLUSCS: 'Moluscos',
            WHITE_LUPINS: 'Altramuces',
            SULPHITES: 'Sulfitos'
        },
        MEASURE_UNITS: {KG: 'kg.', LITERS: 'Litros', UDS: 'uds.', G: 'g.', ML: 'ml.'},
        LIST_ITEM_COMPONENT: {APPROVED_LETTER: 'H', NOT_APPROVED_LETTERS: 'PH'},
        APPROVED_MATERIAL: {
            APPROVED_MATERIAL_PAGE: {TITLE: 'Materiales aprovados', ALLERGENS_NUMBER: 'Nº de alérgenos'},
            APPROVED_MATERIAL_MODAL: {
                MP_APPROVAL: 'Homologación de MP',
                EDIT_APPROVED_MATERIAL: 'Editar Almacén',
                FORM: {
                    INFO: 'Para homologar una mp debes seleccionar los proveedores y lo alérgenos que ésta tenga para cada proveedor.',
                    ALLERGENS_INFO: 'Selecciona los alérgenos asociados  si "contiene" o tiene "trazas" de alguno de ellos.',
                    FIELDS: {
                        RAW_MATERIAL: 'Selecciona materia prima',
                        ADD_RAW_MATERIAL: 'Añadir nueva materia prima',
                        PROVIDER: 'Selecciona proveedor',
                        ADD_PROVIDER: 'Añadir nuevo proveedor',
                        ALLERGENS: 'Alérgenos asociados'
                    },
                    LEGENDS: {
                        DOUBLE_CLICK: 'Doble Click.',
                        CONTAINS_INGREDIENT: 'Contiene el ingrediente',
                        CONTAINS_TRACES: 'Contiene trazas del ingrediente'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres'
                    }
                }
            },
            APPROVED_MATERIAL_THROUGH_XLSX: {
                MODAL_TITLE: 'Homologación de MP',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar homologación de MP',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>Siempre seleccionar los valores d e los desplegables, cualquier línea con un campo vacío será ignorada por el sistema.</li></ul>',
                CREATED: 'Homologaciones creados',
            },
        },
        PROVIDER: {
            PROVIDER_PAGE: {
                HEALTH_REGISTER: 'Registro sanitario',
                CONTACT: 'Contacto',
                PHONE_NUMBER: 'Teléfono',
                ADD_NEW: 'Añadir nuevo',
                HELP_TOOLTIP: 'Video tutorial crear un proveedor',
                TOOLTIP: {
                    DETAIL: 'Ficha de proveedor',
                }
            },
            PROVIDER_MODAL: {
                NEW_PROVIDER: 'Nuevo Proveedor',
                EDIT_PROVIDER: 'Ficha de proveedor',
                FORM: {
                    INFO: 'Puedes editar la ficha de proveedor y actualizar',
                    FIELDS: {
                        NAME: 'Nombre',
                        ADDRESS: 'Dirección',
                        HEALTH_REGISTER_NUMBER: 'Número de registro sanitario',
                        ALERTS_CONTACT: 'Contacto alertas',
                        ALERTS_CONTACT_PHONE: 'Teléfono contacto alertas'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        PATTERN: 'El campo solo debe tener números',
                        ASYNC_NAME: 'Ya existe un proveedor con este nombre',
                        NOT_EMPTY: 'El campo no puede estar vacío'
                    }
                }
            },
            PROVIDER_THROUGH_XLSX: {
                MODAL_TITLE: 'Nuevo Proveedor',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar proveedores',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>El nombre del proveedor es único, si está repetido en el xlsx o ya está en el sistema será ignorado.</li></ul>',
                CREATED: 'Proveedores creados',
            },
        },
        WAREHOUSE: {
            WAREHOUSE_PAGE: {TITLE: 'Almacén', HELP_TOOLTIP: 'Video tutorial crear un almacén', ADD_NEW: 'Añadir nuevo', },
            WAREHOUSE_MODAL: {
                NEW_WAREHOUSE: 'Nuevo Almacén',
                EDIT_WAREHOUSE: 'Editar Almacén',
                FORM: {
                    INFO: 'Pon nombre al nuevo almacén. Si es refrigerado o congelado asigna también una temperatura. Ejemplo: Cámara +4ªC o Congelador (-18ªC)',
                    FIELDS: {NAME: 'Nombre'},
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        ASYNC_NAME: 'Ya existe un almacén con este nombre',
                        NOT_EMPTY: 'El campo no puede estar vacío'
                    }
                }
            },
            WAREHOUSE_DETAIL_MODAL: {
                WAREHOUSE_CARD_TITLE: 'FICHA ALMACÉN',
                WAREHOUSE: 'Almacén',
                MATERIALS: 'Materias primas/Productos',
                TOTAL_MATERIALS: 'Total de materiales y productos en el almacén.',
                EDIT: 'Editar'
            },
            WAREHOUSE_RECEPTION_DETAIL_MODAL: {
                PRODUCT_RECEPTIONS: 'RECEPCIONES POR PRODUCTO',
                MATERIAL: 'Material / producto',
                RECEPTIONS: 'Recepciones',
                TOTAL_MATERIALS: 'Total de materiales y productos en el almacén.',
                ALERT_DIALOG: {
                    MESSAGE: '¿Estás seguro que quieres modificar el stock actual de esta recepción?',
                    SUCCESS_BUTTON: 'Aceptar',
                }
            },
            WAREHOUSE_THROUGH_XLSX: {
                MODAL_TITLE: 'Nuevo Almacén',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar almacenes',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>El nombre del almacén es único, si está repetido en el xlsx o ya está en el sistema será ignorado.</li></ul>',
                CREATED: 'Almacenes creados',
            },
        },
        CHECKLIST: {
            SKIP: 'TERMINAR MÁS TARDE',
            VIEW_CHECKLIST: 'Ver checklist',
            FILL_CHECKLIST: 'Rellenar checklist',
            RECEPTION: {
                TITLE: 'Checklist recepción',
                CONTROL: 'Control de recepción',
                DESCRIPTION: 'Marca el estado de las Materias primas en función de las preguntas que te mostramos a continuación',
                FORM: {
                    FIELDS: {
                        APPEARANCE: 'Aspecto',
                        TEMPERATURE: 'Temperatura',
                        PACKING: 'Envasado - embalaje',
                        TRANSPORT: 'Estado transporte',
                        LABELLED: 'Etiquetado - caducidad',
                        STATE: 'Estado',
                        DESCRIPTION: 'Observaciones',
                        DESCRIPTION_PLACEHOLDER: 'Describe cualquier anomalía o duda que se plantee en la recepción de esta materia prima'
                    },
                    OPTIONS: {
                        CORRECT: 'Correcto',
                        INCORRECT: 'Incorrecto',
                        NOT_ABLE: 'No aplica',
                        VALUE: 'Valor',
                        ALLRIGHT: 'Todos correctos',
                        ACCEPTED: 'Aceptado',
                        DETAINED: 'Retenido',
                        RETURNED: 'Devuelto'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres'
                    }
                }
            },
            PRODUCTION: {
                TITLE: 'Checklist producción',
                CONTROL: 'Control de producción',
                DESCRIPTION: 'Marca el estado de la producción e indica si se ha producido alguna observación a lo largo del proceso',
                FORM: {
                    FIELDS: {
                        PACKAGE_IN_TRACKING: '¿Has incluido los envases en la trazabilidad?',
                        INGREDIENTS_WEIGHT: '¿Están los ingredientes bien pesados?',
                        INTERMEDIATE_ITEMS_IDENTIFIED: '¿Están los productos intermedios bien identificados?',
                        PCC_REVIEW: '¿Revisión de los PCC hecha?',
                        DESCRIPTION: 'Observaciones',
                        DESCRIPTION_PLACEHOLDER: 'Describe cualquier anomalía o duda que se plantee en el proceso'
                    },
                    OPTIONS: {
                        CORRECT: 'Correcto',
                        INCORRECT: 'Incorrecto',
                        NOT_ABLE: 'No aplica'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres'
                    }
                }
            },
            DELIVERY: {
                TITLE: 'Checklist entregas',
                CONTROL: 'Control de entregas',
                DESCRIPTION: 'Marca el estado de la entrega e indica si se ha producido alguna observación a lo largo del proceso',
                FORM: {
                    FIELDS: {
                        CARRIER_NAME: 'Nombre del transportista',
                        TRUCK_STATUS: 'Estado del camion',
                        LABELLED: 'Etiquetado - caducidad',
                        PLATE: 'Matrícula',
                        NUM_DELIVERY_NOTE: 'Número de albarán',
                        TEMPERATURE: 'Temperatura',
                        DESCRIPTION: 'Observaciones',
                        DESCRIPTION_PLACEHOLDER: 'Describe cualquier anomalía o duda que se plantee en en la entrega'
                    },
                    OPTIONS: {
                        CORRECT: 'Correcto',
                        INCORRECT: 'Incorrecto',
                        NOT_ABLE: 'No aplica',
                        VALUE: 'Valor',
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres'
                    }
                }
            },
            ERROR_COMPONENT: {
                IDS_REQUIRED: 'EL Id de Checklist el id y nombre de entidad [RECEPCION | PRODUCCION | ENTREGA] son requeriidos'
            }
        },
        MATERIAL: {
            MATERIAL_PAGE: {
                TITLE: 'Materia prima',
                HELP_TOOLTIP: 'Video tutorial crear materia prima',
                TOOLTIP: {
                    DETAIL: 'Ver ficha aquí',
                }
            },
            MATERIAL_DETAIL_MODAL: {
                MATERIAL_CARD: 'Ficha MP',
                RAW_MATERIAL: 'Materia prima',
                TOTAL_PROVIDERS: 'Proveedores totales',
                APPROVED: 'Homologados',
                NOT_APPROVED: 'No homologados',
                MINIMUM: 'Mínimo',
                EDIT: 'Editar',
                PROVIDERS: 'Proveedores',
                ADD_PROVIDER: 'Añadir proveedor',
                CURRENT: 'Real',
            },
            MATERIAL_MODAL: {
                NEW_MP: 'Nueva MP',
                EDIT_MP: 'Editar MP',
                FORM: {
                    INFO: 'Añadir una nueva MP y fija su valor de Stock mínimo y óptimo',
                    FIELDS: {
                        NAME: 'Nombre de la MP',
                        MAX_STOCK: 'Stock Óptimo',
                        MIN_STOCK: 'Stock Mínimo',
                        UNITS_MEASURE: 'Unidad de medida',
                        DAYS_EXPIRATION_ALERT: 'Días alerta antes de caducidad',
                        UPLOAD_FILE: 'Documentación'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        PATTERN: 'El campo solo debe contener números',
                        STOCK: 'Los valores del Stock no son correctos',
                        MAX_STOCK_LESS_THAN_MIN: 'El stock óptimo debe ser mayor que el stock mínimo',
                        ASYNC_NAME: 'Ya existe una materia prima con este nombre',
                        NOT_EMPTY: 'El campo no puede estar vacio'
                    }
                }
            },
            MATERIAL_THROUGH_XLSX: {
                MODAL_TITLE: 'Nueva MP',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar materias primas',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>Si alguna línea está incompleta el sistema la ignorará</li><li>Unidades de medida siempre seleccionar del desplegable</li><li>El nombre de la maeria prima es único, si está repetido en el xlsx o ya está en el sistema será ignorado.</li><li>El stock mínimo tiene que ser superior al stock optimo, si no es así el sistema ignorará esa línea.</li></ul>',
                CREATED: 'Materias primas creadas',
            },
        },
        RECEPTION: {
            RECEPTION_PAGE: {
                TITLE: 'Recepción',
                TITLE_CARD_MATERIAL: 'Materias primas',
                TITLE_CARD_RECEPTION: 'Recepciones',
                NEW_RECEPTION: 'Nueva Recepción MP',
                NEW_MATERIAL: 'Nueva MP',
                HELP_TOOLTIP: 'Video tutorial crear una recepción',
                RECEPTION_TABLE: {
                    TABS: {
                        LAST_RECEPTIONS: 'ÚLTIMAS RECEPCIONES',
                        TO_BE_COMPLETED: 'PENDIENTES DE COMPLETAR (APP)',
                        PENDING_CHECKLIST: 'PENDIENTES DE CHECKLIST',
                        NEXT_EXPIRED: 'PRÓXIMAS A CADUCAR',
                        EXPIRED: 'CADUCADAS',
                    },
                    TABLE_COLUMN: {
                        TR: 'TR',
                        LOTE: 'LOTE',
                        MP: 'MP',
                        PROVIDER: 'PROVEEDOR',
                        RECEPTION_DATE: 'RECEPCIÓN',
                        EXPIRATION: 'CADUCIDAD',
                        CANT: 'CANTIDAD'
                    }
                },
                MATERIAL_TABS: {
                    TABS: {
                        MATERIAL_HOMOLOGATE: 'MP HOMOLOGADAS',
                        MATERIAL_NO_HOMOLOGADA: 'NO HOMOLOGADA',
                    }
                },
                TOOLTIP: {
                    TRACEABILITY: 'Abrir árbol de trazabilidad',
                    STOCK_LEVEL: 'Nivel de stock',
                    HOMOLOGATE: 'Homologar un MP significa asignarle proveedor/s y alérgenos',
                    CHECKLIST: 'Control de calidad realizado',
                    DETAIL: 'Ver ficha aquí',
                }
            },
            RECEPTION_DETAIL_MODAL: {
                EDIT: 'Editar',
            },
            RECEPTION_MODAL: {
                MODAL_TITLE: 'Añadir Nueva',
                LIST_TITLE: 'Recepción',
                LIST: {
                    NEW_RECEPTION: 'Nueva Recepción MP',
                    NEW_RECEPTION_XLSX: 'Nueva Recepción MP en xlsx (excel)',
                    NEW_RECEPTION_PDF: 'Nueva Recepción MP en pdf (adobe pdf)',
                    NEW_RECEPTION_WARNING: 'No puedes crear una recepción sin primero crear materias primas y proveedores',
                    NEW_MATERIAL: 'Nueva MP',
                    NEW_PROVIDER: 'Nuevo Proveedor',
                    NEW_APPROVED_RAW_MATERIAL: 'Nueva Materia prima homologada',
                    NEW_WAREHOUSE: 'Nuevo Almacén'
                },
                CARD: {
                    LATEST_SCANNED_TITLE: 'Últimos albaranes escaneados',
                    TOTAL_SCANNED:  'Total albaranes escaneados',
                    SCANNED_THIS_MONTH: 'Escaneados mensuales'
                }
            },
            RECEPTION_MATERIAL_MODAL: {
                NEW_RECEPTION: 'Nueva Recepción',
                EDIT_RECEPTION: 'Editar Recepción',
                RECENT_PROVIDERS: 'Proveedores recientes',
                RECENT_RECEPTIONS: 'Recepciones recientes',
                ADD_OTHER: 'Añadir otra',
                REMOVE_ITEM: 'Eliminar',
                RECEPTIOIN_DATA: 'Datos de recepción',
                ASSIGN_WHAREHOUSE: 'Asignar almacén',
                ASSIGN_WHAREHOUSE_DESCRIPTION: 'Asigna las materia al almacén correspondendiente o envíala a producción directamente',
                ACCEPTED_RECEPTION: 'Recepción Aceptada',
                FORM: {
                    FIELDS: {
                        PROVIDER: 'Proveedor',
                        MATERIAL: 'Materia Prima',
                        RECIPT_NUMBER: 'Nº Albarán',
                        PRODUCT_LOT: 'Lote producto',
                        EXPIRATION_DATE: 'Fecha de caducidad',
                        RECEPTION_DATE: 'Fecha de recepción',
                        QUANTITY: 'Cantidad',
                        UNITS_MEASURE: 'Unidad',
                        OBSERVATIONS: 'Observaciones',
                        NOTE:  {
                            LABEL: 'Escribe cualquier nota que quieras comunicar acerca de la recepción:',
                            PLACE_HOLDER: 'Escribe aquí tu comentario'
                        },
                    }, ERRORS: {CORRECT: 'El campo es correcto', REQUIRED: 'Este campo es requerido'}
                },
                ALERT_DIALOG: {
                    MESSAGE: 'Falta añadir algun número de lote en las recepciones, si quiere continuar igualmente se guardará el numero de albaran como numero de lote.',
                    MESSAGE_INTRO_STOCK: 'Al modificar el stock puede afectar a las siguientes producciones',
                    CANCEL_BUTTON: 'Cancelar',
                    SUCCESS_BUTTON: 'Aceptar',
                }
            },
            RECEPTION_THROUGH_XLSX: {
                MODAL_TITLE: 'Nueva Recepción',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para crear una recepción',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>Fechas del documento en formato español (dd/mm/yyyy)</li><li>Proveedor, materia prima y almacén siempre seleccionar del desplegable</li><li>Si el Nº de lote está vacio automáticamente añade del Nº de albaran</li><li>Si alguna línea está incompleta el sistema la ignorará</li></ul>'
            },
            RECEPTION_THROUGH_PDF: {
                MODAL_TITLE: 'Nueva Recepción',
                UPLOAD_FILE: 'Subir PDF para crear una recepción',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .pdf</li><li>Fechas del documento en formato español (dd/mm/yyyy)</li><li>Si el Nº de lote está vacío automáticamente añade del Nº de albarán</li><li>Si alguna línea está incompleta el sistema la ignorará</li></ul>',
                SCAN: 'Enviar albarán',
            },
            DETAIL_RECEPTION_MATERIAL_MODAL: {SHOW_RECEPTION: 'Detalles de la recepción'},
            PROVIDER_CARD: {TOTAL_RAW_MATERIAL: 'MP Totales', TOTAL_ALLERGENS: 'Alergenos'},
            RAW_MATERIAL_CARD: {
                TOTAL_RAW_MATERIAL: 'MP Totales',
                TOTAL_ALLERGENS: 'Alergenos',
                FAMILY: 'Familia',
                MINIMUM: 'Mínimo',
                CURRENT: 'Real'
            }
        },
        PRODUCTION: {
            PRODUCTION_PAGE: {
                TITLE: 'Producción',
                TITLE_CARD_RECIPE: 'Recetas',
                TITLE_CARD_PRODUCTION: 'Producciones',
                NEW_PRODUCTION: 'Nueva Producción',
                START_PRODUCTION: 'Iniciar Producción',
                NEW_RECIPE: 'Nueva Receta',
                HELP_TOOLTIP: 'Video tutorial crear una producción',
                TOOLTIP: {
                    DETAIL: 'Ver ficha aquí',
                    TRACEABILITY: 'Abrir árbol de trazabilidad',
                    CHECKLIST: 'Control de calidad realizado'
                },
                PRODUCTION_TABLE: {
                    TABS: {
                        PRODUCTIONS_PLANNED: 'PRODUCCIONES PLANIFICADAS',
                        LAST_PRODUCTIONS: 'ÚLTIMAS PRODUCCIONES',
                        PENDING_CHECKLIST: 'PENDIENTES DE CHECKLIST',
                        NEXT_EXPIRED: 'PRÓXIMAS A CADUCAR',
                    },
                    TABLE_COLUMN: {
                        TR: 'TR',
                        LOTE: 'LOTE',
                        RECIPE: 'RECETA',
                        PRODUCTION: 'FECHA',
                        EXPIRATION_DATE: 'CADUCIDAD',
                        QUANTITY_DECREASE: 'CANTIDAD/MERMA'
                    },
                },
                RECIPE_TABS: {
                    TABS: {
                        RECIPE_HOMOLOGATE: 'HOMOLOGADAS',
                        RECIPE_NO_HOMOLOGATE: 'NO HOMOLOGADAS',
                    },
                    TOOLTIP: {
                        RECIPE_HOMOLOGATE: 'Alérgenos asignados',
                        RECIPE_NO_HOMOLOGATE: 'Falta asignar alérgenos',
                    }
                }
            },
            PRODUCTION_PLANNED_PAGE: {
                TITLE: 'Planificar producción',
                TITLE_CARD_PRODUCTION: 'Producciones planificadas',
                NEW_PRODUCTION: 'Planificar nueva producción',
                PRODUCTION_STATUS_TOOLTIP_OK: 'Producción iniciada',
                PRODUCTION_STATUS_TOOLTIP_KO: 'Producción Por iniciar',
                HELP_TOOLTIP: 'Video tutorial para planificar una producción',
                PRODUCTION_TABLE: {
                    TABS: {
                        PRODUCTIONS_PLANNED: 'PRODUCCIONES PLANIFICADAS',
                    },
                },
                RECIPE_TABS: {
                    TABS: {
                        RECIPE_HOMOLOGATE: 'HOMOLOGADAS',
                        RECIPE_NO_HOMOLOGATE: 'NO HOMOLOGADAS',
                    }
                }
            },
            PRODUCTION_MODAL: {
                MODAL_TITLE: 'Añadir Nueva',
                LIST_TITLE: 'Producción',
                LIST: {
                    NEW_RECIPE: 'Crear receta',
                    NEW_LOT: 'Crear lote',
                    NEW_PRODUCTION_RECIPE: 'Nueva producción con receta',
                    NEW_PRODUCTION_WITHOUT_RECIPE: 'Nueva producción  sin receta',
                    REUSE_PRODUCTION: 'Reutilizar producción existente',
                    NEW_PRODUCTION_WARNING: 'No puedes crear una producción sin primero crear materias primas, proveedores y recepciones.',
                    NEW_RECIPE_WARNING: 'No puedes crear una receta sin primero crear materias primas.',
                }
            },
            PLANNED_MODAL: {
                LIST_TITLE: 'Planificación',
                LIST: {
                    NEW_PLANNED_RECIPE: 'Nueva planificación con receta',
                    NEW_PLANNED_WITHOUT_RECIPE: 'Nueva planificación  sin receta',
                    REUSE_PLANNED: 'Reutilizar planificación existente',
                    NEW_PLANNED_WARNING: 'No puedes crear una planificación sin primero crear materias primas, proveedores y recepciones.'
                }
            },
            PRODUCTION_COOKING_RECIPE_MODAL: {
                NEW_PRODUCTION: 'Nueva Producción con receta',
                EDIT_PRODUCTION: 'Reutilizar Producción existente',
                LAST_COOKING_RECIPE: 'Últimas recetas',
                RECENT_COOKING_RECIPE: 'Recetas frecuentes',
                RECIPE: 'Receta',
                ADD_NEW_LOT: 'Nuevo lote',
                FORM: {
                    FIELDS: {
                        SELECT_RECIPE: 'Selecciona Receta',
                        INSERT_QUANTITY_PRODUCE: 'Introduce {{measurement_unit}} que vas a producir',
                        INSERT_LOT_PRODUCTION: 'Seleccione nº de Lote Producción',
                        NO_LOT_PRODUCTION: 'El lote que buscas no existe o no esta abierto. Crea uno nuevo.',
                        DATE_EXPIRY: 'Fecha de caducidad',
                        ROW_MATERIAL_PRODUCTION: {
                            TOOLTIP_ERROR: 'Falta materia prima para la receta.',
                            TOOLTIP_TOTAL_SELECTED: 'Total de recepciones para esta materia prima',
                            MODIFY: 'MODIFICAR',
                            ADD: 'AÑADIR MP',
                            LOT: 'LOTE',
                            PROVIDER: 'PROVEEDOR',
                            RECEPTION: 'RECEPCIÓN',
                            EXPIRATION: 'CADUCIDAD',
                            QUANTITY: 'CANT',
                            SELECTED_QUANTITY: 'CANT SELEC.',
                            NO_RESULTS: 'No hay recepciones con stock para esta MP'
                        }
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        NOT_ENOUGH_STOCK: 'No hay suficiente stock de las materias primas necesarias para esta producción',
                        ENOUGH_STOCK_MORE_INFO: '+ Más información',
                        RAW_MATERIAL_REQUIRES: 'Necesitas por lo menos <b>{{stockRequired}}{{measurement_unit}}</b> más de <b>{{material_name}}</b>',
                        NO_LIST_ITEM: 'Es necesario seleccionar un lote de  la lista o crear uno nuevo.'
                    }
                },
                QUANTITY: 'Cantidad',
                UNITS: 'Unidades',
                TOTAL_RAW_MATERIAL: 'MP totales',
                LOT: 'LOTE',
                PRODUCTION_RAW_MATERIAL_TEXT: 'Estas son la MP utilizadas para producir {{uds}} {{measurement_unit}} de {{recipe_name}}. Añádelas para iniciar la producción.',
                ASSIGN_WHAREHOUSE: 'Asignar almacén',
                ASSIGN_WHAREHOUSE_DESCRIPTION: 'Asigna las materia al almacén correspondendiente o envíala a producción directamente',
                ACCEPTED_PRODUCTION: 'Producción Realizada',
                FOOTER: {
                    TOOLTIP: 'Unidades producidas por {{measurement_unit}}',
                    TOTAL_RAW_MATERIAL: 'MP totales'
                }
            },
            PRODUCTION_PLANNED_COOKING_RECIPE_MODAL: {
                NEW_PLANNED: 'Nueva planificación con receta',
                EDIT_PLANNED: 'Reutilizar planificación existente',
            },
            PLANNED_TO_PRODUCTION_COOKING_RECIPE_MODAL: {
                EDIT_PLANNED: 'Producir planificación',
            },
            DETAIL_PRODUCTION_PLANNED_COOKING_RECIPE_MODAL: {
                RE_PLANNED: 'Copiar planificación',
                DETAIL_PLANNING: 'Detalles de la planificación'
            },
            DETAIL_PRODUCTION_COOKING_RECIPE_MODAL: {
                DETAIL_PRODUCTION: 'Detalles de la producción',
                RECIPE: 'Receta',
                ROW_MATERIAL_PRODUCTION: {
                    LOT: 'LOTE',
                    PROVIDER: 'PROVEEDOR',
                    EXPIRATION: 'CADUCIDAD',
                    QUANTITY: 'CANT',
                    NO_RESULTS: 'No hay recepciones con stock para esta MP'
                },
                QUANTITY: 'Cantidad',
                UNITS: 'Unidades',
                TOTAL_RAW_MATERIAL: 'MP totales',
                LOT: 'LOTE',
                PRODUCTION_RAW_MATERIAL_TEXT: 'Estas son la MP que necesitas para producir {{uds}} {{measurement_unit}} de {{recipe_name}}.',
                WHAREHOUSE_LOCATION: 'Ubicación',
                DATE_EXPIRY: 'Caducidad',
                DECREASE: 'Merma',
                FOOTER: {
                    TOOLTIP: 'Unidades producidas por {{measurement_}}',
                    TOTAL_RAW_MATERIAL: 'MP totales'
                },
                RE_PRODUCE: 'Volver a producir',
                ADD_REAL_PRODUCTION: 'Editar cantidad producida',
                DIALOG_DECREASE: {
                    DIALOG_TITLE: 'Producción final real',
                    FINAL_PRODUCTION_LABEL: 'Producción final real',
                    CLOSE: 'Cerrar',
                    SAVE: 'Guardar',
                    FORM: {
                        ERRORS: {
                            CORRECT: 'El campo es correcto',
                            REQUIRED: 'Este campo es requerido',
                            MAX: 'La cantidad final no puede ser superior a la indicada en la producción'
                        }
                    }


                }
            },
            EDIT_PRODUCTION: {
                TITLE: 'Editar producción',
                FORM: {
                    INFO: 'Al modificar el nombre del lote o la fecha de creado las siguientes producciones se verán afectadas:',
                    FIELDS: {
                        NAME: 'Nombre del lote',
                        DATE_EXPIRY: 'Fecha de caducidad de la producción',
                        DATE_OF_CREATE: 'Fecha de creado',
                        DATE_OF_CREATE_INFO: 'No se puede modificar porque alguna producción de este lote ya está entregada.',
                    }, ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        ASYNC_NAME: 'Ya existe un lote con este nombre'
                    }
                }
            },
        },
        LOT: {
            LOT_PAGE: {
                NAME: 'Num de lote',
                DATE_OF_CLOSE: 'Fecha de cerrado',
                DATE_OF_CREATE: 'Fecha de creado',
                ADD_NEW: 'Añadir nuevo',
                HELP_TOOLTIP: 'Video tutorial para crear un lote.',
                TOOLTIP: {
                    DETAIL: 'Ver ficha aquí',
                },
            },
            LOT_CREATE_MODAL: {
                NEW_LOT: 'Nuevo Lote',
                EDIT_LOT: 'Editar un Lote',
                FORM: {
                    INFO: 'Puedes editar los campos de num de lote y fechas aquí',
                    FIELDS: {
                        NAME: 'Num de lote',
                        DATE_OF_CLOSE: 'Fecha de cerrado',
                        DATE_OF_CREATE: 'Fecha de creado',
                    }, ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        ASYNC_NAME: 'Ya existe un lote con este nombre'
                    }
                }
            },
            LOT_DETAIL_MODAL: {
                LOT_CARD_TITLE: 'Ficha del lote',
                LOT: 'Lote',
                PRODUCTIONS: 'Producciones',
                TOTAL_PRODUCTIONS: 'Total de producciones en el lote.',
                DATE_OF_CLOSE: 'Fecha de cerrado',
                DATE_OF_CREATE: 'Fecha de creado',
                EDIT: 'Editar'
            },
        },
        COOKING_RECIPE: {
            COOKING_RECIPE_PAGE: {
                TITLE: 'Recetas',
                HELP_TOOLTIP: 'Video tutorial para crear una receta.',
                COOKING_RECIPE_TABLE: {
                    TABLE_COLUMN: {
                        NAME: 'Nombre',
                        CANT_TOTAL: 'Cant. producir',
                        UNIT_TOTAL: 'Cant. unidades',
                        CANT_MATERIALS: 'Nº MP',
                        UNITS_MEASURE: 'Unidad',
                    }
                },
                TOOLTIP: {
                    DETAIL: 'Ver ficha receta aquí',
                },
            },
            COOKING_RECIPE_DETAIL_MODAL: {
                CREATE: 'Crear receta',
                EDIT_RECIPE: 'Editar receta',
                DETAIL_RECIPE: 'Ficha de la receta',
                RECIPE: 'Receta',
                QUANTITY: 'Cantidad',
                UNITS: 'Unidades',
                EXPIRATION_DAYS: 'Duración receta',
                DESCRIPTION: 'Estas son la MP que necesitas para producir {{cant_total}} {{measurement_unit}} de {{name}}. Añádelas para iniciar la producción.',
                TOTAL_RAW_MATERIAL: 'MP totales',
                EDIT: 'Editar',
                RAW_MATERIAL_ROW: {
                    LABEL: {
                        RAW_MATERIAL: 'MP',
                        HOMOLOGATE: 'HOM.',
                        QUANTITY: 'CANT.',
                        UNITS: 'UDS.'
                    }
                }
            },
            COOKING_RECIPE_CREATE_MODAL: {
                CREATE: 'Crear receta',
                EDIT_RECIPE: 'Editar receta',
                TEMPORAL_RECIPE: 'Nueva producción sin receta',
                TEMPORAL_RECIPE_PLANNED: 'Nueva planificación sin receta',
                RECIPE: 'Ficha de receta',
                PRODUCTION: 'Producción',
                ADD_RAW_MATERIAL: 'Añade o quita materias primas para modificar la receta.',
                REGISTER: 'Dar de alta',
                UPDATE: 'Actualizar',
                PRODUCE: 'Producir',
                PLANNING: 'Planificar',
                CANT_INFO: 'conversión de cantidad a unidades de producto final',
                FORM: {
                    FIELDS: {
                        NAME: 'Escribe el nombre de la receta',
                        NAME_PRODUCTION: 'Escribe el nombre del producto',
                        QUANTITY: 'Cantidad',
                        UNITS_MEASURE: 'Unidad de medida',
                        UNITS: 'Unidades',
                        SEARCH_RAW_MATERIAL: 'Buscar materia prima',
                        DAYS_EXPIRATION_ALERT: 'Días alerta antes de caducidad',
                        EXPIRATION_DAYS: 'Días caducidad',
                        SAVE_AS_RECIPE: 'Guardar Receta',
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        ASYNC_NAME: 'Ya existe una receta con este nombre'
                    },
                    EMPTY_MATERIALS_INFO: 'No se han seleccionado materias primas'
                },
                RAW_MATERIAL_ROW: {
                    TOOLTIP_ERROR: 'La cantidad no puede superar el stock actual.',
                    LABEL: {
                        RAW_MATERIAL: 'MP',
                        HOMOLOGATE: 'HOM.',
                        QUANTITY: 'CANT.',
                        UNITS: 'UDS.'
                    }
                }
            },
            COOKING_RECIPE_CREATE_THROUGH_XLSX: {
                MODAL_TITLE: 'Crear recetas',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar recetas',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>Si alguna Línea está incompleta el sistema la ignorará</li><li>Siempre que esté disponible seleccionar los valores de los desplegables</li></ul>',
                CREATED: 'Recetas creadas',
            },
            COOKING_RECIPES_EDIT_MASS: {
                MODAL_TITLE: 'Edición masiva de recetas',
                DOWNLOAD_TEMPLATE: 'Descargar todas las recetas',
                DOWNLOAD: 'Descargar',
                DOWNLOADING: 'Descargando...',
                DOWNLOAD_ERROR: 'Algo ha ido mal, por favor inténtalo de nuevo',
                UPLOAD_FILE: 'Subir archivo .ZIP con las recetas editadas',
                STEPS_TITLE: 'Paso a paso para editar las recetas',
                DESCRIPTION_TITLE: 'Información importante',
                STEPS: '<ul><li>Haz clique en el botón para descargar un fichero .ZIP con todas las recetas registradas</li><li>Descomprimir el fichero en su ordenador y editar las recetas (en los archivos .XLSX</li><li>Vuelve a comprimir en un fichero .ZIP todas las recetas que has editado</li><li>Usa el campo abajo para Subir el fichero .ZIP</li><li>Una vez que subas el fichero, se van a editar las recetas en el sistema con los nuevos datos</li></ul>',
                DESCRIPTION: '<ul><li>No cambiar datos de las celdas indicadas con (No editar)</li><li>Si alguna línea está incompleta, el sistema la ignorará</li><li>Solo se aceptarán archivos en formato .XLSx, comprimidos en un fichero .ZIP</li><li>El tamaño máximo recomendado del fichero para subida es de hasta 20mb</li><li>Si desea crear una nueva receta, debes usar el botón de Añadir por xlsx</li></ul>',
                EDITED: 'Recetas editadas',
            }
        },
        CLIENT: {
            CLIENT_PAGE: {
                HEALTH_REGISTER: 'Registro sanitario',
                CONTACT_NAME: 'Contacto',
                PHONE_NUMBER: 'Teléfono',
                EMAIL: 'Correo electrónico',
                ADD_NEW: 'Añadir nuevo',
                HELP_TOOLTIP: 'Video tutorial para crear un cliente.',
                TOOLTIP: {
                    DETAIL: 'Ficha de cliente',
                },
            },
            CLIENT_MODAL: {
                NEW_CLIENT: 'Nuevo Cliente',
                EDIT_CLIENT: 'Ficha de cliente',
                FORM: {
                    INFO: 'Puedes editar los detalles del cliente aquí',
                    FIELDS: {
                        NAME: 'Nombre',
                        ADDRESS: 'Dirección',
                        HEALTH_REGISTER_NUMBER: 'Número de registro sanitario',
                        PHONE_NUMBER: 'Teléfono',
                        CONTACT_NAME: 'Contacto',
                        EMAIL: 'Correo electrónico'
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        MIN_LENGTH: 'El campo debe tener un mínimo de {{value}} caracteres',
                        MAX_LENGTH: 'El campo tiene un límite de {{value}} caracteres',
                        PATTERN: 'El campo solo debe tener números',
                        ASYNC_NAME: 'Ya existe un cliente con este nombre'
                    }
                }
            },
            CLIENT_THROUGH_XLSX: {
                MODAL_TITLE: 'Nuevo Cliente',
                DOWNLOAD_TEMPLATE: 'Descargar plantilla',
                DOWNLOAD: 'Descargar',
                UPLOAD_FILE: 'Subir excel para importar clientes',
                DESCRIPTION_TITLE: 'Información importante',
                DESCRIPTION: '<ul><li>Fichero siempre en formato .xlsx</li><li>El nombre del cliente es único, si está repetido en el xlsx o ya está en el sistema será ignorado.</li></ul>',
                CREATED: 'Clientees creados',
            },
        },
        DELIVERY: {
            DELIVERY_PAGE: {
                TITLE: 'Entrega',
                TITLE_CARD_PRODUCTS: 'Últimos productos',
                TITLE_CARD_DELIVERY: 'Entregas',
                NEW_DELIVERY: 'Nueva Entrega',
                NEW_CLIENT: 'Nuevo Cliente',
                HELP_TOOLTIP: 'Video tutorial para crear una entrega.',
                TOOLTIP: {
                    TRACEABILITY: 'Abrir árbol de trazabilidad',
                    DETAIL: 'Detalles de la entrega',
                },
                DELIVERY_TABLE: {
                    TABS: {
                        LAST_DELIVERIES: 'ÚLTIMAS ENTREGAS',
                        RETURNED_DELIVERIES: 'DEVOLUCIONES',
                    },
                    TABLE_COLUMN: {
                        TR: 'TR',
                        CLIENT: 'CLIENTE',
                        PRODUCTION: 'FECHA DE ENVÍO',
                    },
                    TABLE_COLUMN_RETURN: {
                        CLIENT: 'CLIENTE',
                        RETURN_DATE: 'FECHA DEVOLUCIÓN',
                    }
                },
                CLIENT_TABS: {
                    TABS: {
                        CLIENTS: 'CLIENTES'
                    }
                }
            },
            DELIVERY_MODAL: {
                MODAL_TITLE: 'Añadir Nueva',
                LIST_TITLE: 'Entrega',
                LIST: {
                    NEW_CLIENT: 'Alta cliente',
                    NEW_RETURN: 'Devolución',
                    NEW_DELIVERY: 'Nueva entrega',
                    NEW_DELIVERY_WARNING: 'No puedes crear una entrega sin primero crear materias primas, proveedores y producciones.',
                }
            },
            DELIVERY_CLIENT_MODAL: {
                NEW_DELIVERY: 'Nueva Entrega',
                EDIT_DELIVERY: 'Editar Entrega',
                LAST_CLIENT: 'Últimos clientes',
                RECENT_CLIENT: 'Clientes frecuentes',
                CLIENT: 'Cliente',
                ACCEPTED_DELIVERY: 'Entrega realizada',
                RAW_MATERIAL_ROW: {
                    TOOLTIP_ERROR: 'La cantidad no puede superar el stock actual.',
                    LABEL: {
                        PRODUCT: 'PRODUCTO',
                        HOMOLOGATE: 'HOM.',
                        QUANTITY: 'CANT.',
                        UNITS: 'UDS.'
                    }
                },
                FORM: {
                    FIELDS: {
                        SELECT_CLIENT: 'Selecciona Cliente',
                        DATE_DELIVERY: 'Fecha de envio',
                        SEARCH_RAW_MATERIAL: 'Buscar producto',
                        ROW_MATERIAL_DELIVERY: {
                            TOOLTIP_ERROR: 'El stock no coincide para el envio de este producto.',
                            TOOLTIP_TOTAL_SELECTED: 'Total de producciones para este productos',
                            MODIFY: 'MODIFICAR',
                            ADD: 'AÑADIR PRODUCTO',
                            LOT: 'LOTE',
                            SELECTED_QUANTITY: 'CANT SELEC.',
                            RECEPTION: 'RECEPCIÓN',
                            EXPIRATION: 'CADUCIDAD',
                            QUANTITY: 'CANT',
                            NO_RESULTS: 'No hay recepciones con stock para este producto',
                        },
                    },
                    ERRORS: {
                        CORRECT: 'El campo es correcto',
                        REQUIRED: 'Este campo es requerido',
                        REQUIRED_DATE: 'Este campo es requerido. Por favor, revisa el formato y selecciona una fecha posterior a la fecha de fabricación de los productos fabricados.',
                        NOT_ENOUGH_STOCK: 'No hay suficiente stock de los productos para esta entrega'
                    },
                    EMPTY_MATERIALS_INFO: 'No se han seleccionado productos',
                },
                DELIVERY_RAW_MATERIAL_TITLE: 'Selecciona productos para entrega',
                DELIVERY_RAW_MATERIAL_TEXT: 'Añade los productos y las cantidades que quieras entregar a cliente y despues selecciona los lotes que quieras entregar.'
            },

            DETAIL_DELIVERY_MODAL: {
                DETAIL_DELIVERY: 'Detalles de la entrega',
                CLIENT: 'Cliente',
                DELIVERY_DATE: 'Fecha de envio',
                DELIVERY_RAW_MATERIAL_TEXT: 'Productos y sus lotes que se han entregado al cliente.',
                RETURN: 'Devolución',
                DETAIL_RETURN: 'ver devolución',
                ROW_MATERIAL_DELIVERY: {
                    LOT: 'LOTE',
                    PROVIDER: 'PROVEEDOR',
                    EXPIRATION: 'CADUCIDAD',
                    QUANTITY: 'CANT',
                    NO_RESULTS: 'No hay productos en este envio.'
                }
            },
            RETURN_DELIVERY_MODAL: {
                RETURN_DELIVERY: 'Nueva devolución',
                CLIENT: 'Cliente',
                DELIVERY_DATE: 'Fecha de envio',
                DELIVERY_RAW_MATERIAL_TEXT: 'Productos y sus lotes entregados. Selecciona y marca la cantidad que se ha devuelto.',
                RETURN_ALL_STOCK: 'Todo a stock',
                RETURN_ALL_DECREASE: 'Todo a merma',
                ERROR_INPUT: 'La cantidad a devolver no puede ser superior a la entregada',
                ROW_MATERIAL_DELIVERY: {
                    LOT: 'LOTE',
                    QUANTITY_RETURN_STOCK: 'CANT A STOCK',
                    QUANTITY_RETURN_DECREASE: 'CANT A MERMA',
                    QUANTITY: 'CANT',
                    NO_RESULTS: 'No hay productos en este envio.'
                }
            },
            DETAIL_RETURN_DELIVERY_MODAL: {
                DETAIL_RETURN_DELIVERY: 'Detalles de la devolución',
                CLIENT: 'Cliente',
                RETURN_DELIVERY_DATE: 'Fecha de devolución',
                RETURN_DELIVERY_RAW_MATERIAL_TEXT: 'Productos y lotes que ha devuelto el cliente.',
                RETURN: 'Devolución',
                DETAIL_DELIVERY: 'ver entrega',
                ROW_MATERIAL_DELIVERY: {
                    LOT: 'LOTE',
                    QUANTITY_RETURN_STOCK: 'CANT A STOCK',
                    QUANTITY_RETURN_DECREASE: 'CANT A MERMA',
                    NO_RESULTS: 'No hay productos en esta devolución.'
                }
            },
        },
        TRACEABILITY: {
            BACK: 'TRAZABILIDAD HACIA ATRÁS',
            PRODUCTION: 'TRAZABILIDAD DE PRODUCCIÓN',
            FORWARD: 'TRAZABILIDAD HACIA DELANTE',
            PRODUCTION_DATE: 'Fecha de producción: ',
            DELIVERY_DATE: 'Fecha de entrega: ',
        },
        HELPBUTTON: {
            HELP: 'Ayuda',
            WATCHVIDEOS: 'Ver videotutoriales',
            STARTTUTORIAL: 'Iniciar tutorial',
        },
        WATCHVIDEOSPAGE: {
            TITLE: 'Ayuda',
            CALLTOACCTION: 'Mira nuestras guías y tutoriales',
            SECTION1: {
                TITLE: 'Cómo cargar ÁRBOLES DE TRAZABILIDAD',
                VIDEO_TITLE_1: 'GUÍA TFC: Cómo cargar ÁRBOLES DE TRAZABILIDAD'
            },

            SECTION2: {
                TITLE: 'Cómo crear una NUEVA PRODUCCIÓN con una RECETA',
                VIDEO_TITLE_1: 'GUÍA TFC: Cómo crear una NUEVA PRODUCCIÓN con una RECETA EXISTENTE'
            },

            SECTION3: {
                TITLE: 'Cómo HOMOLOGAR una MATERIA PRIMA y un PROVEEDOR',
                VIDEO_TITLE_1: 'GUÍA TFC: Cómo HOMOLOGAR una MATERIA PRIMA y un PROVEEDOR'
            },

            SECTION4: {
                TITLE: 'Com gestionar RECEPTES (CAT)',
                VIDEO_TITLE_1: 'GUIA TFC: Com gestionar receptes (CAT)'
            },

            SECTION5: {
                TITLE: 'Cómo añadir una NUEVA RECEPCIÓN de materia prima',
                VIDEO_TITLE_1: 'GUÍA TFC: Cómo crear una NUEVA RECEPCIÓN DE MATERIA PRIMA'
            },
        },
        POPOVERMODAL: {
            TITLE: '¡Bienvenido a Total Food Control!',
            PARAGRAPH: 'En los siguientes pasos vamos a mostrarte lo que puedes hacer con nuestra herramienta.',
        },
        WALKTHROUGH: {
            COMMON: {
                START: 'EMPECEMOS',
                REGISTER: 'Clica en <br /> dar de alta',
                CREATE: 'Clica en <br /> crear',
                DONE: '¡Listo!',
                CONTINUE: 'SIGAMOS',
                NEXT: 'SIGUIENTE',
                WATCH_THIS: 'Mira este',
                OK: 'OK!',
                STEP_BY_STEP_TEXT: 'tutorial en el que se explica el paso a paso',
                FINISH: 'FINALIZAR',
            },
            SKIP: 'SALTAR TUTORIAL',
            WALK1: {
                TITLE: 'Haz clic en el menú principal',
                CARD_PARAGRAPH: 'Desde aquí podrás acceder a todas las secciones de Total Food Control'
            },
            WALK2: {
                TITLE: 'Haz clic en almacén',
                CARD_PARAGRAPH: 'Empezaremos por introducir <br /> el primer almacén',
            },
            WALK3: {
                TITLE: 'Haz clic en <br /> <strong>añadir nuevo</strong> <br />para añadir un nuevo<br /> almacén',
            },
            WALK4: {
                TITLE: 'Rellena el nombre del almacén y clica en <br /> <b>dar de alta</b>',
            },
            WALK6: {
                CARD_PARAGRAPH: 'Ya tenemos nuestro primer almacén',
            },
            WALK7: {
                TITLE: 'Puedes también añadir de forma masiva a tus almacenes',
            },
            WALK8: {
                TITLE: 'Subiendo un .xlsx (archivo excel) con la información de todos tus almacenes para no tener que subirlos uno a uno.',
            },
            WALK9: {
                TITLE: 'Vamos a introducir nuestro primer proveedor',
            },
            WALK10: {
                TITLE: 'Haz clic en Proveedores',
                CARD_PARAGRAPH: 'Empezaremos por introducir el primer proveedor',
            },
            WALK11: {
                TITLE: 'Haz clic en <br /> <strong>añadir nuevo</strong> <br />para añadir un nuevo<br />proveedor',
            },
            WALK12: {
                TITLE: 'Rellena los campos del proveedor y clica en <br /> <b>dar de alta</b>',
            },
            WALK14: {
                CARD_PARAGRAPH: 'Ya tenemos nuestro primer proveedor',
            },
            WALK15: {
                TITLE: 'Puedes también añadir de forma masiva a tus proveedores',
            },
            WALK16: {
                TITLE: 'Subiendo un .xlsx (archivo excel) con la información de todos tus proveedores para no tener que subirlos uno a uno.',
            },
            WALK16CONFIRMATION: {
                TITLE: 'Vamos a introducir nuestra primera materia prima',
            },
            WALK17: {
                TITLE: 'Haz clic en Materia Prima',
            },
            WALK18: {
                TITLE: 'Haz clic en <br /><strong>añadir nuevo</strong> <br />para añadir una<br />nueva materia prima',
            },
            WALK19: {
                TITLE: 'Rellena los campos de la materia prima',
                CARD_PARAGRAPH: '<strong>Atención:</strong> es <strong>muy importante</strong> mantener un criterio único en "unidades" para todas las MP.',
                CARD_PARAGRAPH1: '<strong>Decide desde el principio <br />si se van a usar Kg o Gr.</strong>',
            },
            WALK21: {
                CARD_PARAGRAPH: 'Ya tenemos nuestra primera materia prima',
            },
            WALK22: {
                TITLE: 'Puedes también añadir de forma masiva tus materias primas',
            },
            WALK23: {
                TITLE: 'Subiendo un .xlsx (archivo excel) de todas tus materias primas.',
                CARD_PARAGRAPH: 'Si haces la <strong>carga masiva</strong> de materias primas con Excel<strong> podrás actualizar al mismo tiempo el stock real,</strong> loque <strong>te permitirá empezar a producir ya !</strong>',
            },
            WALK25: {
                TITLE: 'Por último, introducimos una recepción',
            },
            WALK26: {
                TITLE: 'Haz clic en Recepción',
            },
            WALK27: {
                TITLE: 'Haz clic en <br /><strong>añadir nuevo</strong> <br />para añadir una<br />nueva recepción',
            },
            WALK28: {
                TITLE: 'Elije una <br />nueva recepción de <br />materia prima',
            },
            WALK29: {
                TITLE: 'Rellena los campos sobre la recepción de materias primas',
            },
            WALK30: {
                TITLE: 'Asigna un almacén',
                CARD_PARAGRAPH: 'Puedes <strong>dar de alta tus almacenes reales existentes,</strong> así podrás asignar recepciones a diferentes almacenes',
            },
            WALK31: {
                TITLE: 'Rellena el control de recepción',
                CARD_PARAGRAPH: 'Puedes hacerlo más tarde',
            },
            WALK33: {
                CARD_PARAGRAPH: 'Ya tenemos nuestra primera recepción',
            },
            WALK34: {
                TITLE: '¡Hemos terminado!',
                CARD_PARAGRAPH: 'Recuerda consultar la ayuda para saber más sobre producciones y entregas',
            },
            PRODUCTION_PLANNED: {
                STEP1: {
                    TITLE: 'Haz clic en<br/> <b>añadir nueva</b><br/> para añadir una nueva receta o producción',
                },
                STEP2: {
                    TITLE: 'En crear receta podrás registrar las fórmulas o recetas de tus productos con sus materias primas / ingredientes'
                },
                STEP3: {
                    TITLE: 'Cuando planificas una producción, el sistema calcula si tienes materias primas suficientes para hacerla pero no se descuentan del stock hasta que ejecutas la producción.'
                }
            },
            PRODUCTION: {
                STEP1: {
                    TITLE: 'Haz clic en<br/> <b>añadir nueva</b><br/> para añadir una nueva receta o producción',
                },
                STEP2: {
                    TITLE: 'En crear receta podrás registrar las combinaciones de materias primas más recurrente'
                },
                STEP3: {
                    TITLE: 'En nueva planificación podrás crear una producción, con o sin receta'
                }
            },
            DELIVERY: {
                STEP1: {
                    TITLE: 'Haz clic en<br/> <b>añadir nueva</b><br/> para añadir una nueva entrega o cliente',
                },
                STEP2: {
                    TITLE: 'En nueva entrega podrás registrar las salidas/ventas de producto final hacia tus clientes',
                },
                STEP3: {
                    TITLE: 'En alta cliente dispones de un formulario para  registrar un cliente.'
                }
            },
            TRACEABILITY: {
                STEP1: {
                    TITLE: 'Aquí es donde verás la trazabilidad de la materia prima una vez la hayas hecho una producción o entrega',
                },
            }
        }
    }
};
