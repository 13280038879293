import {BaseModel} from '@core/models/_base.model';

export class AllergenModel extends BaseModel {
  id: number;
  name: string;

  static jsonToModel(object) {
    const allergen = new AllergenModel();
    allergen.id = object?.id;
    allergen.name = object?.name;
    return allergen;
  }
}
