import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {ProductionModel} from '../_models/production.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {MaterialModel} from '@pages/material/_models/material.model';
import {CookingRecipeModel} from '@pages/cooking-recipe/_models/cooking-recipe.model';
import {MissingStockModel} from '@pages/production/_models/missingStock.model';

@Injectable({
    providedIn: 'root'
})
export class ProductionService {
    API_URL = `${environment.api_host}/api/production/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    findProductions(queryParams?: QueryParamsModel): Observable<QueryResultsModel<ProductionModel>> {
        this._isLoading$.next(true);
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<ProductionModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<ProductionModel>) => {
                const queryResults = new QueryResultsModel<ProductionModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createProduction(production: ProductionModel) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ProductionModel>(url, production.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateProduction(production: ProductionModel): Observable<ProductionModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<ProductionModel>(url, production.saveToAPI(), {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', production, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<ProductionModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<ProductionModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getLastCookingRecipes(): Observable<QueryResultsModel<CookingRecipeModel>> {
        this._isLoading$.next(true);
        const url = this.API_URL + `cooking-recipe/last-used`;
        return this.http.get<CookingRecipeModel[]>(url).pipe(
            map((res: CookingRecipeModel[]) => {
                const queryResults = new QueryResultsModel<CookingRecipeModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getRecentCookingRecipes(): Observable<QueryResultsModel<CookingRecipeModel>> {
        this._isLoading$.next(true);
        const url = this.API_URL + `cooking-recipe/frecuents`;
        return this.http.get<CookingRecipeModel[]>(url).pipe(
            map((res: CookingRecipeModel[]) => {
                const queryResults = new QueryResultsModel<CookingRecipeModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createProductionFromAnother(id: number) {
        this._isLoading$.next(true);
        const url = this.API_URL + `new-production/${id}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<ProductionModel>(url, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err);
                return of({id: undefined});
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    startPlannedProduction(id: number) {
        this._isLoading$.next(true);
        const url = this.API_URL + `start-planned-production/${id}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<ProductionModel>(url, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err);
                return of({id: undefined});
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getProductionCookingRecipeLastUse(queryParams?: QueryParamsModel): Observable<QueryResultsModel<CookingRecipeModel>> {
        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `cooking-recipe/use-in-production`;
        return this.http.get<CookingRecipeModel[]>(url, {params}).pipe(
            map((res: CookingRecipeModel[]) => {
                const queryResults = new QueryResultsModel<CookingRecipeModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            })
        );
    }

    validationRecipeCurrentStock(value: string, id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-stock-by-quantity?quantity=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(false);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    missingRecipeCurrentStock(value: number, id: number): Observable<MissingStockModel[]> {
        this._isLoading$.next(true);
        const url = this.API_URL + `missing-stock-by-quantity?quantity=${value}&id=${id}`;
        return this.http.get<MissingStockModel[]>(url).pipe(
            catchError(() => {
                return of([]);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    isProductionDelivered(id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `is-delivered?id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(false);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getDashboardGraph(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>> {

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        this._isLoading$.next(true);
        const url = this.API_URL + `dashboard/graph`;
        return this.http.get<any>(url, {params}).pipe(
            finalize(() => this._isLoading$.next(false))
        );

    }
}
