import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {AuthHTTPService} from '@modules/auth/_services/auth-http';
import {mergeMapTo} from 'rxjs/operators';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    // tslint:disable-next-line:variable-name
    private _currentMessage = new BehaviorSubject(null);

    constructor(
        private auth: AuthHTTPService,
        private notificationService: NotificationService,
        private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.subscribe(
            // tslint:disable-next-line:variable-name
            (_messaging: any) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );
    }

    /**
     * update token in api
     *
     * @param token token as a value
     */
    updateToken(token) {
        // we can change this function to request our backend service
        this.auth.registerToken(token).subscribe((response) => {
            console.log('response', response);
        }, error => {
            console.error('token not save', error);
        });
    }

    /**
     * request permission for notification from firebase cloud messaging
     */
    requestPermission() {
        this.angularFireMessaging.requestToken
            .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    this.updateToken(token);
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                }
            );
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage(){
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                this.emitChanges();
                // show notifications
                this.notificationService.showActionNotification(payload?.notification?.body, MessageType.Default);

            }, error => console.error('onMessage error', error));
    }

    emitChanges(){
        this._currentMessage.next(true);
    }

    get getMessage$() {
        return this._currentMessage.asObservable();
    }
}
