import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {ApprovedMaterialModel} from '../_models/approved-material.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {MaterialModel} from "@pages/material/_models/material.model";

@Injectable({
    providedIn: 'root'
})
export class ApprovedMaterialService {
    API_URL = `${environment.api_host}/api/raw-material-approval/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    findApprovedMaterials(queryParams: QueryParamsModel): Observable<QueryResultsModel<ApprovedMaterialModel>> {
        // Para añadir el search o clauquier filtro solo falta añadir el queryParams en la petición
        const offset = queryParams.pageNumber * queryParams.pageSize;
        const limit = queryParams.pageSize;
        const url = this.API_URL + `list/${limit}/${offset}/`;

        let params = new HttpParams();
        if ( queryParams?.sortField.length > 0 && queryParams?.sortOrder.length > 0) {
            params = params.append('sort_direction', queryParams ? queryParams?.sortOrder : '');
            params = params.append('sort_field', queryParams ? queryParams?.sortField : '');
        }

        if ( queryParams?.search.length > 0) {
            params = params.append('search_term', queryParams ? queryParams?.search : '');
        }

        return this.http.get<QueryResultsModel<ApprovedMaterialModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<ApprovedMaterialModel>) => {
                const queryResults = new QueryResultsModel<ApprovedMaterialModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            })
        );
    }

    createApprovedMaterials(approvedMaterial: ApprovedMaterialModel) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ApprovedMaterialModel>(url, approvedMaterial.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM ', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createApprovedMaterialThroughXlsx(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-through-xlsx`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM ', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<ApprovedMaterialModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<ApprovedMaterialModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getTemplateXlsx(): any {
        const url = this.API_URL + `download-template-create`;
        return this.http.get(url, {responseType: 'blob'});
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }
}
