import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {WarehouseService} from './_services';
import {WarehouseModel} from './_models/warehouse.model';
import {WarehouseDataSource} from './_dataSource/warehouse.datasource';
import {MatPaginator} from '@angular/material/paginator';
import {debounceTime, tap} from 'rxjs/operators';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {SelectionModel} from '@angular/cdk/collections';
import {NewWarehouseModalPage} from './component/new-warehouse-modal/new-warehouse-modal.page';
import {NewWarehouseThoughXlsxPage} from '@pages/warehouse/component/new-warehouse-though-xlsx/new-warehouse-though-xlsx.page';
import {MatSort} from '@angular/material/sort';
import {fromEvent} from 'rxjs';
import {WarehouseDetailPage} from '@pages/warehouse/component/warehouse-detail/warehouse-detail.page';
import {WalkthroughComponent} from 'angular-walkthrough';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.page.html',
  styleUrls: ['./warehouse.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehousePage implements OnInit, AfterViewInit {

  // Table fields
  dataSource: WarehouseDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchBox') searchBox: ElementRef;
  searchData: any;

  // Selection
  selection = new SelectionModel<WarehouseModel>(true, []);
  warehouseResult: WarehouseModel[] = [];

  constructor(
      public modalController: ModalController,
      private warehouseService: WarehouseService,
  ) {
  }

  /** LOAD DATA */
  ngOnInit() {
    // Init DataSource
    const queryParams = new QueryParamsModel('', 'asc', '', 0, 15, {show_disabled: true});
    this.dataSource = new WarehouseDataSource(this.warehouseService);
    // First load
    this.dataSource.loadWarehouse(queryParams);
    this.dataSource.entitySubject.subscribe(res => (this.warehouseResult = res));
    const subscribe = this.warehouseService.isLoadingOnboarding$.pipe(tap((loading) => {
      if (loading === false) {
        setTimeout(() => {
          WalkthroughComponent.walkthroughNext();
          subscribe.unsubscribe();
        }, 150);
      }
    })).subscribe();
  }

  ngAfterViewInit(): void {
    // Susbcribe to event from paginator
    this.paginator.page
        .pipe(
            tap(() => this.loadWarehouseList())
        )
        .subscribe();

    const search = fromEvent(this.searchBox.nativeElement, 'keyup');
    search.pipe(
        debounceTime(600),
        tap(() => {
          const searchTerm = this.searchData.toLocaleLowerCase();
          const queryParams = new QueryParamsModel(searchTerm, 'asc', '', 0, this.paginator.pageSize, {show_disabled: true});
          this.dataSource.loadWarehouse(queryParams);
        })).subscribe();
  }

  loadWarehouseList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginator.pageIndex,
        this.paginator.pageSize,
        {show_disabled: true}
    );
    this.dataSource.loadWarehouse(queryParams);
    this.selection.clear();
  }

  async openNewWarehouse(warehouse?: WarehouseModel) {
    const modal = await this.modalController.create({
      component: NewWarehouseModalPage,
      backdropDismiss: false,
      componentProps: {
        id: warehouse ? warehouse.id : undefined
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadWarehouseList();
      }
    });

    return await modal.present();
  }

  async openShowWarehouse(warehouse?: WarehouseModel) {
    const modal = await this.modalController.create({
      component: WarehouseDetailPage,
      backdropDismiss: false,
      componentProps: {
        id: warehouse.id
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadWarehouseList();
      }
    });

    return await modal.present();
  }

  async openNewWarehouseXlsx() {
    const modal = await this.modalController.create({
      id: 'NewWarehouseThoughXlsxPage',
      component: NewWarehouseThoughXlsxPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadWarehouseList();
      }
    });

    return await modal.present();
  }
}
