import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DeliveryReturnModel} from '@pages/delivery/_models/deliveryReturn.model';
import {Subscription, throwError} from 'rxjs';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {catchError, first} from 'rxjs/operators';
import {ChecklistComponent} from '@core/components/checklist/checklist.component';
import {DeliveryReturnService} from '@pages/delivery/_services/deliveryReturn.service';
import {NewDeliveryReturnModalPage} from '@pages/delivery/components/new-delivery-return-modal/new-delivery-return-modal.page';

@Component({
  selector: 'app-detail-delivery-return-modal',
  templateUrl: './detail-delivery-return-modal.page.html',
  styleUrls: ['./detail-delivery-return-modal.page.scss'],
})
export class DetailDeliveryReturnModalPage implements OnInit, OnDestroy {

  id: number;
  isLoading$;
  deliveryReturn: DeliveryReturnModel;
  updated = false;

  private subscriptions: Subscription[] = [];


  constructor(
      private modalController: ModalController,
      private deliveryReturnService: DeliveryReturnService,
      public typesUtils: TypesUtilsService,
      private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this.deliveryReturnService.isLoading$;
    this.loadDeliveryReturn();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadDeliveryReturn() {
    if (!this.id) {
      this.deliveryReturn = new DeliveryReturnModel();
    } else {
      const sb = this.deliveryReturnService.getItemById(this.id).pipe(
          first(),
          catchError(async (errorMessage) => {
            console.log(errorMessage);
            this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
            await this.modalController.dismiss(false);
            return throwError(errorMessage);
          })
      ).subscribe((deliveryReturn: DeliveryReturnModel) => {
        this.deliveryReturn = DeliveryReturnModel.jsonToModel(deliveryReturn);
      });
      this.subscriptions.push(sb);
    }
  }

  async closeModal() {
    await this.modalController.dismiss(this.updated);
  }

  getAllergenByProvider(reception_item) {
    const provideIndex = reception_item.raw_material.providers.findIndex((item) => item.id ===  reception_item.provider.id);
    if (provideIndex >= 0) {
      return reception_item.raw_material.providers[provideIndex].homologate;
    }
    return null;
  }

  getHomologateByProvider(reception_item) {
    const provideIndex = reception_item.raw_material.providers.findIndex((item) => item.id ===  reception_item.provider.id);
    if (provideIndex >= 0) {
      return reception_item.raw_material.providers[provideIndex].homologate;
    }
    return null;
  }

  async openNewReturn() {
    const modal = await this.modalController.create({
      component: NewDeliveryReturnModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        deliveryReturnId: this.deliveryReturn.id
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.updated = true;
      }
    });

    return await modal.present();
  }
}
