import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {DetailReceptionMaterialModalPage} from '@pages/reception/components/detail-reception-material-modal/detail-reception-material-modal.page';


@Component({
    selector: 'app-reception-card',
    templateUrl: './reception-card.component.html',
    styleUrls: ['./reception-card.component.scss'],
})
export class ReceptionCardComponent implements OnInit {

    @Input() reception: any;
    @Output() callback = new EventEmitter<boolean>();

    allergens:any = [];
    providerHomologate = false;

    constructor(
        public modalController: ModalController,
    ) {
    }

    ngOnInit() {
        this.getAllergenByProvider();
        this.isProviderHomologate();
    }

    isProviderHomologate() {
        this.reception.raw_material.providers.forEach((item: any) => {
            if (item.id == this.reception.provider?.id){
                this.providerHomologate = item.homologate;
            }
        });
    }

    getAllergenByProvider() {
        this.reception.raw_material.providers.forEach((item: any) => {
            if (item.id == this.reception.provider?.id){
                this.allergens = item.allergen;
            }
        });
        this.allergens = [];
    }

    async openDetailModal(reception_id) {
        const modal = await this.modalController.create({
            id: 'detail-modal',
            component: DetailReceptionMaterialModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: reception_id
            }
        });
        return await modal.present();
    }

}
