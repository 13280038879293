import {BaseModel} from '@core/models/_base.model';

export class WarehouseModel extends BaseModel {
  id: number;
  name: string;
  enabled: boolean = true;
  default: boolean = false;

  static jsonToModel(object) {
    const warehouse = new WarehouseModel();
    warehouse.id = object.id;
    warehouse.name = object.name;
    warehouse.enabled = object.enabled;
    warehouse.default = object.default;

    return warehouse;
  }
}
