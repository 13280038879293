import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DetailProductionCookingRecipeModalPage} from '@pages/production/components/detail-production-cooking-recipe-modal/detail-production-cooking-recipe-modal.page';
import {ProductionModel} from '@pages/production/_models/production.model';

@Component({
  selector: 'app-production-card',
  templateUrl: './production-card.component.html',
  styleUrls: ['./production-card.component.scss'],
})
export class ProductionCardComponent implements OnInit {

  @Input() production: ProductionModel;
  @Output() callback = new EventEmitter<boolean>();

  allergens:any;

  constructor(
      public modalController: ModalController,
      ) { }

  ngOnInit() {
    this.getAllergen();
  }

  async openDetailModal(production_id: any) {
    const modal = await this.modalController.create({
      component: DetailProductionCookingRecipeModalPage,
      backdropDismiss: false,
      componentProps: {
        id: production_id,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        // this.refreshProductionDataList();
      }
    });

    return await modal.present();
  }

  getAllergen(){
    this.allergens = this.production?.allergens.filter((item) => item.contain || item.traces);
  }
}
