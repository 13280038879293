import {BaseModel} from '@core/models/_base.model';
import {DeliveryReturnItemHasReceptionItem} from '@pages/delivery/_models/deliveryReturnItemHasReceptionItem.model';

export class DeliveryReturnItemModel extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    cant: number;
    // tslint:disable-next-line:variable-name
    delivery_return_item_has_reception_items: DeliveryReturnItemHasReceptionItem[];

    static jsonToModel(object) {
        const deliveryReturnItem = new DeliveryReturnItemModel();
        deliveryReturnItem.id = object.id;
        deliveryReturnItem.delivery_return_item_has_reception_items = [];
        object?.reception_return_items?.forEach((item: any) => {
            const deliveryReturnItemHasReceptionItem = DeliveryReturnItemHasReceptionItem.jsonToModel(item);
            deliveryReturnItem.delivery_return_item_has_reception_items.push(deliveryReturnItemHasReceptionItem);
        });

        return deliveryReturnItem;
    }
}
