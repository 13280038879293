import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-allergen-popover',
  templateUrl: './allergen-popover.component.html',
  styleUrls: ['./allergen-popover.component.scss'],
})
export class AllergenPopoverComponent implements OnInit {

  @Input() allergens: any;
  @Input() homologate: boolean;
  @Input() bigBadge: boolean;

  allergenQuantity: number;
  allergenQuantityString: string;

  constructor() { }

  ngOnInit() {
    this.allergensTotal();
    this.countAllergens();
  }

  allergensActive(){
    return  this.allergens?.filter((item) => item.contain || item.traces) || [];
  }

  countAllergens(){
    if (this.homologate || ( this.allergenQuantity > 0)){
      this.allergenQuantityString =  this.allergenQuantity + '';
    }else{
      this.allergenQuantityString =  '-';
    }
  }

  allergensTotal(){
    this.allergenQuantity =  this.allergensActive().length;
  }

}
