import {BaseModel} from '@core/models/_base.model';
import * as _ from 'lodash';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';

interface Allergen {
    allergen_id: number;
    contain: boolean;
    traces: boolean;
    status: number;
}

export class ApprovedMaterialModel extends BaseModel {
    id: number;
    provider: ProviderModel;
    // tslint:disable-next-line:variable-name
    raw_material: MaterialModel;
    allergens: Allergen[] = [];

    static jsonToModel(object) {
        const approvedMaterialModel = new ApprovedMaterialModel();
        approvedMaterialModel.id = object.id;
        approvedMaterialModel.provider = ProviderModel.jsonToModel(object?.provider);
        approvedMaterialModel.raw_material = MaterialModel.jsonToModel(object?.raw_material);
        object?.allergens?.forEach((item: any) => {
            const allergenItem = item as Allergen;
            approvedMaterialModel.allergens.push(allergenItem);
        });
        return approvedMaterialModel;
    }

    countAllergen() {
        return _.filter(this.allergens, (item: Allergen) => item.contain || item.traces).length;
    }

    saveToAPI() {
        return {
            raw_material_id: this.raw_material.id,
            provider_id: this.provider.id,
            allergens: this.allergens.map( (item: Allergen) => {
                return {allergen_id: item.allergen_id, status: item.status};
            })
        };
    }
}
