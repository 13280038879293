export class AuthModel {
  token: string;
  userId: string;
  groups: string[] = [];

  setAuth(auth: any) {
    this.token = auth.token;
    this.userId = auth.userId;
    this.groups = auth.groups;
  }
}
