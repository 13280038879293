import {BaseModel} from '@core/models/_base.model';
import {AllergenModel} from '@common/_models/allergen.model';

export class AllergenItemModel extends BaseModel {
  allergen: AllergenModel;
  contain: boolean;
  traces: boolean;

  static jsonToModel(object) {
    const allergenItem = new AllergenItemModel();
    const allergen = AllergenModel.jsonToModel(object.allergen);
    allergenItem.allergen = allergen;
    allergenItem.contain = object?.contain;
    allergenItem.traces = object?.traces;
    return allergenItem;
  }
}
