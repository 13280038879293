import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {DeliveryModel} from '../_models/delivery.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ClientModel} from '@pages/client/_models/client.model';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService {
    API_URL = `${environment.api_host}/api/delivery/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    findDeliverys(queryParams?: QueryParamsModel): Observable<QueryResultsModel<DeliveryModel>> {
        this._isLoading$.next(true);
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<DeliveryModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<DeliveryModel>) => {
                const queryResults = new QueryResultsModel<DeliveryModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createDelivery(delivery: DeliveryModel) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<DeliveryModel>(url, delivery.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateDelivery(delivery: DeliveryModel): Observable<DeliveryModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<DeliveryModel>(url, delivery.saveToAPI(), {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', delivery, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number ): Observable<DeliveryModel>{
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<DeliveryModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getLastClients(): Observable<QueryResultsModel<ClientModel>> {
        this._isLoading$.next(true);
        const url = this.API_URL + `client/last-used`;
        return this.http.get<ClientModel[]>(url).pipe(
            map((res: ClientModel[]) => {
                const queryResults = new QueryResultsModel<ClientModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getRecentClients(): Observable<QueryResultsModel<ClientModel>>{
        this._isLoading$.next(true);
        const url = this.API_URL + `client/frecuents`;
        return this.http.get<ClientModel[]>(url).pipe(
            map((res: ClientModel[]) => {
                const queryResults = new QueryResultsModel<ClientModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createDeliveryFromAnother(id: number ){
        this._isLoading$.next(true);
        const url = this.API_URL + `new-delivery/${id}`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.get<DeliveryModel>(url, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err);
                return of({ id: undefined });
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getDeliveryClientLastUse(queryParams?: QueryParamsModel): Observable<QueryResultsModel<ClientModel>>{
        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `client/use-in-delivery`;
        return this.http.get<ClientModel[]>(url, {params}).pipe(
            map((res: ClientModel[]) => {
                const queryResults = new QueryResultsModel<ClientModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            })
        );
    }

    validationRecipeCurrentStock(value: string, id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-stock-by-quantity?quantity=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(false);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getDashboardGraph(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>>{

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        this._isLoading$.next(true);
        const url = this.API_URL + `dashboard/graph`;
        return this.http.get<any>(url, {params}).pipe(
            finalize(() => this._isLoading$.next(false))
        );

    }

    getLastDeliveryProducts(queryParams?: QueryParamsModel): Observable<QueryResultsModel<any>>{

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        this._isLoading$.next(true);
        const url = this.API_URL + `last-deliveries`;
        return this.http.get<any[]>(url, {params}).pipe(
            map((res: any[]) => {
                const queryResults = new QueryResultsModel<any>();
                queryResults.results = Object.values(res);
                queryResults.total = Object.values(res).length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }
}
