import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '@core/components/header/header.component';
import {RmStockComponent} from '@core/components/rm-stock/rm-stock.component';
import {IonicModule} from '@ionic/angular';
import {NotificationComponent} from '@core/_notification/notification/notification.component';
import {LoadingComponent} from '@core/components/loading/loading.component';
import {ListItemComponent} from '@core/components/list-item/list-item.component';
import {CircleButtonTextComponent} from '@core/components/circle-button-text/circle-button-text.component';
import {WarehouseCardComponent} from '@core/components/warehouse-card/warehouse-card.component';
import {TranslationModule} from '@modules/i18n/translation.module';
import {LanguageSelectorComponent} from '@core/components/header/language-selector/language-selector.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {AlertDialogComponent} from '@core/components/alert-dialog/alert-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {RawMaterialCardComponent} from '@core/components/raw-material-card/raw-material-card.component';
import {ReceptionCardComponent} from '@core/components/reception-card/reception-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
// import filepond module
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { ChecklistComponent } from './checklist/checklist.component';
import {ProductionCardComponent} from '@core/components/production-card/production-card.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AllergenPopoverComponent} from '@core/components/allergen-popover/allergen-popover.component';
import {MdePopoverModule} from '@material-extended/mde';
import {ProducedRecipesCardComponent} from '@core/components/produced-recipes-card/produced-recipes-card.component';
import {MatButtonModule} from '@angular/material/button';
import {DeliveryItemCardComponent} from '@core/components/delivery-item-card/delivery-item-card.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { WalkthroughModule } from 'angular-walkthrough';
import { NumberHumanize } from "../pipes/numbers.pipe";
registerPlugin(FilePondPluginFileValidateType);

const materialModules = [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    WalkthroughModule,
  ];



@NgModule({
    declarations: [
        HeaderComponent,
        RmStockComponent,
        NotificationComponent,
        LoadingComponent,
        ListItemComponent,
        CircleButtonTextComponent,
        WarehouseCardComponent,
        LanguageSelectorComponent,
        AlertDialogComponent,
        ChecklistComponent,
        RawMaterialCardComponent,
        ReceptionCardComponent,
        ProductionCardComponent,
        AllergenPopoverComponent,
        ProducedRecipesCardComponent,
        DeliveryItemCardComponent,
        OnboardingComponent,
    ],
    exports: [
        HeaderComponent,
        RmStockComponent,
        NotificationComponent,
        LoadingComponent,
        ListItemComponent,
        CircleButtonTextComponent,
        WarehouseCardComponent,
        LanguageSelectorComponent,
        AlertDialogComponent,
        ChecklistComponent,
        RawMaterialCardComponent,
        ReceptionCardComponent,
        ProductionCardComponent,
        AllergenPopoverComponent,
        ProducedRecipesCardComponent,
        DeliveryItemCardComponent,
        OnboardingComponent,
    ],
    providers: [],
    imports: [
        CommonModule,
        IonicModule,
        TranslationModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslationModule,
        ...materialModules,
        FilePondModule,
        MatTooltipModule,
        MdePopoverModule,
        MatButtonModule,
        NumberHumanize
    ]
})
export class CoreComponentsModule {}
