import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, of, Subscription} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {WarehouseService} from '@pages/warehouse/_services';
import {catchError, debounceTime, first, tap} from 'rxjs/operators';
import {NewWarehouseModalPage} from '@pages/warehouse/component/new-warehouse-modal/new-warehouse-modal.page';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {MaterialService} from '@pages/material/_services';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {MaterialDatasource} from '@pages/material/_dataSource/material.datasource';
import {MatPaginator} from '@angular/material/paginator';
import {MaterialModel} from '@pages/material/_models/material.model';
import {WarehouseMaterialDatasource} from '@pages/warehouse/_dataSource/warehouseMaterials.datasource';
import {WarehouseReceptionDetailPage} from '@pages/warehouse/component/warehouse-reception-detail/warehouse-reception-detail.page';
import { AuthService } from "@modules/auth";

@Component({
  selector: 'app-warehouse-detail',
  templateUrl: './warehouse-detail.page.html',
  styleUrls: ['./warehouse-detail.page.scss'],
})
export class WarehouseDetailPage implements OnInit, OnDestroy, AfterViewInit {
  id: number;
  isLoading$;
  warehouse: WarehouseModel;
  updated = false;

  warehouseMaterialDatasource: WarehouseMaterialDatasource;

  @ViewChild('paginatorMaterial',{read:MatPaginator}) paginator: MatPaginator;

  materials: MaterialModel[] = [];
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;
  private subscriptions: Subscription[] = [];

  constructor(
      private modalController: ModalController,
      private warehouseService: WarehouseService,
      private authService: AuthService,
  ) {}

  ngOnInit() {
    this.isLoading$ = this.warehouseService.isLoading$;
    this.loadWarehouse();
    // Init DataSource
    const queryParams = new QueryParamsModel('', 'asc', '', 0, 10, {warehouse_id: this.id});
    this.warehouseMaterialDatasource = new WarehouseMaterialDatasource(this.warehouseService);
    // First load
    this.warehouseMaterialDatasource.loadWarehouseMaterials(queryParams);
    this.warehouseMaterialDatasource.entitySubject.subscribe(res => (this.materials = res));
  }

  ngAfterViewInit(): void {
    // Susbcribe to event from paginator
    setTimeout(()=>{
      this.paginator.page
          .pipe(
              tap(() => this.loadMaterialsList())
          )
          .subscribe();
    },1000)

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadMaterialsList() {
    const queryParams = new QueryParamsModel(
        '',
        'DESC',
        'name',
        this.paginator.pageIndex,
        this.paginator.pageSize,
        {warehouse_id: this.id}
    );
    this.warehouseMaterialDatasource.loadWarehouseMaterials(queryParams);
  }

  loadWarehouse() {
    if (!this.id) {
      this.warehouse = new WarehouseModel();
    } else {
      const sb = this.warehouseService.getItemById(this.id).pipe(
          first(),
          catchError((errorMessage) => {
            console.log('error', errorMessage);
            return of(new WarehouseModel());
          })
      ).subscribe((warehouse: WarehouseModel) => {
        this.warehouse = WarehouseModel.jsonToModel(warehouse);
      });
      this.subscriptions.push(sb);
    }
  }

  async closeModal() {
    await this.modalController.dismiss(this.updated);
  }

  async addNew(
      rawWarehouse?: WarehouseModel,
  ) {
    const modal = await this.modalController.create({
      component: NewWarehouseModalPage,
      backdropDismiss: false,
      componentProps: {
        id: rawWarehouse ? rawWarehouse.id : undefined,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((response) => {
      this.updated = this.updated || !!response.data;
      if (response.data) { this.loadWarehouse(); }
    });

    return await modal.present();
  }


  async openWarehouseReception(materialId: number, currentStock: number) {
    const modal = await this.modalController.create({
      component: WarehouseReceptionDetailPage,
      backdropDismiss: false,
      componentProps: {
        materialId: materialId,
        currentStock: currentStock
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadWarehouse();
        this.loadMaterialsList();
      }
    });

    return await modal.present();
  }
}
