import {BaseModel} from '@core/models/_base.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';

export class DeliveryReturnItemHasReceptionItem extends BaseModel{
    // tslint:disable-next-line:variable-name
    cantStock: number;
    // tslint:disable-next-line:variable-name
    cantDecrease: number;
    // tslint:disable-next-line:variable-name
    reception_item: ReceptionItem;

    static jsonToModel(object) {
        const deliveryItemHasReceptionItem = new DeliveryReturnItemHasReceptionItem();
        deliveryItemHasReceptionItem.cantStock = +object.toStock;
        deliveryItemHasReceptionItem.cantDecrease = +object.toDecrease;
        deliveryItemHasReceptionItem.reception_item = ReceptionItem.jsonToModel(object.receptionItem);

        return deliveryItemHasReceptionItem;
    }
}
