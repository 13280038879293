import {BaseModel} from '@core/models/_base.model';

export class MeasurementUnitModel extends BaseModel {
  id = 0;
  name = 'new';

  isNew(): boolean {
    return this.id === 0 && this.name === 'new';
  }
}
