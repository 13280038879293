import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ProductionModel} from '@pages/production/_models/production.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {of, Subscription, throwError} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {ProductionService} from '@pages/production/_services';
import {catchError, first} from 'rxjs/operators';
import {
    NewProductionCookingRecipeModalPage
} from '@pages/production/components/new-production-cooking-recipe-modal/new-production-cooking-recipe-modal.page';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {ChecklistComponent} from '@core/components/checklist/checklist.component';
import {EditProductionModalPage} from '@pages/production/components/edit-production-modal/edit-production-modal.page';

@Component({
    selector: 'app-detail-production-cooking-recipe-modal',
    templateUrl: './detail-production-cooking-recipe-modal.page.html',
    styleUrls: ['./detail-production-cooking-recipe-modal.page.scss'],
})
export class DetailProductionCookingRecipeModalPage implements OnInit, OnDestroy {

    id: number;
    planned: boolean;
    showFooterButtons: boolean;
    isLoading$;
    production: ProductionModel;
    totalUds: number;
    udsByCant: number;
    measurement_unit: MeasurementUnitModel = new MeasurementUnitModel();
    updated = false;
    checklist: boolean = false;
    reProduction: boolean = true;
    realProduction: boolean = true;

    private subscriptions: Subscription[] = [];


    constructor(
        private modalController: ModalController,
        private productionService: ProductionService,
        public typesUtils: TypesUtilsService,
        private notificationService: NotificationService,
        public dialog: MatDialog) {
    }

    ngOnInit() {
        this.isLoading$ = this.productionService.isLoading$;
        this.loadProduction();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadProduction() {
        if (!this.id) {
            this.production = new ProductionModel();
        } else {
            const sb = this.productionService.getItemById(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((production: ProductionModel) => {
                this.production = ProductionModel.jsonToModel(production);
                this.totalUds = ((this.production.total * this.production.cooking_recipe?.unit_total) / this.production.cooking_recipe?.cant_total);
                this.udsByCant = Math.round((this.production.cooking_recipe?.unit_total / this.production.cooking_recipe?.cant_total) * 10) / 10;
            });
            this.subscriptions.push(sb);
        }
    }

    async closeModal() {
        await this.modalController.dismiss(this.updated);
    }

    doChecklist() {
        this.editChecklist();

    }

    async editChecklist() {
        const modal = await this.modalController.create({
            component: ChecklistComponent,
            backdropDismiss: false,
            componentProps: {
                idChecklist: 2,
                idResultChecklist: this.production.checklist_id,
                entity: 'PRODUCTION',
                entity_id: this.production.id,
                action: 'detail'
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((response) => {
            if (response.data) this.updated = true;
            this.closeModal();
        });

        return await modal.present();
    }

    async editModal(
        production?: ProductionModel,
    ) {
        const modal = await this.modalController.create({
            component: NewProductionCookingRecipeModalPage,
            backdropDismiss: false,
            componentProps: {
                id: production ? production.id : undefined,
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((response) => {
            this.updated = this.updated || !!response.data;
            if (response.data) {
                this.loadProduction();
            }
        });

        return await modal.present();
    }

    countAllergen() {
        const allergenCount = this.production.allergens?.filter((item) => item.contain || item.traces).length;
        return allergenCount === 0 ? '-' : allergenCount;
    }

    getAllergenByProvider(reception_item) {
        const provideIndex = reception_item.raw_material.providers.findIndex((item) => item.id === reception_item.provider.id);
        if (provideIndex >= 0) {
            return reception_item.raw_material.providers[provideIndex].homologate;
        }
        return null;
    }

    getHomologateByProvider(reception_item) {
        const provideIndex = reception_item.raw_material.providers.findIndex((item) => item.id === reception_item.provider.id);
        if (provideIndex >= 0) {
            return reception_item.raw_material.providers[provideIndex].homologate;
        }
        return null;
    }

    reProductionModal() {

        const cnp = this.productionService.createProductionFromAnother(this.id).pipe(
            catchError((errorMessage) => {
                return of(new ProductionModel());
            })
        ).subscribe(async (production: ProductionModel) => {
            const modal = await this.modalController.create({
                component: NewProductionCookingRecipeModalPage,
                backdropDismiss: false,
                componentProps: {
                    id: production.id || undefined,
                    planned: this.planned,
                },
                cssClass: 'right-side-modal-css',
            });

            modal.onWillDismiss().then((response) => {
                this.updated = this.updated || !!response.data;
                return this.modalController.dismiss(this.updated, undefined, 'production-detail-modal');
            });

            await modal.present();
        });
        this.subscriptions.push(cnp);
    }

    openDialog(): void {


        const dialogRef = this.dialog.open(AddDecreaseDialog, {
            width: '420px',
            data: {total: this.production.total, decrease: this.production.decrease}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.production.decrease = this.production.total - result;
                const sbUpdate = this.productionService.updateProduction(this.production).pipe(
                    catchError((errorMessage) => {
                        this.notificationService.showActionNotification('COMMON.DATA_UPDATED_ERROR', MessageType.Error);
                        return of(this.production);
                    }),
                ).subscribe(async (res: ProductionModel) => {
                    this.updated = true;
                    this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
                });
                this.subscriptions.push(sbUpdate);
            }
        });
    }

    async editProduction() {
        const modal = await this.modalController.create({
            id: 'production-detail-modal',
            component: EditProductionModalPage,
            backdropDismiss: false,
            componentProps: {
                id: this.id,
            },
            cssClass: 'right-side-modal-css',
        });

        modal.onDidDismiss().then((updated) => {
            if (updated.data) {
                this.updated = true;
                this.loadProduction();
            }
        });

        return await modal.present();
    }
}

export interface DialogData {
    total: number;
    decrease: number;
}

@Component({
    selector: 'dialog-add-decrease',
    templateUrl: 'dialog-add-decrease.html',
    styleUrls: ['./dialog-add-decrease.scss'],
})
export class AddDecreaseDialog {
    formControl = new UntypedFormControl((this.data.total - this.data.decrease), Validators.compose([Validators.required, Validators.min(0), Validators.max(this.data.total)]))

    constructor(
        public dialogRef: MatDialogRef<AddDecreaseDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }


    // helpers for View
    isControlValid(): boolean {
        const control = this.formControl;
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(): boolean {
        const control = this.formControl;
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation): boolean {
        const control = this.formControl;
        return control.hasError(validation) && (control.dirty || control.touched);
    }
}
