import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {NotificationModel} from '@modules/notifications/_models/notification.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    API_URL = `${environment.api_host}/api/notification/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    getItems(queryParams?: QueryParamsModel): Observable<QueryResultsModel<NotificationModel>> {
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        this._isLoading$.next(true);
        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<NotificationModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<NotificationModel>) => {
                const queryResults = new QueryResultsModel<NotificationModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<NotificationModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<NotificationModel>(url).pipe(
            catchError(err => {
                console.error('GET ITEM BY ID: ', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    markAsRead(id: number): Observable<any> {
        const url = this.API_URL + `read`;
        return this.http.post<any>(url, {notification_id: id}).pipe(
            catchError(err => {
                console.error('GET ITEM BY ID: ', id, err);
                return throwError(err);
            }),
            tap(() => {
                return of(true);
            })
        );
    }

    markAsDelete(id: number): Observable<any> {
        const url = this.API_URL + `delete`;
        return this.http.post<any>(url, {notification_id: id}).pipe(
            catchError(err => {
                console.error('GET ITEM BY ID: ', id, err);
                return throwError(err);
            }),
            tap(() => {
                return of(true);
            })
        );
    }

    getBagdeNotifications(): Observable<any> {
        const url = this.API_URL + `total-bagde`;
        return this.http.get<any>(url, ).pipe(
            catchError(err => {
                console.error('GET ITEM BY ID: ', err);
                return throwError(err);
            }),
        );
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }
}
