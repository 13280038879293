import {BaseModel} from '@core/models/_base.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {MaterialModel} from '@pages/material/_models/material.model';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {AllergenItemModel} from '@common/_models/allergenItem.model';

export class ReceptionItem extends BaseModel{
    id: number;
    provider: ProviderModel;
    // tslint:disable-next-line:variable-name
    raw_material: MaterialModel;
    wharehouse: WarehouseModel;
    cant: number;
    // tslint:disable-next-line:variable-name
    current_stock: number;
    // tslint:disable-next-line:variable-name
    num_lote: string;
    // tslint:disable-next-line:variable-name
    date_expiry: any;
    // tslint:disable-next-line:variable-name
    reception_id: number;
    // tslint:disable-next-line:variable-name
    reception_delivery_date: string;

    allergens: AllergenItemModel[];

    static jsonToModel(object) {
            const material = MaterialModel.jsonToModel(object.raw_material);
            const provider = ProviderModel.jsonToModel(object?.provider);
            const warehouse = WarehouseModel.jsonToModel(object.wharehouse);
            const receptionItem = new ReceptionItem();
            receptionItem.id = object.id;
            receptionItem.provider = provider;
            receptionItem.raw_material = material;
            receptionItem.wharehouse = warehouse;
            receptionItem.cant = +object.cant;
            receptionItem.num_lote = object.num_lote;
            receptionItem.date_expiry = new Date(object.date_expiry);
            receptionItem.allergens = [];
            object?.allergens?.forEach((item: any ) => {
                const allergenItem = AllergenItemModel.jsonToModel(item);
                receptionItem.allergens.push(allergenItem);
            });
            return receptionItem;
    }
}
