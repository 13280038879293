import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-warehouse-card',
    templateUrl: './warehouse-card.component.html',
    styleUrls: ['./warehouse-card.component.scss'],
})
export class WarehouseCardComponent implements OnInit {
    @Input() warehouse: WarehouseModel;
    @Input() percentageCapacity: number;
    @Output() openNew = new EventEmitter<number>();
    @Output() openShow = new EventEmitter<number>();
    isOpenNew = true;
    isOpenShow = true;

    constructor(
        public modalController: ModalController,
        ) {
    }

    ngOnInit() {
        this.isOpenNew = this.openNew.observers.length > 0;
        this.isOpenShow = this.openShow.observers.length > 0;
    }


    openNewFunction(value) {
        this.openNew.emit(value);
    }

    openShowFunction(value) {
        this.openShow.emit(value);
    }

}
