import { Injectable } from '@angular/core';
import { NotificationComponent } from '@core/_notification/notification/notification.component';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

export enum MessageType {
  Success = 'success-snackbar',
  Warning = 'warning-snackbar',
  Error = 'error-snackbar',
  Info = 'info-snackbar',
  Default = '1234'
}
@Injectable()
export class NotificationService {
  constructor(private snackBar: MatSnackBar,
              private dialog: MatDialog) { }
  // SnackBar for notifications
  showActionNotification(
      message: string,
      type: MessageType = MessageType.Default,
      duration: number = 3000,
      showCloseButton: boolean = true,
      verticalPosition: 'top' | 'bottom' = 'top'
  ) {
    return this.snackBar.openFromComponent(NotificationComponent, {
      duration,
      panelClass: [type],
      data: {
        message,
        snackBar: this.snackBar,
        showCloseButton,
        verticalPosition,
      },
      verticalPosition
    });
  }
}
