import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatPaginator} from '@angular/material/paginator';
import {ReceptionService} from './_services';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {ReceptionDataSource} from './_dataSource/reception.datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {debounceTime, finalize, tap} from 'rxjs/operators';
import {NewReceptionModalPage} from '@pages/reception/components/new-reception-modal/new-reception-modal.page';
import {
  DetailReceptionMaterialModalPage
} from '@pages/reception/components/detail-reception-material-modal/detail-reception-material-modal.page';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {MaterialService} from '@pages/material/_services';
import {ProviderService} from '@pages/provider/_services';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {UntypedFormControl} from '@angular/forms';
import {NewMaterialModalPage} from '@pages/material/components/new-material-modal/new-material-modal.page';
import {NewReceptionMaterialModalPage} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';
import {WalkthroughComponent} from 'angular-walkthrough';

moment.locale('es');

@Component({
  selector: 'app-reception',
  templateUrl: './reception.page.html',
  styleUrls: ['./reception.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ReceptionPage implements OnInit, AfterViewInit, OnDestroy {

  columnsToDisplay = [
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.TR',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.LOTE',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.MP',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.CANT',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.EXPIRATION',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.RECEPTION_DATE',
    'RECEPTION.RECEPTION_PAGE.RECEPTION_TABLE.TABLE_COLUMN.PROVIDER',
    'ACTIONS'
  ];
  dataSourceRecent: ReceptionDataSource;
  dataSourceToBeCompleted: ReceptionDataSource;
  dataSourceChecklist: ReceptionDataSource;
  dataSourceNextExpire: ReceptionDataSource;
  dataSourceExpired: ReceptionDataSource;

  @ViewChild('recents', {read: MatPaginator}) paginatorRecents: MatPaginator;
  @ViewChild('toBeCompleted', {read: MatPaginator}) paginatorToBeCompleted: MatPaginator;
  @ViewChild('checklist', {read: MatPaginator}) paginatorChecklist: MatPaginator;
  @ViewChild('nextExpire', {read: MatPaginator}) paginatorNextExpire: MatPaginator;
  @ViewChild('expired', {read: MatPaginator}) paginatorExpired: MatPaginator;

  // Selection
  selection = new SelectionModel<ReceptionItem>(true, []);
  receptionsResult: ReceptionItem[] = [];

  filteredMaterialOptions: MaterialModel[];
  filteredProviderOptions: ProviderModel[];
  isSearchProvider = false;
  isSearchMaterial = false;
  @ViewChild('providerSearchE') providerSearchElement: ElementRef;
  providerSearch: string;
  @ViewChild('materialSearchE') materialSearchElement: ElementRef;
  materialSearch: string;
  currentProvider: number;
  currentMaterial: number;
  searchControl = new UntypedFormControl();
  searchTerm: string;
  // por defecto los últimos 7 días
  dateFilter = {
    startDate: localStorage.getItem('filter_date_start') ? moment(new Date(localStorage.getItem('filter_date_start'))) : moment().subtract(6, 'days'),
    endDate: localStorage.getItem('filter_date_end') ? moment(new Date(localStorage.getItem('filter_date_end'))) : moment()
  };
  ranges: any = {
    Hoy: [moment(), moment()],
    Ayer: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'El mes Pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };
  haveProvider = false;
  haveMaterial = false;

  // Materials last use
  isLoading$;
  materialLastUseList: Observable<QueryResultsModel<MaterialModel>>;

  private subscriptions: Subscription[] = [];

  constructor(
      public modalController: ModalController,
      public cd: ChangeDetectorRef,
      private receptionService: ReceptionService,
      public typesUtils: TypesUtilsService,
      private materialService: MaterialService,
      private providerService: ProviderService,
  ) {
  }

  /** LOAD DATA */
  ngOnInit() {
    this.checkRawMaterials();
    this.checkProviders();
    // Init Loading
    this.isLoading$ = this.receptionService.isLoading$;

    this.searchControl.valueChanges.pipe(debounceTime(1500))
        .subscribe(searchTerm => {
          this.searchTerm = searchTerm;
          this.refreshReceptionDataList();
        });

    // Init DataSource
    const startDate = this.dateFilter.startDate.format('yyyy-MM-DD');
    const endDate = this.dateFilter.endDate.format('yyyy-MM-DD');
    const queryParams = new QueryParamsModel('', null, null, 0, 50, {start_date: startDate, end_date: endDate});
    const queryToBeCompleted = new QueryParamsModel('', null, null, 0, 50, {pending: true, start_date: startDate, end_date: endDate});
    const queryChecklist = new QueryParamsModel('', null, null, 0, 50,
        {not_has_checklist: true, start_date: startDate, end_date: endDate}
    );
    const queryNextExpire = new QueryParamsModel('', null, null, 0, 50,
        {next_to_expire: true, start_date: startDate, end_date: endDate}
    );
    const queryExpired = new QueryParamsModel('', null, null, 0, 50,
        {expired: true, start_date: startDate, end_date: endDate}
    );
    // DataSources init
    this.dataSourceRecent = new ReceptionDataSource(this.receptionService);
    this.dataSourceToBeCompleted = new ReceptionDataSource(this.receptionService);
    this.dataSourceChecklist = new ReceptionDataSource(this.receptionService);
    this.dataSourceNextExpire = new ReceptionDataSource(this.receptionService);
    this.dataSourceExpired = new ReceptionDataSource(this.receptionService);
    // First load
    this.dataSourceRecent.loadReceptions(queryParams);
    this.dataSourceToBeCompleted.loadReceptions(queryToBeCompleted);
    this.dataSourceChecklist.loadReceptions(queryChecklist);
    this.dataSourceNextExpire.loadReceptions(queryNextExpire);
    this.dataSourceExpired.loadReceptions(queryExpired);

    this.dataSourceRecent.entitySubject.subscribe(res => (this.receptionsResult = res));

    // First Load Material Last use list
    this.loadMaterialsLastUse();
    // First load filters
    this.loadFirstProvider();
    this.loadFirstMaterial();
    const subscribe = this.receptionService.isLoadingOnboarding$.pipe(tap((loading) => {
      console.log('receptionLoading: ', loading);
      if (loading === false) {
        setTimeout(() => {
          WalkthroughComponent.walkthroughNext();
          subscribe.unsubscribe();
        }, 150);
      }
    })).subscribe();
  }

  ngAfterViewInit(): void {
    // Susbcribe to event from paginator
    this.paginatorRecents.page
        .pipe(
            tap(() => this.loadReceptionsList())
        )
        .subscribe();
    this.paginatorToBeCompleted.page
        .pipe(
            tap(() => this.loadToBeCompletedList())
        )
        .subscribe();
    this.paginatorChecklist.page
        .pipe(
            tap(() => this.loadReceptionsChecklist())
        )
        .subscribe();
    this.paginatorNextExpire.page
        .pipe(
            tap(() => this.loadReceptionsNextExpire())
        )
        .subscribe();
    this.paginatorExpired.page
        .pipe(
            tap(() => this.loadReceptionsExpired())
        )
        .subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadFirstProvider() {
    this.isSearchProvider = true;
    const loadFirstProvider = this.providerService.findProviders(new QueryParamsModel()).pipe(
        finalize(() => {
          this.isSearchProvider = false;
          this.cd.detectChanges();
        }),
    ).subscribe(data => this.filteredProviderOptions = data.results);
    this.subscriptions.push(loadFirstProvider);
  }

  loadFirstMaterial() {
    this.isSearchMaterial = true;
    const loadFirstMaterial = this.materialService.findMaterials(
        new QueryParamsModel('', 'asc', '', 0, 50, {show_products: false})
    ).pipe(
        finalize(() => {
          this.isSearchMaterial = false;
          this.cd.detectChanges();
        }),
    ).subscribe(data => this.filteredMaterialOptions = data.results);
    this.subscriptions.push(loadFirstMaterial);
  }

  loadReceptionsList() {

    const filters = this.getFilters();
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginatorRecents.pageIndex,
        this.paginatorRecents.pageSize,
        filters
    );
    this.dataSourceRecent.loadReceptions(queryParams);
    this.selection.clear();
  }

  loadToBeCompletedList() {
    const filters = this.getFilters();
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginatorToBeCompleted.pageIndex,
        this.paginatorToBeCompleted.pageSize,
        _.merge({pending: true}, filters)
    );
    this.dataSourceToBeCompleted.loadReceptions(queryParams);
    this.selection.clear();
  }


  loadReceptionsChecklist() {
    const filters = this.getFilters();
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginatorChecklist.pageIndex,
        this.paginatorChecklist.pageSize,
        _.merge({not_has_checklist: true}, filters)
    );
    this.dataSourceChecklist.loadReceptions(queryParams);
    this.selection.clear();
  }

  loadReceptionsNextExpire() {
    const filters = this.getFilters();
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginatorNextExpire.pageIndex,
        this.paginatorNextExpire.pageSize,
        _.merge({next_to_expire: true}, filters)
    );
    this.dataSourceNextExpire.loadReceptions(queryParams);
    this.selection.clear();
  }

  loadReceptionsExpired() {
    const filters = this.getFilters();
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        this.paginatorExpired.pageIndex,
        this.paginatorExpired.pageSize,
        _.merge({expired: true}, filters)
    );
    this.dataSourceExpired.loadReceptions(queryParams);
    this.selection.clear();
  }

  loadMaterialsLastUse() {
    const filters = this.getFilters();
    const queryParams = new QueryParamsModel(
        '',
        'asc',
        '',
        undefined,
        undefined,
        filters
    );
    this.materialLastUseList = this.receptionService.getMaterialsLastUse(queryParams);
  }

  onChangeProvider(value: string) {

    if (value.length === 0) {
      this.currentProvider = undefined;
      this.loadFirstProvider();
    } else {
      this.isSearchProvider = true;
      this.providerService.findProviders(new QueryParamsModel(value)).pipe(
          debounceTime(300),
          finalize(() => {
            this.isSearchProvider = false;
            this.cd.detectChanges();
          }),
      ).subscribe(data => this.filteredProviderOptions = data.results);
    }

  }

  onChangeMaterial(value: string) {
    if (value.length === 0) {
      this.currentMaterial = undefined;
      this.loadFirstMaterial();
    } else {
      this.isSearchMaterial = true;
      this.materialService.findMaterials(new QueryParamsModel(value, 'asc', '', 0, 50, {show_products: false})).pipe(
          debounceTime(300),
          finalize(() => {
            this.isSearchMaterial = false;
            this.cd.detectChanges();
          }),
      ).subscribe(data => this.filteredMaterialOptions = data.results);
    }
  }

  // tslint:disable-next-line:variable-name
  async openDetailModal(reception_id) {
    const modal = await this.modalController.create({
      component: DetailReceptionMaterialModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: reception_id
      }
    });
    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadReceptionsChecklist();
        this.loadReceptionsExpired();
        this.loadReceptionsNextExpire();
        this.loadReceptionsList();
        this.loadToBeCompletedList();
      }
    });
    return await modal.present();
  }

  async showTracebility(item) {
    alert('Aún en desarrollo' + item);
  }

  async openModal() {
    const modal = await this.modalController.create({
      id: 'NewReceptionModal',
      component: NewReceptionModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined,
        haveProvider: this.haveProvider,
        haveMaterial: this.haveMaterial,
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadReceptionsChecklist();
        this.loadReceptionsExpired();
        this.loadReceptionsNextExpire();
        this.loadReceptionsList();
        this.loadToBeCompletedList();
      }
    });

    return await modal.present();
  }

  onChangeDateFilters(value: any) {
    if (value?.startDate && value?.endDate) {
      this.refreshReceptionDataList();
      localStorage.setItem('filter_date_start', value.startDate);
      localStorage.setItem('filter_date_end', value.endDate);
    }
  }

  onSelectProvider(providerId: number) {
    this.currentProvider = providerId;
    this.refreshReceptionDataList();
  }

  onSelectmaterial(materialId: number) {
    this.currentMaterial = materialId;
    this.refreshReceptionDataList();
  }

  clearFilters() {

    this.providerSearchElement.nativeElement.value = '';
    this.materialSearchElement.nativeElement.value = '';
    this.searchControl.reset();
    // por defecto los últimos 7 días
    this.dateFilter = {startDate: moment().subtract(6, 'days'), endDate: moment()};
    localStorage.removeItem('filter_date_start');
    localStorage.removeItem('filter_date_end');
    this.currentMaterial = undefined;
    this.currentProvider = undefined;
    this.refreshReceptionDataList();
    this.loadFirstProvider();
    this.loadFirstMaterial();
  }

  getFilters() {
    // filterData
    const filters: {
      start_date: string,
      end_date: string,
      raw_material_id: number,
      provider_id: number,
      search_term: string,
    } = {
      start_date: '',
      end_date: '',
      raw_material_id: 0,
      provider_id: 0,
      search_term: '',
    };
    if (this.dateFilter?.startDate && this.dateFilter?.endDate) {
      const startDate = this.dateFilter.startDate.format('yyyy-MM-DD');
      const endDate = this.dateFilter.endDate.format('yyyy-MM-DD');

      filters.start_date = startDate;
      filters.end_date = endDate;
    }

    if (this.currentMaterial) {
      filters.raw_material_id = this.currentMaterial;
    }

    if (this.currentProvider) {
      filters.provider_id = this.currentProvider;
    }

    if (this.searchTerm) {
      filters.search_term = this.searchTerm;
    }
    return filters;

  }

  refreshReceptionDataList() {
    this.loadReceptionsList();
    this.loadToBeCompletedList();
    this.loadReceptionsChecklist();
    this.loadReceptionsNextExpire();
    this.loadReceptionsExpired();
  }

  async createMaterial() {
    const modal = await this.modalController.create({
      id: 'NewMaterialModal',
      component: NewMaterialModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadMaterialsLastUse();
      }
    });

    return await modal.present();
  }

  refreshMaterial(value) {
    if (value) {
      this.loadMaterialsLastUse();
    }
  }

  async openNewReception() {
    const modal = await this.modalController.create({
      id: 'NewReceptionMaterialModal',
      component: NewReceptionMaterialModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.refreshReceptionDataList();
      }
    });

    return await modal.present();
  }

  clearProviderInput() {
    this.providerSearch = '';
    this.onChangeProvider(this.providerSearch);
  }

  clearMaterialInput() {
    this.materialSearch = '';
    this.onChangeMaterial(this.materialSearch);
  }

  checkRawMaterials() {
    this.materialService.findMaterials(new QueryParamsModel('', 'asc', '', 0, 1, {show_products: false})).subscribe(
        (data) => {
          this.haveMaterial = data.results.length > 0;
        },
        (error) => {
          console.log(error);
        }
    );
  }


  checkProviders() {
    this.providerService.findProviders(new QueryParamsModel('', 'asc', '', 0, 1, {show_products: false})).subscribe(
        (data) => {
          this.haveProvider = data.results.length > 0;
        },
        (error) => {
          console.log('ERROR:' + error);
        }
    );
  }
}
