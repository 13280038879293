import {Component, OnDestroy, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NewMaterialModalPage } from '../new-material-modal/new-material-modal.page';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {MaterialModel} from '../../_models/material.model';
import {MaterialService} from '@pages/material/_services';
import {catchError, first} from 'rxjs/operators';
import {of, Subscription} from 'rxjs';
import {NewApprovedMaterialModalPage} from '@pages/approved-material/components/new-approved-material-modal/new-approved-material-modal.page';
import { AuthService } from "@modules/auth";
@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.page.html',
  styleUrls: ['./material-detail.page.scss'],
})
export class MaterialDetailPage implements OnInit, OnDestroy {
  id: number;
  isLoading$;
  material: MaterialModel;
  // tslint:disable-next-line:variable-name
  measurement_unit: MeasurementUnitModel = new MeasurementUnitModel();
  providers: ProviderModel[] = [];
  updated = false;
  homologatedProviders: ProviderModel[] = [];

  private subscriptions: Subscription[] = [];
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;

  constructor(
    private modalController: ModalController,
    private materialService: MaterialService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.isLoading$ = this.materialService.isLoading$;
    this.loadMaterial();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  loadMaterial() {
    if (!this.id) {
      this.material = new MaterialModel();
    } else {
      const sb = this.materialService.getItemById(this.id).pipe(
          first(),
          catchError((errorMessage) => {
            console.log('error', errorMessage);
            return of(new MaterialModel());
          })
      ).subscribe((material: MaterialModel) => {
        this.material = MaterialModel.jsonToModel(material);
        this.homologatedProviders = this.material.providers.filter((item) => item.homologate);
      });
      this.subscriptions.push(sb);
    }
  }

  async closeModal() {
    await this.modalController.dismiss(this.updated);
  }

  async addNew(
    rawMaterial?: MaterialModel,
  ) {
    const modal = await this.modalController.create({
      component: NewMaterialModalPage,
      backdropDismiss: false,
      componentProps: {
        id: rawMaterial ? rawMaterial.id : undefined,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((response) => {
      this.updated = this.updated || !!response.data;
      if (response.data) { this.loadMaterial(); }
    });

    return await modal.present();
  }

  async openApprovedMaterialModal(material, provider? ,approvedMaterialId?: number) {
    const modal = await this.modalController.create({
      component: NewApprovedMaterialModalPage,
      backdropDismiss: false,
      componentProps: {
        material: material,
        provider: provider? provider: undefined,
        id: approvedMaterialId ? approvedMaterialId : undefined,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        this.loadMaterial();
      }
    });

    return await modal.present();
  }
}
