import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {ReceptionModel} from '../_models/reception.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';

@Injectable({
    providedIn: 'root'
})
export class ReceptionService {
    API_URL = `${environment.api_host}/api/reception/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    private _isLoadingOnboarding$ = new BehaviorSubject<null | boolean>(null);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    get isLoadingOnboarding$() {
        return this._isLoadingOnboarding$.asObservable();
    }

    findReceptions(queryParams?: QueryParamsModel): Observable<QueryResultsModel<ReceptionItem>> {
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `list/${limit}/${offset}`;
        return this.http.get<QueryResultsModel<ReceptionItem>>(url, {params}).pipe(
            map((res: QueryResultsModel<ReceptionItem>) => {
                const queryResults = new QueryResultsModel<ReceptionItem>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }), finalize(() => {
                if (this._isLoadingOnboarding$.getValue() === true) {
                    this._isLoadingOnboarding$.next(false)
                }

            })
        );
    }

    createReception(reception: ReceptionModel) {
        this._isLoading$.next(true);
        this._isLoadingOnboarding$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ReceptionModel>(url, reception.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createReceptionThroughXlsx(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-through-xlsx`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createReceptionThroughPdf(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-by-ocr`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateReception(reception: ReceptionModel): Observable<ReceptionModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<ReceptionModel>(url, reception.saveToAPI(), {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', reception, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    validateStockReceptionItem(body: any): Observable<any> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'validate/stock';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.post(url, body, {headers: httpHeader}).pipe(
            catchError(err => {
                return of(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<ReceptionModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<ReceptionModel>(url).pipe(
            catchError(err => {
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getProvidersFrecuents(): Observable<QueryResultsModel<ProviderModel>> {
        this._isLoading$.next(true);
        const url = this.API_URL + `providers/frecuents`;
        return this.http.get<ProviderModel[]>(url).pipe(
            map((res: ProviderModel[]) => {
                const queryResults = new QueryResultsModel<ProviderModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getMaterialsFrecuents(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>> {
        this._isLoading$.next(true);
        const url = this.API_URL + `material/frecuents`;
        return this.http.get<MaterialModel[]>(url).pipe(
            map((res: MaterialModel[]) => {
                const queryResults = new QueryResultsModel<MaterialModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getMaterialsLastUse(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>> {

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        this._isLoading$.next(true);
        const url = this.API_URL + `materials/last-use`;
        return this.http.get<MaterialModel[]>(url, {params}).pipe(
            map((res: MaterialModel[]) => {
                const queryResults = new QueryResultsModel<MaterialModel>();
                queryResults.results = res;
                queryResults.total = res.length;
                return queryResults;
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getTemplateXlsx(): any {
        const url = this.API_URL + `download-template-create`;
        return this.http.get(url, {responseType: 'blob'});
    }

    getDashboardGraph(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>> {

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        this._isLoading$.next(true);
        const url = this.API_URL + `dashboard/graph`;
        return this.http.get<any>(url, {params}).pipe(
            finalize(() => this._isLoading$.next(false))
        );

    }
}
