// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '1.4.0',
  appEnvironment: '(dev)',
  USERDATA_KEY: 'auth',
  firebase: {
    apiKey: 'AIzaSyBQFpJjNm1qCXalVdG4E9wm6PlbFSw_pyc',
    authDomain: 'total-food-control-6ea17.firebaseapp.com',
    projectId: 'total-food-control-6ea17',
    storageBucket: 'total-food-control-6ea17.appspot.com',
    messagingSenderId: '603821098928',
    appId: '1:603821098928:web:15615e196f0344c64a3875',
    measurementId: 'G-6NPE9Y6JH9'
  },
  // api_host: 'https://127.0.0.1:8000',
  api_host: 'https://totalfoodcontrol-api-devel.gooapps.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
