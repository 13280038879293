import {Component, OnDestroy, OnInit, } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ProviderModel} from '../../_models/provider.model';
import {Subscription, throwError} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators, } from '@angular/forms';
import {ProviderService} from '@pages/provider/_services';
import {catchError, debounceTime, first} from 'rxjs/operators';
import {MessageType, NotificationService, } from '@core/_services/notification/notification.service';
import {nameAsyncValidator} from '@core/validators/AsyncName.validators';
import {WalkthroughComponent} from 'angular-walkthrough';
import {AuthService} from '@modules/auth';
import {notEmptyValidator} from '@core/validators/NotEmpty.validators';

@Component({
  selector: 'app-new-provider-modal',
  templateUrl: './new-provider-modal.page.html',
  styleUrls: ['./new-provider-modal.page.scss'],
})
export class NewProviderModalPage implements OnInit, OnDestroy {
  id: number;
  name: string; // Si se envía el parámetro al mostrar el modal se toma como nombre para inicializar el formulario
  isLoading$;
  provider: ProviderModel;
  formGroup: UntypedFormGroup;
  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;

  private subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private fb: UntypedFormBuilder,
    private providerService: ProviderService,
    private notificationService: NotificationService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this.providerService.isLoading$;
    this.loadProvider();
    // this.formGroup.statusChanges
    //     .pipe(debounceTime(1500))
    //     .subscribe((formValue) => {
    //         if (this.formGroup.valid) {
    //             this.continueOnboarding();
    //         }
    //     });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  loadProvider() {
    if (!this.id) {
      this.provider = new ProviderModel();
      this.loadForm();
    } else {
      const sb = this.providerService
        .getItemById(this.id)
        .pipe(
          first(),
          catchError(async (errorMessage) => {
            console.log(errorMessage);
            this.notificationService.showActionNotification(
              'COMMON.DATA_SERVER_ERROR',
              MessageType.Error
            );
            await this.modalController.dismiss(false);
            return throwError(errorMessage);
          })
        )
        .subscribe((material: ProviderModel) => {
          this.provider = ProviderModel.jsonToModel(material);
          this.loadForm();
        });
      this.subscriptions.push(sb);
    }
  }

  async closeModal() {
    await this.modalController.dismiss(false);
  }

  loadForm() {
    this.formGroup = this.fb.group({
      name: [
        this.id ? this.provider.name : this.name,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          notEmptyValidator,
        ]),
        [nameAsyncValidator(this.providerService, this.provider.id)],
      ],
      address: [
        this.provider.address,
        Validators.compose([Validators.maxLength(250), notEmptyValidator, ]),
      ],
      sanitary_registration_number: [
        this.provider.sanitary_registration_number,
        Validators.compose([Validators.minLength(5), Validators.maxLength(20), notEmptyValidator, ]),
      ],
      contact_name: [
        this.provider.contact_name,
        Validators.compose([Validators.maxLength(100), notEmptyValidator, ]),
      ],
      contact_phone: [
        this.provider.contact_phone,
        Validators.compose([
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10),
        ]),
      ],
      enabled: [this.provider.enabled],
    });
  }

  save() {
    this.prepareProvider();
    if (this.provider.id) {
      this.edit();
    } else {
      this.create();
    }
  }

  edit() {
    const sbUpdate = this.providerService
      .updateProvider(this.provider)
      .pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification(
            'COMMON.DATA_CREATED_ERROR',
            MessageType.Error
          );
          return throwError(errorMessage);
        })
      )
      .subscribe(async (res: ProviderModel) => {
        this.provider = res;
        this.notificationService.showActionNotification(
          'COMMON.DATA_CREATED',
          MessageType.Success
        );
        await this.modalController.dismiss(true);
      });
    this.subscriptions.push(sbUpdate);
  }

  create() {
    const sbCreate = this.providerService
      .createProvider(this.provider)
      .pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification(
            'COMMON.DATA_CREATED_ERROR',
            MessageType.Error
          );
          return throwError(errorMessage);
        })
      )
      .subscribe(async (res: ProviderModel) => {
        this.provider = res;
        this.notificationService.showActionNotification(
          'COMMON.DATA_CREATED',
          MessageType.Success
        );
        this.continueOnboarding();
        await this.modalController.dismiss({provider: this.provider});
      });
    this.subscriptions.push(sbCreate);
  }

  private prepareProvider() {
    const formData = this.formGroup.value;
    this.provider.name = formData.name;
    this.provider.address = formData.address;
    this.provider.sanitary_registration_number =
      formData.sanitary_registration_number;
    this.provider.contact_phone = formData.contact_phone;
    this.provider.contact_name = formData.contact_name;
    this.provider.enabled = formData.enabled;
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.dirty || control.touched;
  }

  private continueOnboarding() {
    WalkthroughComponent.walkthroughContinue();
    WalkthroughComponent.walkthroughNext();
  }
}
