import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {CookingRecipeModel} from '../_models/cooking-recipe.model';

@Injectable({
    providedIn: 'root'
})
export class CookingRecipeService {
    API_URL = `${environment.api_host}/api/cooking-recipe/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    findCookingRecipes(queryParams?: QueryParamsModel): Observable<QueryResultsModel<CookingRecipeModel>> {
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if ( queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        if ( queryParams?.sortField.length > 0 && queryParams?.sortOrder.length > 0) {
            params = params.append('sort_direction', queryParams ? queryParams?.sortOrder : '');
            params = params.append('sort_field', queryParams ? queryParams?.sortField : '');
        }

        if ( queryParams?.search.length > 0) {
        params = params.append('search_term', queryParams ? queryParams?.search : '');
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<CookingRecipeModel>>(url, {params}).pipe(
            map((res: QueryResultsModel<CookingRecipeModel>) => {
                const queryResults = new QueryResultsModel<CookingRecipeModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            })
        );
    }

    createCookingRecipe(cookingRecipe: CookingRecipeModel) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<CookingRecipeModel>(url, cookingRecipe.saveToAPI(), {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createCookingRecipeThroughXlsx(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-through-xlsx`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }
    editMassCookingRecipes(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `update-through-zip`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateCookingRecipe(cookingRecipe: CookingRecipeModel): Observable<CookingRecipeModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<CookingRecipeModel>(url, cookingRecipe.saveToAPI(), {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', cookingRecipe, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    

    getItemById(id: number): Observable<CookingRecipeModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<CookingRecipeModel>(url).pipe(
            catchError(err => {
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    validationName(value: string, id: number ): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-name/?name=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(true);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getTemplateXlsx(): any {
        const url = this.API_URL + `download-template-create`;
        return this.http.get(url, {responseType: 'blob'});
    }

    getRecipesZip(): any {
        this._isLoading$.next(true);
        const url = this.API_URL + `multiple-download-update`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            catchError(() => {
                return of(true);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }
}
