import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {NewMaterialModalPage} from './components/new-material-modal/new-material-modal.page';
import {MaterialDetailPage} from './components/material-detail/material-detail.page';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MaterialService} from './_services';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {debounceTime, tap} from 'rxjs/operators';
import {MaterialDatasource} from './_dataSource/material.datasource';
import {MaterialModel} from './_models/material.model';
import {NewMaterialThoughXlsxPage} from '@pages/material/components/new-material-though-xlsx/new-material-though-xlsx.page';
import {MatSort} from '@angular/material/sort';
import {fromEvent} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {WalkthroughComponent} from 'angular-walkthrough';

@Component({
  selector: 'app-material',
  templateUrl: './material.page.html',
  styleUrls: ['./material.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialPage implements OnInit, AfterViewInit {
  // Table fields
  columnsToDisplay = [
    'COMMON.NAME',
    'COMMON.STOCK',
    'COMMON.MIN_STOCK',
    'COMMON.MAX_STOCK',
    'COMMON.UNITS_MEASURE',
    'COMMON.DECREASE',
    'MATERIAL.MATERIAL_MODAL.FORM.FIELDS.DAYS_EXPIRATION_ALERT',
    'COMMON.ENABLED',
    'ACTIONS'];
  columnsSortName = {
    'COMMON.NAME': 'name',
    'COMMON.STOCK': 'currentStock',
    'COMMON.MIN_STOCK': 'minStock',
    'COMMON.MAX_STOCK': 'maxStock',
    'COMMON.UNITS_MEASURE': 'measurementUnit',
    'COMMON.DECREASE': 'decrease',
    'MATERIAL.MATERIAL_MODAL.FORM.FIELDS.DAYS_EXPIRATION_ALERT': 'daysExpirationAlert',
    'COMMON.ENABLED': 'enabled'
  };

  dataSource: MaterialDatasource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchBox') searchBox: ElementRef;
  searchData: any;

  // Selection
  selection = new SelectionModel<MaterialModel>(true, []);
  materialResult: MaterialModel[] = [];

  constructor(
      public modalController: ModalController,
      private translate: TranslateService,
      private materialService: MaterialService
  ) {
  }

  /** LOAD DATA */
  ngOnInit() {
    // Init DataSource
    const queryParams = new QueryParamsModel('', 'asc', '', 0, 50, {show_disabled: true});
    this.dataSource = new MaterialDatasource(this.materialService);
    // First load
    this.dataSource.loadMaterials(queryParams);
    this.dataSource.entitySubject.subscribe(res => (this.materialResult = res));
    const subscribe = this.materialService.isLoadingOnboarding$.pipe(tap((loading) => {
      console.log('materialLoading: ', loading);
      if (loading === false) {
        setTimeout(() => {
          WalkthroughComponent.walkthroughNext();
          subscribe.unsubscribe()
        }, 150);
      }
    })).subscribe()
  }

  ngAfterViewInit(): void {
    // Susbcribe to event from paginator
    this.paginator.page
        .pipe(
            tap(() => this.loadMaterialsList())
        )
        .subscribe();

    // Susbcribe to event from paginator
    this.sort.sortChange
        .pipe(
            tap(() => {
              const queryParams = new QueryParamsModel('', this.sort.direction, this.columnsSortName[this.sort.active], 0, this.paginator.pageSize, {show_disabled: true});
              this.dataSource.loadMaterials(queryParams);
            })
        )
        .subscribe();

    const search = fromEvent(this.searchBox.nativeElement, 'keyup');
    search.pipe(
        debounceTime(600),
        tap(() => {
          const searchTerm = this.searchData.toLocaleLowerCase();
          const queryParams = new QueryParamsModel(searchTerm, this.sort.direction, this.columnsSortName[this.sort.active], 0, this.paginator.pageSize, {show_disabled: true});
          this.dataSource.loadMaterials(queryParams);
        })).subscribe();
  }

  loadMaterialsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        this.sort.direction,
        this.columnsSortName[this.sort.active],
        this.paginator.pageIndex,
        this.paginator.pageSize,
        {show_disabled: true}
    );
    this.dataSource.loadMaterials(queryParams);
    this.selection.clear();
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: NewMaterialModalPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadMaterialsList();
      }
    });

    return await modal.present();
  }

  async openDetailModal(material: MaterialModel) {
    const modal = await this.modalController.create({
      component: MaterialDetailPage,
      backdropDismiss: false,
      componentProps: {
        id: material.id,
        measurement_unit: material.measurement_unit,
        providers: material.providers,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        this.loadMaterialsList();
      }
    });

    return await modal.present();
  }

  async openNewMaterialXlsx() {
    const modal = await this.modalController.create({
      id: 'NewMaterialThoughXlsxPage',
      component: NewMaterialThoughXlsxPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined
      }
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadMaterialsList();
      }
    });

    return await modal.present();
  }
}
