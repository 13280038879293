import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, of, Subscription, throwError} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {catchError, debounceTime, first, tap} from 'rxjs/operators';
import {MaterialService} from '@pages/material/_services';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {MatPaginator} from '@angular/material/paginator';
import {MaterialModel} from '@pages/material/_models/material.model';
import {WarehouseReceptionDatasource} from '@pages/warehouse/_dataSource/warehouseReception.datasource';
import {ReceptionService} from '@pages/reception/_services';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CookingRecipeModel} from '@pages/cooking-recipe/_models/cooking-recipe.model';
import {WarehouseService} from '@pages/warehouse/_services';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {AlertDialogComponent} from '@core/components/alert-dialog/alert-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { AuthService } from "@modules/auth";

@Component({
    selector: 'app-warehouse-reception-detail',
    templateUrl: './warehouse-reception-detail.page.html',
    styleUrls: ['./warehouse-reception-detail.page.scss'],
})
export class WarehouseReceptionDetailPage implements OnInit, OnDestroy, AfterViewInit {
    materialId: number;
    currentStock: number;
    material: MaterialModel;
    isLoadingMaterial$;
    isLoadingReceptions$;
    formGroup: UntypedFormGroup;
    reception_stock_items = new UntypedFormArray([]);
    updated = false;

    warehouseReceptionDatasource: WarehouseReceptionDatasource;

    @ViewChild('paginatorMaterial', {read: MatPaginator}) paginator: MatPaginator;

    receptionsResult: ReceptionItem[] = [];

    private subscriptions: Subscription[] = [];
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;

    constructor(
        private modalController: ModalController,
        private receptionService: ReceptionService,
        private materialService: MaterialService,
        private warehouseService: WarehouseService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.isLoadingMaterial$ = this.materialService.isLoading$;
        this.isLoadingReceptions$ = this.receptionService.isLoading$;
        this.loadMaterial();
        // Init DataSource
        const queryParams = new QueryParamsModel('', 'asc', '', 0, 10, {
            raw_material_id: this.materialId,
            positive_stock: true,
            show_products: true
        });
        this.warehouseReceptionDatasource = new WarehouseReceptionDatasource(this.receptionService);
        // First load
        this.warehouseReceptionDatasource.loadReceptions(queryParams);
        this.warehouseReceptionDatasource.entitySubject.subscribe(res => {
            this.receptionsResult = res;
            this.loadForm();
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    loadForm() {
        this.formGroup = this.fb.group({
            material_id: [this.materialId],
            reception_stock_items: this.fb.array([]),
        });
        this.reception_stock_items = this.formGroup.get('reception_stock_items') as UntypedFormArray;
        this.receptionsResult.map((item) => {
            const control = this.fb.group({
                reception_id: [item.id],
                cant: [+item.current_stock],
            });

            this.reception_stock_items.push(control);
        });
    }

    loadMaterial() {
        if (!this.materialId) {
            this.material = new MaterialModel();
        } else {
            const sb = this.materialService.getItemById(this.materialId).pipe(
                first(),
                catchError((errorMessage) => {
                    console.log('error', errorMessage);
                    return of(new MaterialModel());
                })
            ).subscribe((material: MaterialModel) => {
                this.material = MaterialModel.jsonToModel(material);
            });
            this.subscriptions.push(sb);
        }
    }

    changeStock(newValue, receptionId) {
        this.currentStock = 0;
        const formValue = this.formGroup.getRawValue();
        formValue.reception_stock_items.forEach((item) => {
            this.currentStock += item.cant;
        });
    }

    save() {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            data: {
                message: 'WAREHOUSE.WAREHOUSE_RECEPTION_DETAIL_MODAL.ALERT_DIALOG.MESSAGE',
                buttonText: {
                    confirm: 'WAREHOUSE.WAREHOUSE_RECEPTION_DETAIL_MODAL.ALERT_DIALOG.SUCCESS_BUTTON'
                }
            },
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const formData = this.formGroup.getRawValue();
                const sbUpdate = this.warehouseService.stockRegularization(formData).pipe(
                    catchError((errorMessage) => {
                        console.log(errorMessage);
                        this.notificationService.showActionNotification('COMMON.DATA_UPDATED_ERROR', MessageType.Error);
                        return throwError(errorMessage);
                    }),
                ).subscribe(async (res: CookingRecipeModel) => {
                    this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
                    await this.modalController.dismiss(true);
                });
                this.subscriptions.push(sbUpdate);
            }

        });
    }

    async

    async closeModal() {
        await this.modalController.dismiss(this.updated);
    }
}
