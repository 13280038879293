import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductionModel} from '@pages/production/_models/production.model';
import {forkJoin, of, Subscription, throwError} from 'rxjs';
import {ModalController, Platform} from '@ionic/angular';
import {LotService, ProductionService} from '@pages/production/_services';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {catchError, first} from 'rxjs/operators';
import {LotModel} from '@pages/lot/_models/lot.model';
import {MatExpansionPanel} from '@angular/material/expansion';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {ProductionDataSource} from '@pages/production/_dataSource/production.datasource';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {nameAsyncValidator} from '@core/validators/AsyncName.validators';
import { CustomDateAdapter, MY_DATE_FORMATS } from '@core/adapters/custom-date-adapter.service';


@Component({
    selector: 'app-edit-production-modal',
    templateUrl: './edit-production-modal.page.html',
    styleUrls: ['./edit-production-modal.page.scss'],
    viewProviders: [MatExpansionPanel],
    providers: [
        {
            provide: DateAdapter, 
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
    ]
})
export class EditProductionModalPage implements OnInit, OnDestroy, AfterViewInit {
    id: number;

    isLoadingProduction$;
    isLoadingLot$;
    formGroup: UntypedFormGroup;
    production_items = new UntypedFormArray([]);
    production: ProductionModel;
    lot: LotModel;
    dataSource: ProductionDataSource;
    createDateDisabled: boolean = true;
    isDeliveredChecked: boolean = false;


    private subscriptions: Subscription[] = [];

    constructor(
        private modalController: ModalController,
        private productionService: ProductionService,
        private lotService: LotService,
        public typesUtils: TypesUtilsService,
        private fb: UntypedFormBuilder,
        private dateAdapter: DateAdapter<Date>,
        private notificationService: NotificationService,
    ) {
        this.dateAdapter.setLocale('es-ES');
    }

    ngOnInit() {
        this.isLoadingProduction$ = this.productionService.isLoading$;
        this.isLoadingLot$ = this.lotService.isLoading$;
        this.loadProduction();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    isProductionDelivered() {
        if (this.id) {
            const sb = this.productionService.isProductionDelivered(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    return throwError(errorMessage);
                })
            ).subscribe((response: boolean) => {
                this.isDeliveredChecked = true;
                this.createDateDisabled = response;
                this.loadForm();
            });
            this.subscriptions.push(sb);
        }
    }

    loadProduction() {
        if (this.id) {
            const sb = this.productionService.getItemById(this.id).pipe(
                first(),
                catchError(async (errorMessage) => {
                    console.log(errorMessage);
                    this.notificationService.showActionNotification('COMMON.DATA_SERVER_ERROR', MessageType.Error);
                    await this.modalController.dismiss(false);
                    return throwError(errorMessage);
                })
            ).subscribe((production: ProductionModel) => {
                this.production = ProductionModel.jsonToModel(production);
                this.lot = LotModel.jsonToModel(production.lot);
                this.loadProductionListByLot(production.lot.id);
                this.isProductionDelivered();
            });
            this.subscriptions.push(sb);
        }
    }

    loadProductionListByLot(lot_id) {

        const queryParams = new QueryParamsModel(
            '',
            'asc',
            '',
            0,
            1000,
            {lot_id: lot_id}
        );
        this.dataSource = new ProductionDataSource(this.productionService);
        this.dataSource.loadReceptions(queryParams);

    }

    loadForm() {
        this.formGroup = this.fb.group({
            lot_name: [this.production.lot?.name, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
                [nameAsyncValidator(this.lotService, this.lot.id)]
            ],
            date_expiry: [this.production?.date_expiry, Validators.compose([Validators.required])],
            date_of_create: [{
                value: this.production.lot?.date_of_create,
                disabled: this.createDateDisabled
            }, Validators.compose([Validators.required])],
        });
    }

    async closeModal(data = false) {
        await this.modalController.dismiss(data);
    }


    async closeModalWithOK() {
        this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
        await this.wait(2500);
        await this.modalController.dismiss(true);
    }


    private prepareProduction() {
        const formData = this.formGroup.value;
        this.production.date_expiry = formData.date_expiry;
        this.lot.name = formData.lot_name;
        this.lot.date_of_create = formData.date_of_create;
    }

    save() {
        this.prepareProduction();
        if (this.production.id) {
            this.edit();
        }
    }

    edit() {
        const sbUpdate = this.lotService.updateLot(this.lot);
        const sbUpdate2 = this.productionService.updateProduction(this.production);

        forkJoin([sbUpdate, sbUpdate2]).pipe(
            catchError((errorMessage) => {
                this.notificationService.showActionNotification('COMMON.DATA_UPDATED_ERROR', MessageType.Error);
                return of(this.production);
            }),
        ).subscribe(async () => {
            this.notificationService.showActionNotification('COMMON.DATA_UPDATED', MessageType.Success);
            await this.modalController.dismiss(true);
        });
    }

    wait(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    controlHasError(validation, controlName): boolean {
        const control = this.formGroup.controls[controlName];
        return control.hasError(validation) && (control.dirty || control.touched);
    }

    getValue(field: string): any {
        const control = this.formGroup.get(field);
        return control.value;
    }

    isObject(val): boolean {
        return typeof val === 'object';
    }
}
