import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { modalEnterAnimation } from './animations/animation.enter';
import { modalLeaveAnimation } from './animations/animation.leave';
import { HttpUtilsService } from '@core/utils/http-utils.service';
import { NotificationService } from '@core/_services/notification/notification.service';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SplashScreenModule } from '@core/components/splash-screen/splash-screen.module';
import { AuthService } from '@modules/auth';
import { AuthInterceptor } from './interceptors/http.interceptor';
import { environment } from '@environments/environment';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from '@modules/notifications/_services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import '../app/core/utils/number.extensions';
import { WalkthroughModule } from 'angular-walkthrough';

function appInitializer(authService: AuthService) {
    return () => {
        return new Promise((resolve) => {
            authService.getUserByToken().subscribe().add(resolve);
        });
    };
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            modalEnter: modalEnterAnimation,
            modalLeave: modalLeaveAnimation, mode: 'ios'
        }),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot(),
        AppRoutingModule,
        MatDialogModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        SocialLoginModule,
        WalkthroughModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '603821098928-e6atqtu29sl1sd68sm5rvc5q0dsbvi74.apps.googleusercontent.com'
                        )
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HttpUtilsService,
        NotificationService,
        MessagingService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
