import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    standalone: true,
    name: 'numberHumanize'
})
export class NumberHumanize implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: number | string): string {
        if (!value) return;
        
        if (typeof value === 'string') value = parseFloat(value);

        const lang = this.translateService.currentLang;
        const thousandSeparators = {
            'es': '.',
            'en': ','
        };
        const decimalSeparators = {
            'es': ',',
            'en': '.'
        };

        return value.toString().replace('.', decimalSeparators[lang]).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparators[lang]);
    }
}
