import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeliveryModel} from '@pages/delivery/_models/delivery.model';
import {ModalController} from '@ionic/angular';
import {TypesUtilsService} from '@core/utils/types-utils.service';
import {DetailDeliveryClientModalPage} from '@pages/delivery/components/detail-delivery-client-modal/detail-delivery-client-modal.page';

@Component({
  selector: 'app-delivery-item-card',
  templateUrl: './delivery-item-card.component.html',
  styleUrls: ['./delivery-item-card.component.scss'],
})
export class DeliveryItemCardComponent implements OnInit {

  @Input() deliveryItem: any;
  @Output() callback = new EventEmitter<boolean>();


  constructor(
      public modalController: ModalController,
      public typesUtils: TypesUtilsService,
  ) { }

  ngOnInit() {
  }

  async openDetailModal(delivery_id: any) {
    const modal = await this.modalController.create({
      component: DetailDeliveryClientModalPage,
      backdropDismiss: false,
      componentProps: {
        id: delivery_id,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        // this.refreshDeliveryDataList();
      }
    });

    return await modal.present();
  }
}
