import {BaseModel} from '@core/models/_base.model';
import {format, differenceInHours} from 'date-fns';

export class NotificationModel extends BaseModel {
  id: number;
  title: string;
  createAt: Date;
  type: string;
  icon: string;
  isRead: boolean;

  static jsonToModel(object) {
    const notification = new NotificationModel();
    if (!object) { return notification; }
    notification.id = object?.id;
    notification.title = object.title;
    notification.createAt = new Date(object.createdAt);
    notification.isRead = object?.isRead || false;
    // notification.type = object.type;
    // notification.icon = object.icon;
    return notification;
  }
}
