import {BaseModel} from '@core/models/_base.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';

export class DeliveryItemHasReceptionItem extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    cant: number;
    // tslint:disable-next-line:variable-name
    reception_item: ReceptionItem;

    static jsonToModel(object) {
        const deliveryItemHasReceptionItem = new DeliveryItemHasReceptionItem();
        deliveryItemHasReceptionItem.id = object.id;
        deliveryItemHasReceptionItem.cant = +object.cant;
        deliveryItemHasReceptionItem.reception_item = ReceptionItem.jsonToModel(object.reception_item);

        return deliveryItemHasReceptionItem;
    }
}
