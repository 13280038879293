import {BaseModel} from '@core/models/_base.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';

export class ProductionItemHasReceptionItem extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    cant: number;
    // tslint:disable-next-line:variable-name
    reception_item: ReceptionItem;

    static jsonToModel(object) {
        const productionItemHasReceptionItem = new ProductionItemHasReceptionItem();
        productionItemHasReceptionItem.id = object.id;
        productionItemHasReceptionItem.cant = +object.cant;
        productionItemHasReceptionItem.reception_item = ReceptionItem.jsonToModel(object.receptionItem);

        return productionItemHasReceptionItem;
    }
}
