import {BaseModel} from '@core/models/_base.model';
import {ProductionItemHasReceptionItem} from '@pages/production/_models/productionItemHasReceptionItem.model';

export class ProductionItem extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    cant: number;
    // tslint:disable-next-line:variable-name
    production_item_has_reception_items: ProductionItemHasReceptionItem[];

    static jsonToModel(object) {
        const productionItem = new ProductionItem();
        productionItem.id = object.id;
        productionItem.cant = +object.cant;
        productionItem.production_item_has_reception_items = [];
        object?.reception_Items?.forEach((item: any) => {
            const productionItemHasReceptionItem = ProductionItemHasReceptionItem.jsonToModel(item);
            productionItem.production_item_has_reception_items.push(productionItemHasReceptionItem);
        });

        return productionItem;
    }
}
