import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

export function validateStockByQuantityValidator(service: any, id: number ): AsyncValidatorFn {

    return (control: AbstractControl ): Observable<ValidationErrors> | null => {
        const value: string = control.value;

        return service.validationRecipeCurrentStock(value, id).pipe(
            debounceTime(500),
            map( (data: any ) =>  {
                if (!data) { return ({ validateStockByQuantityValidator: true }); }
            })
        );
    };
}
