import {BaseModel} from '@core/models/_base.model';
import {DeliveryReturnItemModel} from '@pages/delivery/_models/deliveryReturnItem.model';
import {DeliveryModel} from '@pages/delivery/_models/delivery.model';

export class DeliveryReturnModel extends BaseModel {
    id: number;
    delivery: DeliveryModel;
    // tslint:disable-next-line:variable-name
    delivery_return_date: any;
    // tslint:disable-next-line:variable-name
    delivery_return_items: DeliveryReturnItemModel[];

    static jsonToModel(object) {
        const deliveryReturn = new DeliveryReturnModel();
        deliveryReturn.delivery_return_date = new Date(object.delivery_return_date);
        deliveryReturn.delivery = DeliveryModel.jsonToModel(object.delivery);
        deliveryReturn.id = object.id;
        deliveryReturn.delivery_return_items = [];
        object?.delivery_return_items?.forEach((item: any) => {
            const deliveryItem = DeliveryReturnItemModel.jsonToModel(item);
            deliveryReturn.delivery_return_items.push(deliveryItem);
        });

        return deliveryReturn;
    }

    saveToAPI(){
        const toJson = {
            id: this.id,
            delivery_id: this.delivery.id,
            delivery_return_items: []
        };

        toJson.delivery_return_items = this.delivery_return_items.map((item) => {
            // tslint:disable-next-line:variable-name
            const delivery_item =  {
                id: item?.id,
                reception_return_items: []
            };
            delivery_item.reception_return_items = item.delivery_return_item_has_reception_items.map((item) => {
                return {
                    toStock: item.cantStock,
                    toDecrease: item?.cantDecrease,
                    reception_item_id: item.reception_item.id
                };
            });
            return delivery_item;
        });
        return toJson;
    }
}
