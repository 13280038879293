import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DetailProductionCookingRecipeModalPage} from '@pages/production/components/detail-production-cooking-recipe-modal/detail-production-cooking-recipe-modal.page';
import {CookingRecipeModel} from '@pages/cooking-recipe/_models/cooking-recipe.model';

@Component({
  selector: 'app-produced-recipes-card',
  templateUrl: './produced-recipes-card.component.html',
  styleUrls: ['./produced-recipes-card.component.scss'],
})
export class ProducedRecipesCardComponent implements OnInit {

  @Input() cookingRecipe: CookingRecipeModel;
  @Output() callback = new EventEmitter<boolean>();

  allergens: any;

  constructor(
      public modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  async openDetailModal(production_id: any) {
    const modal = await this.modalController.create({
      component: DetailProductionCookingRecipeModalPage,
      backdropDismiss: false,
      componentProps: {
        id: production_id,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        // this.refreshProductionDataList();
      }
    });

    return await modal.present();
  }
}
