import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {catchError, finalize, map} from 'rxjs/operators';
import {WarehouseModel} from '../_models/warehouse.model';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {MaterialModel} from '@pages/material/_models/material.model';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService {
    API_URL = `${environment.api_host}/api/warehouse/`;

    // tslint:disable-next-line:variable-name
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    private _isLoadingOnboarding$ = new BehaviorSubject<null | boolean>(null);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    findWarehouse(queryParams?: QueryParamsModel): Observable<QueryResultsModel<WarehouseModel>> {
        // Para añadir el search o clauquier filtro solo falta añadir el queryParams en la petición
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;


        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }
        if (queryParams?.search.length > 0) {
            params = params.append('search_term', queryParams ? queryParams?.search : '');
        }

        const url = this.API_URL + `list/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<WarehouseModel>>(url, {params}).pipe(
            map((res) => {
                const queryResults = new QueryResultsModel<WarehouseModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            }), finalize(() => {
                if (this._isLoadingOnboarding$.getValue() === true) {
                    this._isLoadingOnboarding$.next(false)
                }

            })
        );
    }

    createWarehouse(warehouse: WarehouseModel) {
        this._isLoading$.next(true);
        this._isLoadingOnboarding$.next(true);
        const url = this.API_URL + `create`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<WarehouseModel>(url, warehouse, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    createWarehouseThroughXlsx(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create-through-xlsx`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    updateWarehouse(warehouse: WarehouseModel): Observable<WarehouseModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + 'update';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.patch<WarehouseModel>(url, warehouse, {headers: httpHeader}).pipe(
            catchError(err => {
                console.error('UPDATE ITEM', warehouse, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getItemById(id: number): Observable<WarehouseModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<WarehouseModel>(url).pipe(
            catchError(err => {
                // this._errorMessage.next(err);
                console.error('GET ITEM BY IT', id, err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    findWarehouseRawMaterials(queryParams?: QueryParamsModel): Observable<QueryResultsModel<MaterialModel>> {
        // Para añadir el search o clauquier filtro solo falta añadir el queryParams en la petición
        const offset = queryParams ? queryParams.pageNumber * queryParams.pageSize : 0;
        const limit = queryParams ? queryParams.pageSize : 100;

        let params = new HttpParams();
        if (queryParams?.filter) {
            params = new HttpParams({fromObject: queryParams?.filter});
        }

        const url = this.API_URL + `get-raw-materials/${limit}/${offset}/`;
        return this.http.get<QueryResultsModel<MaterialModel>>(url, {params}).pipe(
            map((res) => {
                const queryResults = new QueryResultsModel<MaterialModel>();
                queryResults.results = res.results;
                queryResults.total = res.total;
                return queryResults;
            })
        );
    }

    validationName(value: string, id: number): Observable<boolean> {
        this._isLoading$.next(true);
        const url = this.API_URL + `validate-name/?name=${value}&id=${id}`;
        return this.http.get<boolean>(url).pipe(
            catchError(() => {
                return of(true);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getTemplateXlsx(): any {
        const url = this.API_URL + `download-template-create`;
        return this.http.get(url, {responseType: 'blob'});
    }


    stockRegularization(data: any): Observable<any> {
        this._isLoading$.next(true);
        const url = this.API_URL + `stock-regularization`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.patch<any>(url, data, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err.error);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    get isLoadingOnboarding$() {
        return this._isLoadingOnboarding$.asObservable();
    }
}
