import { Animation, createAnimation } from '@ionic/angular';

export const modalEnterAnimation = (
    baseEl: HTMLElement,
): Animation => {
    const root = baseEl.shadowRoot;
    const backdropAnimation = createAnimation()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', 0.01, 'var(--backdrop-opacity)')
        .beforeStyles({
            'pointer-events': 'none'
        })
        .afterClearStyles(['pointer-events']);

    const wrapperAnimation = createAnimation()
        .addElement(root.querySelectorAll('.modal-wrapper')!)
        .beforeStyles({ 'opacity': 1 })
        .fromTo('transform', 'translateX(100vh)', 'translateX(0vh)');

    const baseAnimation = createAnimation()
        .addElement(baseEl)
        .easing('cubic-bezier(0.32,0.72,0,1)')
        .duration(500)
        .addAnimation(wrapperAnimation);

    baseAnimation.addAnimation(backdropAnimation);

    return baseAnimation;

};
