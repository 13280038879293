import { BaseModel } from '@core/models/_base.model';



export class ResultControlChecklist extends BaseModel {
    id: number;
    checklistItem_id: number;
    result: string;
    value: string;
}

export class ResultsChecklist extends BaseModel {
    id: number;
    media: number;
    description: string;
    checklist_id: number;
    entity: string;
    entity_id: number;
    resultsControlsChecklist: ResultControlChecklist[];


    constructor() {
        super();
        this.id = null;
        this.media = null;
        this.description = null;
        this.checklist_id = null;
        this.entity = null;
        this.entity = null;
        this.resultsControlsChecklist = [];
        return this;
    }

    static jsonToModel(object) {
        const resultChecklist = new ResultsChecklist();
        resultChecklist.id = object.id;
        resultChecklist.media = object.media;
        resultChecklist.description = object.description;
        resultChecklist.resultsControlsChecklist = [];
        object?.resultsControlsChecklist?.forEach((item: any) => {
            const resultControl = new ResultControlChecklist();
            resultControl.id = item.id;
            resultControl.checklistItem_id = item.checklistItem_id;
            resultControl.result = item.result;
            resultControl.value = item.value;
            resultChecklist.resultsControlsChecklist.push(resultControl);
        });
        return resultChecklist;
    }

}
