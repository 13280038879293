import {UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';

export const rowMaterialValidators: ValidatorFn = (fg: UntypedFormGroup) => {
    const cant: number = fg.get('cant').value;
    const itemReceptions: ReceptionItem[] = fg.get('items_receptions').value  || [];

    let itemReceptionTotal = 0;

    itemReceptions.forEach(item => {
       itemReceptionTotal = (itemReceptionTotal + item.cant).toFixedEpsilon();
    });

    return cant !== null && cant === itemReceptionTotal
        ? null
        : { rowMaterial: true };
};
