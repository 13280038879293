import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {
    @Input() id: number;
    @Input() name: string;
    @Input() showAllergens = true;
    @Input() allergens: number;
    @Input() showHomologate = true;
    @Input() homologate: boolean;
    @Input() lines: string;
    @Input() clickable: boolean;
    @Input() styles: any;
    @Output() callback = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit() {
    }

    onSelect(value) {
        this.callback.emit(value);
    }

}
