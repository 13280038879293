import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UpdateProfileRequest, UserModel} from '@modules/auth';
import {environment} from '@environments/environment';
import {AuthModel} from '../../_models/auth.model';
import {RegisterModel} from '@modules/auth/register/_models/register.model';
import {CompanyModel} from '@modules/auth/register-company/_models/company.model';
import {ChangePasswordModel} from '@modules/auth/change-password/_models/change_password.model';

const API_USERS_URL = `${environment.api_host}/api/user`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient) {
    }

    // public methods
    login(email: string, password: string): Observable<any> {
        return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {username: email, password});
    }

    loginWithGoogle(id_token: string, access_token: string): Observable<any> {
        return this.http.post<AuthModel>(`${API_USERS_URL}/auth-google`, {
            credentials: {
                id_token: id_token,
                access_token: access_token
            }
        });
    }

    register(register: RegisterModel): Observable<any> {
        return this.http.post<RegisterModel>(`${API_USERS_URL}/register`, {
            register: register,
        });
    }

    registerCompany(company: CompanyModel): Observable<any> {
        return this.http.post<CompanyModel>(`${API_USERS_URL}/register-company`, {
            company,
        });
    }

    changePassword(changePassword: ChangePasswordModel): Observable<any> {
        return this.http.post<ChangePasswordModel>(`${API_USERS_URL}/change-password`, {
            ...changePassword,
        });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(API_USERS_URL, user);
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${API_USERS_URL}/recovery-password`, {
            email,
        });
    }

    getUserByToken(token): Observable<UserModel> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<UserModel>(`${API_USERS_URL}/profile`, {
            headers: httpHeaders,
        });
    }

    registerToken(token): Observable<any> {
        return this.http.post<any>(`${API_USERS_URL}/register-token`, {
            token,
        });
    }

    updateUser(user: UpdateProfileRequest): Observable<UpdateProfileRequest> {
        return this.http.post<UpdateProfileRequest>(`${API_USERS_URL}/update-profile`, user);
    }
}
