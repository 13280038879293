export class QueryResultsModel<T> {
    // fields
    results: T[];
    total: number;
    errorMessage: string;

    // tslint:disable-next-line:variable-name
    constructor(_results: T[] = [], _errorMessage: string = '') {
        this.results = _results;
        this.total = _results.length;
    }
}
