import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, } from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

export function nameAsyncValidator(service: any, id: number ): ValidatorFn {

    return (control: AbstractControl ): Observable<ValidationErrors> | null => {
        const value: string = control.value;

        return service.validationName(value, id).pipe(
            debounceTime(500),
            map( (data: any ) =>  {
                if (!data) { return ({ AsyncNameInValid: true }); }
            })
        );
    };
}
