import {BaseModel} from '@core/models/_base.model';
import {DeliveryItemModel} from '@pages/delivery/_models/deliveryItem.model';
import {format} from "date-fns";
import {ClientModel} from '@pages/client/_models/client.model';

export class DeliveryModel extends BaseModel {
    id: number;
    delivery_return_id: number;
    // tslint:disable-next-line:variable-name
    client: ClientModel;
    // tslint:disable-next-line:variable-name
    date_delivery: any;
    // tslint:disable-next-line:variable-name
    checklist_id: number;
    // tslint:disable-next-line:variable-name
    delivery_items: DeliveryItemModel[];

    static jsonToModel(object) {
        const client = ClientModel.jsonToModel(object.client);
        const delivery = new DeliveryModel();
        delivery.id = object.id;
        delivery.delivery_return_id = object.delivery_return_id;
        delivery.client = client;
        delivery.date_delivery = new Date(object.date_delivery);
        delivery.checklist_id = object.checklist_id;
        delivery.delivery_items = [];
        object?.delivery_items?.forEach((item: any) => {
            const deliveryItem = DeliveryItemModel.jsonToModel(item);
            delivery.delivery_items.push(deliveryItem);
        });

        return delivery;
    }

    saveToAPI(){
        const toJson = {
            id: this.id,
            client_id: this.client.id,
            date_delivery: format(this.date_delivery, 'yyyy-MM-dd'),
            deliveries_items: []
        };

        toJson.deliveries_items = this.delivery_items.map((item) => {
            const delivery_item =  {
                id: item?.id,
                cant: item.cant,
                receptions_items: []
            };
            delivery_item.receptions_items = item.delivery_item_has_reception_items.map((item) => {
                return {
                    id: item?.id,
                    cant: item.cant,
                    reception_item_id: item.reception_item.id
                };
            });
            return delivery_item;
        });

        return toJson;
    }
}
