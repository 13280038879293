import {AfterViewInit, Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MatPaginator} from '@angular/material/paginator';
import {ProviderService} from './_services';
import {ProviderModel} from './_models/provider.model';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {ProviderDataSource} from './_dataSource/provider.datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {debounceTime, tap} from 'rxjs/operators';
import {NewProviderModalPage} from './components/new-provider-modal/new-provider-modal.page';
import {NewProviderThoughXlsxPage} from '@pages/provider/components/new-provider-though-xlsx/new-provider-though-xlsx.page';
import {MatSort} from '@angular/material/sort';
import {fromEvent} from 'rxjs';
import {WalkthroughComponent} from 'angular-walkthrough';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.page.html',
  styleUrls: ['./provider.page.scss'],
})
export class ProviderPage implements OnInit, AfterViewInit {
  // Table fields
  columnsToDisplay = [
    'COMMON.NAME',
    'COMMON.ADDRESS',
    'PROVIDER.PROVIDER_PAGE.HEALTH_REGISTER',
    'PROVIDER.PROVIDER_PAGE.CONTACT',
    'PROVIDER.PROVIDER_PAGE.PHONE_NUMBER',
    'COMMON.ENABLED',
    'ACTIONS',
  ];
  columnsSortName = {
    'COMMON.NAME': 'name',
    'COMMON.ADDRESS': 'address',
    'PROVIDER.PROVIDER_PAGE.HEALTH_REGISTER': 'sanitaryRegistrationNumber',
    'PROVIDER.PROVIDER_PAGE.CONTACT': 'contactName',
    'PROVIDER.PROVIDER_PAGE.PHONE_NUMBER': 'contactPhone',
    'COMMON.ENABLED': 'enabled',
  };
  dataSource: ProviderDataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchBox') searchBox: ElementRef;

  searchData: any;

  // Selection
  selection = new SelectionModel<ProviderModel>(true, []);
  providerResult: ProviderModel[] = [];

  constructor(
      public modalController: ModalController,
      private providerService: ProviderService
  ) {
  }

  /** LOAD DATA */
  ngOnInit() {
    // Init DataSource
    const queryParams = new QueryParamsModel('', 'asc', '', 0, 50, {
      show_disabled: true,
    });
    this.dataSource = new ProviderDataSource(this.providerService);
    // First load
    this.dataSource.loadProviders(queryParams);
    this.dataSource.entitySubject.subscribe(
        (res) => (this.providerResult = res)
    );
    const subscribe = this.providerService.isLoadingOnboarding$.pipe(tap((loading) => {
      if (loading === false) {
        setTimeout(() => {
          WalkthroughComponent.walkthroughNext();
          subscribe.unsubscribe()
        }, 150);
      }
    })).subscribe()
  }

  ngAfterViewInit(): void {
    // Susbcribe to event from paginator
    this.paginator.page.pipe(tap(() => this.loadProviderList())).subscribe();

    // Susbcribe to event from paginator
    this.sort.sortChange
        .pipe(
            tap(() => {
              const queryParams = new QueryParamsModel(
                  '',
                  this.sort.direction,
                  this.columnsSortName[this.sort.active],
                  0,
                  this.paginator.pageSize,
                  {show_disabled: true}
              );
              this.dataSource.loadProviders(queryParams);
            })
        )
        .subscribe();

    const search = fromEvent(this.searchBox.nativeElement, 'keyup');
    search
        .pipe(
            debounceTime(600),
            tap(() => {
              const searchTerm = this.searchData.toLocaleLowerCase();
              const queryParams = new QueryParamsModel(
                  searchTerm,
                  this.sort.direction,
                  this.columnsSortName[this.sort.active],
                  0,
                  this.paginator.pageSize,
                  {show_disabled: true}
              );
              this.dataSource.loadProviders(queryParams);
            })
        )
        .subscribe();
  }

  loadProviderList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
        '',
        this.sort.direction,
        this.columnsSortName[this.sort.active],
        this.paginator.pageIndex,
        this.paginator.pageSize,
        {show_disabled: true}
    );
    this.dataSource.loadProviders(queryParams);
    this.selection.clear();
  }

  async openModal(provider?: ProviderModel) {
    const modal = await this.modalController.create({
      component: NewProviderModalPage,
      backdropDismiss: false,
      componentProps: {
        id: provider ? provider.id : undefined,
      },
      cssClass: 'right-side-modal-css',
    });

    modal.onDidDismiss().then((updated) => {
      if (updated.data) {
        this.loadProviderList();
      }
    });

    return await modal.present();
  }

  async openNewProviderXlsx() {
    const modal = await this.modalController.create({
      id: 'NewProviderThoughXlsxPages',
      component: NewProviderThoughXlsxPage,
      backdropDismiss: false,
      cssClass: 'right-side-modal-css',
      componentProps: {
        id: undefined,
      },
    });

    modal.onDidDismiss().then((response) => {
      if (response.data) {
        this.loadProviderList();
      }
    });

    return await modal.present();
  }
}
