import {BaseModel} from '@core/models/_base.model';
import {DeliveryItemHasReceptionItem} from '@pages/delivery/_models/deliveryItemHasReceptionItem.model';

export class DeliveryItemModel extends BaseModel{
    id: number;
    // tslint:disable-next-line:variable-name
    cant: number;
    // tslint:disable-next-line:variable-name
    delivery_item_has_reception_items: DeliveryItemHasReceptionItem[];

    static jsonToModel(object) {
        const deliveryItem = new DeliveryItemModel();
        deliveryItem.id = object.id;
        deliveryItem.cant = +object.cant;
        deliveryItem.delivery_item_has_reception_items = [];
        object?.reception_items?.forEach((item: any) => {
            const deliveryItemHasReceptionItem = DeliveryItemHasReceptionItem.jsonToModel(item);
            deliveryItem.delivery_item_has_reception_items.push(deliveryItemHasReceptionItem);
        });

        return deliveryItem;
    }
}
