import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@environments/environment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {HttpUtilsService} from '@core/utils/http-utils.service';
import {ChecklistModel} from '@core/models/checklist/checklist.model';
import {ResultsChecklist} from '@core/models/checklist/resultsChecklist.model';


@Injectable({
    providedIn: 'root'
})
export class ChecklistService {
    API_URL = `${environment.api_host}/api/checklist/`;
    API_URL_MASTER = `${environment.api_host}/api/master/`;

    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) {
    }

    get isLoading$() {
        return this._isLoading$.asObservable();
    }

    getResultsChecklist(id: number): Observable<ChecklistModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `reception/show/${id}`;
        let params = new HttpParams();
        return this.http.get<ChecklistModel>(url).pipe(
            catchError(err => {
                console.error('GET CHECKLIST BY ID', id, err);
                return of(new ChecklistModel());
            }),
            finalize(() => {
                this._isLoading$.next(false);
            })
        );
    }

    setResponseChecklist(resultChecklist: ResultsChecklist) {
        this._isLoading$.next(true);
        const url = this.API_URL + `create/`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ChecklistModel>(url, resultChecklist, {headers: httpHeaders}).pipe(
            catchError(err => {
                console.error('CREATE ITEM', err);
                return throwError(err);
            }),
            finalize(() => this._isLoading$.next(false))
        );
    }

    getChecklistById(id: number): Observable<ChecklistModel> {
        this._isLoading$.next(true);
        const url = this.API_URL + `show/${id}`;
        return this.http.get<ChecklistModel>(url).pipe(
            catchError(err => {
                console.error('GET CHECKLIST BY ID', id, err);
                return of(new ChecklistModel());
            }),
            finalize(() => {
                this._isLoading$.next(false)
            })
        );
    }


    uploadImage(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL_MASTER + `upload-image`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                return throwError(err);
            }),
            finalize(() => {
                this._isLoading$.next(false);
            })
        );
    }

    uploadFile(formData: any) {
        this._isLoading$.next(true);
        const url = this.API_URL_MASTER + `upload-file`;
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(url, formData, {headers: httpHeaders}).pipe(
            catchError(err => {
                return throwError(err);
            }),
            finalize(() => {
                this._isLoading$.next(false);
            })
        );
    }
}
