import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject} from 'rxjs';
// import {QueryParamsModel} from '../query-models/query-params.model';
// import {QueryResultsModel} from '../query-models/query-results.model';
import * as _ from 'lodash';
import {BaseModel} from '../_base.model';

// Why not use MatTableDataSource?
/*  In this example, we will not be using the built-in MatTableDataSource because its designed for filtering,
	sorting and pagination of a client - side data array.
	Read the article: 'https://blog.angular-university.io/angular-material-data-table/'
**/

export class BaseDataSource implements DataSource<BaseModel> {
    entitySubject = new BehaviorSubject<any[]>([]);
    hasItems = false; // Need to show message: 'No records found

    // Loading | Progress bar
    loadingSubject = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean>;

    // Paginator | Paginators count
    paginatorTotalSubject = new BehaviorSubject<number>(0);
    paginatorTotal$: Observable<number>;

    constructor() {
        this.loading$ = this.loadingSubject.asObservable();
        this.paginatorTotal$ = this.paginatorTotalSubject.asObservable();
        this.paginatorTotal$.subscribe(res => this.hasItems = res > 0);
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        // Connecting data source
        return this.entitySubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        // Disonnecting data source
        this.entitySubject.complete();
        this.loadingSubject.complete();
        this.paginatorTotalSubject.complete();
    }
    // tslint:disable-next-line:variable-name
    sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {
        if (!_sortField) {
            return _incomingArray;
        }

        let result: any[] = [];
        result = _incomingArray.sort((a, b) => {
            if (a[_sortField] < b[_sortField]) {
                return _sortOrder === 'asc' ? -1 : 1;
            }

            if (a[_sortField] > b[_sortField]) {
                return _sortOrder === 'asc' ? 1 : -1;
            }

            return 0;
        });
        return result;
    }

    // tslint:disable-next-line:variable-name
    searchInArray(_incomingArray: any[], _queryObj: any, _filtrationFields: string[] = []): any[] {
        const result: any[] = [];
        let resultBuffer: any[] = [];
        const indexes: number[] = [];
        let firstIndexes: number[] = [];
        let doSearch = false;

        _filtrationFields.forEach(item => {
            if (item in _queryObj) {
                _incomingArray.forEach((element, index) => {
                    if (element[item] === _queryObj[item]) {
                        firstIndexes.push(index);
                    }
                });
                firstIndexes.forEach(element => {
                    resultBuffer.push(_incomingArray[element]);
                });
                _incomingArray = resultBuffer.slice(0);
                resultBuffer = [].slice(0);
                firstIndexes = [].slice(0);
            }
        });

        Object.keys(_queryObj).forEach(key => {
            const searchText = _queryObj[key].toString().trim().toLowerCase();
            if (key && !_.includes(_filtrationFields, key) && searchText) {
                doSearch = true;
                try {
                    _incomingArray.forEach((element, index) => {
                        // tslint:disable-next-line:variable-name
                        const _val = element[key].toString().trim().toLowerCase();
                        if (_val.indexOf(searchText) > -1 && indexes.indexOf(index) === -1) {
                            indexes.push(index);
                        }
                    });
                } catch (ex) {
                    console.log(ex, key, searchText);
                }
            }
        });

        if (!doSearch) {
            return _incomingArray;
        }

        indexes.forEach(re => {
            result.push(_incomingArray[re]);
        });

        return result;
    }
}
