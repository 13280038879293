import {UntypedFormGroup, ValidatorFn} from '@angular/forms';

export function returnCantValidators(total: number): ValidatorFn {
    return (fg: UntypedFormGroup) => {
        const cantStock = fg.get('cantStock').value;
        const cantDecrease = fg.get('cantDecrease').value;

        return (cantStock + cantDecrease) <= total
            ? null
            : {returnCant: true};
    };
}
