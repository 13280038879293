import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AuthService } from "@modules/auth";
import { ModalController, NavController } from "@ionic/angular";
import { NotificationsPage } from "@modules/notifications/notifications.page";
import { NotificationService } from "@modules/notifications/_services";
import { MessagingService } from "@modules/notifications/_services/messaging.service";
import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  mostrarMenu = false;

  @Input() title: string;
  @Input() initials: string;
  @Input() showBackButton = false;
  userName: string;
  numNotifi = 0;
  messageSubcription: Subscription;

  constructor(
    private authService: AuthService,
    private modalController: ModalController,
    private notificationService: NotificationService,
    private meesageService: MessagingService,
    public cd: ChangeDetectorRef,
    private router: Router,
    private _navCtrl: NavController,
  ) {}

  ngOnDestroy(): void {
    this.messageSubcription.unsubscribe();
  }

  ngOnInit() {
    const currentUser = this.authService.currentUserValue;
    this.initials = currentUser.profile.username[0].toUpperCase();
    this.userName =
      currentUser.profile.first_name + " " + currentUser.profile.last_name;
    this.reloadBadget();
    this.messageSubcription = this.meesageService.getMessage$.subscribe(
      (changes: any) => {
        if (changes !== null) {
          this.reloadBadget();
        }
      }
    );
  }

  reloadBadget() {
    this.notificationService.getBagdeNotifications().subscribe(
      (response: any) => {
        this.numNotifi = response?.total || 0;
        this.cd.detectChanges();
      },
      (_) => {
        this.numNotifi = 0;
        this.cd.detectChanges();
      }
    );
  }

  async openNotificationListModal() {
    const modal = await this.modalController.create({
      id: "NotificationsPage",
      component: NotificationsPage,
      backdropDismiss: false,
      cssClass: "right-side-modal-css",
    });

    return await modal.present();
  }

  mostrarOpciones() {
    this.mostrarMenu = !this.mostrarMenu;
  }

  openVideosPage() {
    this._navCtrl.navigateForward(["/tutorial-list"]);
  }

  public restartOnBoarding() {
    this.authService.updateProfile(true).subscribe(
      (response) => {
        this.router.navigate(['/home']).then(() => {
            window.location.href = window.location.href;
          });
      },
      (error) => {
        console.error("Error updating onboarding status:", error);
      }
    );
  }
}
