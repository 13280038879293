import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ReceptionService} from '@pages/reception/_services';
import * as fileSaver from 'file-saver';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {Subscription, throwError} from 'rxjs';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {MatStepper} from '@angular/material/stepper';
import {DetailReceptionMaterialModalPage} from '@pages/reception/components/detail-reception-material-modal/detail-reception-material-modal.page';
import { AuthService } from "@modules/auth";

@Component({
    selector: 'app-new-reception-through-xlsx',
    templateUrl: './new-reception-through-xlsx.page.html',
    styleUrls: ['./new-reception-through-xlsx.page.scss'],
})
export class NewReceptionThroughXlsxPage implements OnInit {
    private subscriptions: Subscription[] = [];
    reception: ReceptionModel;
    uploadForm: UntypedFormGroup;
    hasChange = false;
    currentUser = this.authService.currentUserValue;
    showPopover = this.currentUser.profile.show_onboarding;
    
    @ViewChild('myPond') myPond: any;
    @ViewChild('stepper') private stepper: MatStepper;

    pondOptions = {
        credits: false,
        allowMultiple: false,
        labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
        server: {
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                this.uploadForm.get('profile').setValue(file);
                const formData = new FormData();
                formData.append('file', this.uploadForm.get('profile').value);

                const sbCreate = this.receptionService.createReceptionThroughXlsx(formData).pipe(
                    catchError((errorMessage) => {
                        this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
                        error();
                        return throwError(errorMessage);
                    }),
                ).subscribe(async (res: ReceptionModel) => {
                    this.reception = res;
                    this.hasChange = true;
                    this.stepper.next();
                    await this.wait(2500);
                    await this.openDetailModal(this.reception.id);
                    await this.modalController.dismiss(true, undefined, 'NewReceptionThroughXlsx');
                });
                this.subscriptions.push(sbCreate);

            }
        }
    }

    wait(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    constructor(
        private receptionService: ReceptionService,
        private modalController: ModalController,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            profile: ['']
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    async closeModal() {
        await this.modalController.dismiss(this.hasChange);
    }

    download() {
        this.receptionService.getTemplateXlsx().subscribe(response => {
            let blob: any = new Blob([response]);
            fileSaver.saveAs(blob, 'reception.xlsx');
        }), error => console.log('Error downloading the file'),
            () => console.info('File downloaded successfully');
    }

    async openDetailModal(reception_id) {
        const modal = await this.modalController.create({
            id: 'detail-modal',
            component: DetailReceptionMaterialModalPage,
            backdropDismiss: false,
            cssClass: 'right-side-modal-css',
            componentProps: {
                id: reception_id
            }
        });
        return await modal.present();
    }




    pondHandleInit() {
        console.log('FilePond has initialised', this.myPond);
    }

    pondHandleAddFile(event: any) {
        console.log('A file was added', event);
    }

    pondHandleActivateFile(event: any) {
        console.log('A file was activated', event)
    }

}
