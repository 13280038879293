import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, debounceTime, finalize, first, switchMap, tap} from 'rxjs/operators';
import {MaterialModel} from '@pages/material/_models/material.model';
import {ProviderModel} from '@pages/provider/_models/provider.model';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {MaterialService} from '@pages/material/_services';
import {ProviderService} from '@pages/provider/_services';
import {ReceptionService} from '@pages/reception/_services';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {MasterMeasurementUnitService} from '@common/_services';
import {isObjectValidator} from '@pages/reception/validators/isObjectValidator';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';
import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';
import {MatStepper} from '@angular/material/stepper';
import {QueryParamsModel} from '@core/models/query-models/query-params.model';
import {QueryResultsModel} from '@core/models/query-models/query-results.model';
import {WarehouseService} from '@pages/warehouse/_services';
import {MatDialog} from '@angular/material/dialog';
import {AlertDialogComponent} from '@core/components/alert-dialog/alert-dialog.component';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {WalkthroughComponent} from 'angular-walkthrough';
import {AuthService} from '@modules/auth';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter, MY_DATE_FORMATS} from '@core/adapters/custom-date-adapter.service';
import { notEmptyValidator } from '@core/validators/NotEmpty.validators';

class RowSelection {
  onSelectProvider = false;
  onSelectMaterial = false;
  completed = false;
}


@Component({
  selector: 'app-new-reception-material-modal',
  templateUrl: './new-reception-material-modal.page.html',
  styleUrls: ['./new-reception-material-modal.page.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
    },
  ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewReceptionMaterialModalPage implements OnInit, AfterViewInit, OnDestroy {
  id: number;
  isLoading$;
  isSearchProvider = true;
  isSearchMaterial = true;
  formGroup: UntypedFormGroup;
  measurementUnits: MeasurementUnitModel[] = [];
  receptionItems = new UntypedFormArray([]);
  reception: ReceptionModel;
  onSelectRow: RowSelection[] = [];
  today = new Date();
  checklistAction = 'show';
  formChecklistState = 'INVALID';
  actionExecuted = 'started';
  private subscriptions: Subscription[] = [];
  segmentModel = 'providers';
  pending = false;
  isWarehouseSelected = false;

  currentUser = this.authService.currentUserValue;
  showPopover = this.currentUser.profile.show_onboarding;


  filteredMaterialOptions: MaterialModel[];
  filteredProviderOptions: ProviderModel[];
  providerRecents: Observable<QueryResultsModel<ProviderModel>>;
  materialRecents: Observable<QueryResultsModel<MaterialModel>>;

  warehousesList: Observable<QueryResultsModel<WarehouseModel>>;
  warehouseSelect: WarehouseModel = new WarehouseModel();

  note: string;

  originalReceptionItemsLength = 0;

  @ViewChild('stepper') stepper: MatStepper;
  currentStep = 0;

  constructor(
    private modalController: ModalController,
    private materialService: MaterialService,
    private providerService: ProviderService,
    private receptionService: ReceptionService,
    private measurementUnitService: MasterMeasurementUnitService,
    private warehouseService: WarehouseService,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.isLoading$ = this.receptionService.isLoading$;
    this.loadRawMaterialRecents();
    this.loadProviderRecents();
    this.loadReception();
    this.loadMeasurements();
    this.loadWarehouse();

    // Load First Material And Provider
    this.loadFirstMaterial();
    this.loadFirstProvider();
  }

  ngAfterViewInit() {
    this.stepper.selectionChange.subscribe((event) => {
      this.currentStep = event.selectedIndex;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  async closeModal() {
    await this.modalController.dismiss(false);
  }

  stepBack() {
    this.stepper.previous();
  }

  async closeModalWithOK(stepper) {
    if (this.actionExecuted === 'created') {
      this.notificationService.showActionNotification(
        'COMMON.DATA_CREATED',
        MessageType.Success
      );
    }
    if (this.actionExecuted === 'updated') {
      this.notificationService.showActionNotification(
        'COMMON.DATA_UPDATED',
        MessageType.Success
      );
    }
    stepper.next();
    await this.wait(2500);
    await this.modalController.dismiss(true);
  }

  checkState(state: string) {
    this.formChecklistState = state;
  }

  async emitToSaveChecklist(stepper) {
    stepper.next();
    this.continueOnboarding();
    if (this.reception.checklist_id) {
      this.checklistAction = 'close';
      this.notificationService.showActionNotification(
        'COMMON.DATA_UPDATED',
        MessageType.Success
      );
    } else {
      this.checklistAction = 'save';
    }
    await this.wait(1500);
    await this.modalController.dismiss(true);
    if (this.showPopover) {
      const button = document.getElementById('new-reception-close') as HTMLButtonElement;
      button?.click();
    }
  }

  toggleClass(item) {
    item.active = !item.active;
    this.warehouseSelect = item;

    this.isWarehouseSelected = !!this.warehouseSelect;
  }

  updateNote(value: string) {
    this.note = value;
  }

  loadReception() {
    if (!this.id) {
      this.reception = new ReceptionModel();
      this.loadForm();
      this.pending = false;
    } else {
      const sb = this.receptionService
        .getItemById(this.id)
        .pipe(
          first(),
          catchError(async (errorMessage) => {
            console.log(errorMessage);
            this.notificationService.showActionNotification(
              'COMMON.DATA_SERVER_ERROR',
              MessageType.Error
            );
            await this.modalController.dismiss(false);
            return throwError(errorMessage);
          })
        )
        .subscribe((reception: ReceptionModel) => {
          this.reception = ReceptionModel.jsonToModel(reception);
          this.loadForm();
          this.pending = this.reception.pending;

          const receptionItems = this.reception.reception_items;
          const lastIndex = receptionItems.length - 1;
          receptionItems.forEach((item, index) => {
            if (item.provider && !item.provider.name) {
              item.provider = null;
            }
            if (item.raw_material && !item.raw_material.name){
              item.raw_material = null;
            }

            if (index > 0) {
              const receptionItemProvider = this.reception.reception_items[index - 1].provider;
              const receptionItemRawMaterial = this.reception.reception_items[index - 1].raw_material;

              if (receptionItemProvider && receptionItemProvider.name) {
                this.onSelectRow[index - 1].onSelectProvider = true;
              }
              if (receptionItemRawMaterial && receptionItemRawMaterial.name) {
                this.onSelectRow[index - 1].onSelectMaterial = true;
              }
              if (receptionItemProvider && receptionItemProvider.name && receptionItemRawMaterial && receptionItemRawMaterial.name) {
                if (index - 1 !== lastIndex) {
                  this.onSelectRow[index - 1].completed = true;
                }
              }
            } else {
              if (item.wharehouse.id) {
                this.warehouseSelect = item.wharehouse;
              }
            }
            this.addReceptionItem();
          });
          const lastReceptionItemProvider = this.reception.reception_items[this.reception.reception_items.length - 1].provider;
          const lastReceptionItemRawMaterial = this.reception.reception_items[this.reception.reception_items.length - 1].raw_material;
          if (lastReceptionItemProvider && lastReceptionItemProvider.name) {
            this.onSelectRow[this.reception.reception_items.length - 1].onSelectProvider = true;
          }
          if (lastReceptionItemRawMaterial && lastReceptionItemRawMaterial.name) {
            this.onSelectRow[this.reception.reception_items.length - 1].onSelectMaterial = true;
          }

          this.formGroup.patchValue(this.reception);
          this.formGroup.markAllAsTouched();

          this.originalReceptionItemsLength = receptionItems.length;
        });
      this.subscriptions.push(sb);
    }
  }

  loadForm() {
    this.formGroup = this.fb.group({
      delivery_number: [
        this.reception.delivery_number,
        Validators.compose([Validators.required, notEmptyValidator]),
      ],
      delivery_date: [
        this.reception?.delivery_date,
        Validators.compose([Validators.required]),
      ],
      reception_items: this.fb.array([])
    });

    this.receptionItems = this.formGroup.get('reception_items') as UntypedFormArray;
    if (!this.id) {
      this.addReceptionItem();
    }
  }


  addReceptionItem() {
    const control = this.fb.group({
      id: [],
      provider: [
        '',
        Validators.compose([Validators.required, isObjectValidator()]),
      ],
      raw_material: [
        '',
        Validators.compose([Validators.required, isObjectValidator()]),
      ],
      cant: ['', Validators.compose([Validators.required])],
      // measurement_unit: [null],
      num_lote: ['', Validators.compose([Validators.required, notEmptyValidator])],
      date_expiry: ['', Validators.compose([Validators.required])],
    });
    this.receptionItems.push(control);

    control
      ?.get('provider')
      .valueChanges.pipe(
      debounceTime(300),
      tap(() => (this.isSearchProvider = true)),
      switchMap((value) =>
        this.providerService
          .findProviders(new QueryParamsModel(value))
          .pipe(finalize(() => (this.isSearchProvider = false)))
      )
    )
      .subscribe((data) => (this.filteredProviderOptions = data.results));

    control
      ?.get('raw_material')
      .valueChanges.pipe(
      debounceTime(300),
      tap(() => (this.isSearchMaterial = true)),
      switchMap((value) =>
        this.materialService
          .findMaterials(
            new QueryParamsModel(value, 'asc', '', 0, 50, {
              show_products: false,
            })
          )
          .pipe(finalize(() => (this.isSearchMaterial = false)))
      )
    )
      .subscribe((data) => (this.filteredMaterialOptions = data.results));

    this.onSelectRow.push(new RowSelection());
  }

  save(stepper: MatStepper) {
    this.prepareReception();
    if (this.reception.id) {
      this.edit(stepper);
    } else {
      this.create(stepper);
    }
  }

  private prepareReception() {
    const formData = this.formGroup.value;
    this.reception.delivery_number = formData.delivery_number;
    this.reception.delivery_date = formData.delivery_date;
    this.reception.note = this.note;
    this.reception.reception_items = [];
    formData.reception_items.map((item) => {
      this.reception.reception_items.push({
        id: item.id,
        cant: item.cant,
        date_expiry: item.date_expiry,
        num_lote: item.num_lote,
        provider: item.provider as ProviderModel,
        raw_material: item.raw_material as MaterialModel,
        wharehouse: this.warehouseSelect as WarehouseModel,

      } as ReceptionItem);
    });
  }

  wait(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  edit(stepper: MatStepper) {
    const sbUpdate = this.receptionService
      .updateReception(this.reception)
      .pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification(
            'COMMON.DATA_CREATED_ERROR',
            MessageType.Error
          );
          return throwError(errorMessage);
        })
      )
      .subscribe((res: ReceptionModel) => {
        this.reception = res;
        this.actionExecuted = 'updated';
        stepper.next();
      });
    this.subscriptions.push(sbUpdate);
  }

  create(stepper: MatStepper) {
    const sbCreate = this.receptionService
      .createReception(this.reception)
      .pipe(
        catchError((errorMessage) => {
          console.log(errorMessage);
          this.notificationService.showActionNotification(
            'COMMON.DATA_CREATED_ERROR',
            MessageType.Error
          );
          return throwError(errorMessage);
        })
      )
      .subscribe((res: ReceptionModel) => {
        this.reception = res;
        this.actionExecuted = 'created';
        this.continueOnboarding();
        stepper.next();
        this.id = res.id;
      });
    this.subscriptions.push(sbCreate);
  }

  onAddReception(currentIndex: number) {
    this.onSelectRow[currentIndex].completed = true;
    return this.addReceptionItem();
  }

  onRemoveReception(currentIndex: number) {
    this.onSelectRow[currentIndex - 1].completed = false;
    this.onSelectRow.splice(currentIndex, 1);
    this.receptionItems.removeAt(currentIndex);
  }

  onSelectAutoCompleted(index: number, value, field: string) {
    if (field === 'provider') {
      this.onSelectRow[index].onSelectProvider = true;
    }
    if (field === 'raw_material') {
      this.onSelectRow[index].onSelectMaterial = true;
    }
  }

  loadRawMaterialRecents() {
    this.materialRecents = this.receptionService.getMaterialsFrecuents();
  }

  loadProviderRecents() {
    this.providerRecents = this.receptionService.getProvidersFrecuents();
  }

  loadMeasurements() {
    this.measurementUnitService.findMeasurementUnit().subscribe(
      (data) => {
        this.measurementUnits = data;
      },
      (error) => {
        console.log('ERROR:' + error);
      }
    );
  }

  loadWarehouse() {
    this.warehousesList = this.warehouseService.findWarehouse();
  }

  stepperNext(stepper: MatStepper) {
    if (stepper.selectedIndex === 0) {
      let showModal = false;
      const formData = this.formGroup.value;

      // if some num_lote is empty set variable to show modal
      formData.reception_items.forEach((item) => {
        if (item.num_lote.length === 0) {
          showModal = true;
          return;
        }
      });
      if (showModal) {
        this.openAlertNoNumLoteDialog(() => {
          // if show modal variable is true all the empty num_lote set to delivery number
          formData.reception_items.forEach((item) => {
            if (item.num_lote.length === 0) {
              item.num_lote = formData.delivery_number;
            }
          });
          this.continueOnboarding();
          this.validationStock(stepper);
        });
      } else {
        this.continueOnboarding();
        this.validationStock(stepper);
      }
    } else if (stepper.selectedIndex === 2) {
      this.save(stepper);
    } else {
      stepper.next();
    }
  }

  validationStock(stepper: MatStepper) {
    if (this.reception.id) {
      // Validamos la modificación del stock y si todo es correcto mandamos a guardar
      const body = this.receptionItems
        .getRawValue()
        .filter((item) => item.id)
        .map((item) => {
          return {
            reception_item_id: item.id,
            stock: item.cant,
          };
        });
      this.receptionService
        .validateStockReceptionItem(body)
        .subscribe((response) => {
          const errors = Object.keys(response?.error || []);
          if (errors.length === 0) {
            stepper.next();
          } else {
            const message = this.getAlertMessageStock(response);
            this.openAlertStockValidationDialog(() => {
              stepper.next();
            }, message);
          }
        });
    } else {
      stepper.next();
    }
  }

  openAlertNoNumLoteDialog(callback) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: 'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.MESSAGE',
        buttonText: {
          cancel:
            'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.CANCEL_BUTTON',
          confirm:
            'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.SUCCESS_BUTTON',
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        callback();
      }
    });
  }

  openAlertStockValidationDialog(callback, message) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message,
        buttonText: {
          cancel:
            'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.CANCEL_BUTTON',
          confirm:
            'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.SUCCESS_BUTTON',
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        callback();
      }
    });
  }

  getAlertMessageStock(response: any) {
    const errors = Object.keys(response?.error);
    const responseError = response?.error;
    const introMessage = this.translate.instant(
      'RECEPTION.RECEPTION_MATERIAL_MODAL.ALERT_DIALOG.MESSAGE_INTRO_STOCK'
    );
    let message = `<ion-label>${introMessage}</ion-label></br></br>`;
    errors.forEach((key) => {
      const receptionItemValues = this.receptionItems
        .getRawValue()
        .filter((item) => item.id === parseInt(key, 10));
      let list = `<ion-label><strong>${receptionItemValues[0]?.num_lote}</strong>-(${receptionItemValues[0]?.cant}):</ion-label>`;
      list += '<ul>';
      responseError[key].forEach((item) => {
        list += `<li>${item?.name}${item?.num}</li>`;
      });
      list += '</ul>';
      message += list;
    });
    return message;
  }

  onProviderSelect(value) {
    const index = this.receptionItems.length - 1;
    const rowForm = (
      this.formGroup.get('reception_items') as UntypedFormArray
    ).at(index);
    rowForm.patchValue({
      provider: value,
    });
    rowForm.get('provider').markAsTouched();
    this.segmentModel = 'receptions';
    this.onSelectRow[index].onSelectProvider = true;
  }

  onClearProvider(index) {
    const rowForm = (
      this.formGroup.get('reception_items') as UntypedFormArray
    ).at(index);
    rowForm.patchValue({
      provider: '',
    });
    this.segmentModel = 'providers';
    this.onSelectRow[index].onSelectProvider = false;
  }

  onMaterialSelect(value) {
    const index = this.receptionItems.length - 1;
    const rowForm = (
      this.formGroup.get('reception_items') as UntypedFormArray
    ).at(index);
    rowForm.patchValue({
      raw_material: value,
    });
    rowForm.get('raw_material').markAsTouched();
    this.onSelectRow[index].onSelectMaterial = true;
  }

  onClearMaterial(index) {
    const rowForm = (
      this.formGroup.get('reception_items') as UntypedFormArray
    ).at(index);
    rowForm.patchValue({
      raw_material: '',
    });
    this.segmentModel = 'receptions';
    this.onSelectRow[index].onSelectMaterial = false;
  }

  loadFirstProvider() {
    const loadFirstProvider = this.providerService
      .findProviders(new QueryParamsModel())
      .pipe(finalize(() => (this.isSearchProvider = false)))
      .subscribe((data) => (this.filteredProviderOptions = data.results));
    this.subscriptions.push(loadFirstProvider);
  }

  loadFirstMaterial() {
    const loadFirstMaterial = this.materialService
      .findMaterials(
        new QueryParamsModel('', 'asc', '', 0, 50, {show_products: false})
      )
      .pipe(finalize(() => (this.isSearchMaterial = false)))
      .subscribe((data) => (this.filteredMaterialOptions = data.results));
    this.subscriptions.push(loadFirstMaterial);
  }

  // helpers for View
  isControlValid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlArrayValid(
    controlName: string,
    position: number,
    field: string
  ): boolean {
    const control = (
      this.formGroup.get(controlName) as UntypedFormArray
    ).controls[position].get(field);
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(controlName: string): boolean {
    const control = this.formGroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  isControlArrayInvalid(
    controlName: string,
    position: number,
    field: string
  ): boolean {
    const control = (
      this.formGroup.get(controlName) as UntypedFormArray
    ).controls[position].get(field);
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(validation, controlName): boolean {
    const control = this.formGroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  controlArrayHasError(
    validation,
    controlName,
    position: number,
    field: string
  ): boolean {
    const control = (
      this.formGroup.get(controlName) as UntypedFormArray
    ).controls[position].get(field);
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  getValueforArray(controlName: string, position: number, field: string): any {
    const control = (
      this.formGroup.get(controlName) as UntypedFormArray
    ).controls[position].get(field);
    return control.value;
  }

  continueOnboarding() {
    WalkthroughComponent.walkthroughContinue();
    WalkthroughComponent.walkthroughNext();
  }

  trimInputValue(event: Event, controlName:string) {
    const input = event.target as HTMLInputElement;
    this.formGroup.get(controlName).setValue(input.value.trim());
  }

  trimInputValueWithPosition(event: Event, controlName:string, position: number) {
    const input = event.target as HTMLInputElement;
    (this.formGroup.get('reception_items') as UntypedFormArray).controls[position].get(controlName).setValue(input.value.trim());
  }
}
