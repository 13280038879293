import {Injectable} from '@angular/core';
import {HttpParams, HttpHeaders} from '@angular/common/http';
import * as _ from 'lodash';
// import { QueryResultsModel } from '../models/query-models/query-results.model';
// import { QueryParamsModel } from '../models/query-models/query-params.model';

@Injectable()
export class HttpUtilsService {

    static hasPermission(authArr, userRole) {
        /**
         * If auth array is not defined
         * Pass and allow
         */
        if (authArr === null || authArr === undefined) {
            // console.info("auth is null || undefined:", authArr);
            return true;
        }
        if (authArr.length === 0) {
            /**
             * if auth array is empty means,
             * allow only user role is guest (null or empty[])
             */
            // console.info("auth is empty[]:", authArr);
            return !userRole || userRole.length === 0;
        }
        /**
         * Check if user has grants
         */
        // console.info("auth arr:", authArr);
        /*
            Check if user role is array,
            */
        if (userRole && Array.isArray(userRole)) {
            return authArr.some(r => userRole.indexOf(r) >= 0);
        }

        /*
            Check if user role is string,
            */
        return authArr.includes(userRole);
    }

    getFindHTTPParams(queryParams): HttpParams {
        const params = new HttpParams()
            .set('lastNamefilter', queryParams.filter)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString());

        return params;
    }

    getHTTPHeaders(): HttpHeaders {
        const result = new HttpHeaders();
        result.set('Content-Type', 'application/json');
        return result;
    }
}
