import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {ReceptionService} from '@pages/reception/_services';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {Subscription, throwError} from 'rxjs';
import {ReceptionModel} from '@pages/reception/_models/reception.model';
import {MessageType, NotificationService} from '@core/_services/notification/notification.service';
import {AuthService, UserModel} from '@modules/auth';
import {NewReceptionMaterialModalPage} from '@pages/reception/components/new-reception-material-modal/new-reception-material-modal.page';

@Component({
    selector: 'app-new-reception-through-pdf',
    templateUrl: './new-reception-through-pdf.page.html',
    styleUrls: ['./new-reception-through-pdf.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NewReceptionThroughPdfPage implements OnInit, OnDestroy  {
    private subscriptions: Subscription[] = [];

    id: number;
    reception: ReceptionModel;
    uploadForm: UntypedFormGroup;
    hasChange = false;
    currentUser: UserModel = this.authService.currentUserValue;
    showPopover: boolean = this.currentUser.profile.show_onboarding;
    isPdfFile = false;
    uploadedFile: File | null = null;
    updated = false;


    @ViewChild('myPond') myPond: any;

    pondOptions = {
        credits: false,
        allowMultiple: false,
        acceptedFileTypes: ['application/pdf'],
        labelIdle: this.translate.instant('COMMON.FILEPOND.DROP_FILES'),
        server: {
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                this.uploadForm.get('profile').setValue(file);
                const formData = new FormData();
                formData.append('file', this.uploadForm.get('profile').value);

                const sbCreate = this.receptionService.createReceptionThroughPdf(formData).pipe(
                    catchError((errorMessage) => {
                        console.log(errorMessage.error);
                        this.notificationService.showActionNotification('COMMON.FILEPOND.INVALID_FILE', MessageType.Error);
                        error();
                        return throwError(errorMessage);
                    }),
                ).subscribe(async (res: ReceptionModel) => {
                    this.reception = res;
                    this.hasChange = true;
                    await this.editReception(this.reception.id);
                    await this.modalController.dismiss(true, undefined, 'NewReceptionThroughPdf');
                });
                this.subscriptions.push(sbCreate);

            }
        }
    };

    wait(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    constructor(
        private receptionService: ReceptionService,
        private modalController: ModalController,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private notificationService: NotificationService,
        private authService: AuthService,
    ) {
    }

    ngOnInit() {
        this.uploadForm = this.formBuilder.group({
            profile: ['']
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    async closeModal() {
        await this.modalController.dismiss(this.hasChange);
    }
  async editReception(receptionId: any) {

    const modal = await this.modalController.create({
      component: NewReceptionMaterialModalPage,
      cssClass: 'right-side-modal-css',
      backdropDismiss: false,
      componentProps: {
        id: receptionId
      }
    });

    modal.onDidDismiss().then((response) => {
      this.hasChange = true;
      this.closeModal();
    });

    return await modal.present();
  }


    pondHandleInit() {
        console.log('FilePond has initialised', this.myPond);
    }

    pondHandleActivateFile(event: any) {
        console.log('A file was activated', event);
    }

    pondHandleAddFile(event: any) {
        const file = event.file.file;
        if (file.type === 'application/pdf') {
            console.log('A file was added', event);
            this.uploadedFile = file;
            this.isPdfFile = true;
        } else {
            this.isPdfFile = false;
            this.uploadedFile = null;
        }
    }
    //
    // async onScanClick() {
    //     const formData = new FormData();
    //     formData.append('file', this.uploadedFile);
    //
    //     const sbCreate = this.receptionService.createReceptionThroughPdf(formData).pipe(
    //         catchError((errorMessage) => {
    //             this.notificationService.showActionNotification('COMMON.DATA_CREATED_ERROR', MessageType.Error);
    //             return throwError(errorMessage);
    //         }),
    //     ).subscribe(async (res: ReceptionModel) => {
    //         this.reception = res;
    //         this.hasChange = true;
    //         await this.wait(2500);
    //         await this.editReception(this.reception.id);
    //         await this.modalController.dismiss(true, undefined, 'NewReceptionThroughPdf');
    //     });
    //     this.subscriptions.push(sbCreate);
    // }

}
