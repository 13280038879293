import {BaseModel} from '@core/models/_base.model';
import {MeasurementUnitModel} from '@common/_models/measurement-unit.model';
import {CookingRecipeItemModel} from '@pages/cooking-recipe/_models/cooking-recipe-item.model';

export class CookingRecipeModel extends BaseModel {
    id: number;
    name: string;
    enabled = true;
    // tslint:disable-next-line:variable-name
    cant_total: number;
    // tslint:disable-next-line:variable-name
    unit_total: number;
    current_stock: number;
    // tslint:disable-next-line:variable-name
    measurement_unit: MeasurementUnitModel;
    // tslint:disable-next-line:variable-name
    cooking_recipe_items: CookingRecipeItemModel[];
    homologate: boolean;
    // tslint:disable-next-line:variable-name
    days_expiration_alert: number;
    expiration_days: number;
    isTemp = false;
    save_as_recipe = false;

    static jsonToModel(object) {
        const cookingRecipeModel = new CookingRecipeModel();
        cookingRecipeModel.id = object.id;
        cookingRecipeModel.name = object.name;
        cookingRecipeModel.enabled = object.enabled;
        cookingRecipeModel.cant_total = object.cant_total;
        cookingRecipeModel.unit_total = object.unit_total;
        cookingRecipeModel.current_stock = +object.current_stock;
        cookingRecipeModel.days_expiration_alert = object.days_expiration_alert;
        cookingRecipeModel.expiration_days = object.expiration_days;
        cookingRecipeModel.measurement_unit = new MeasurementUnitModel();
        cookingRecipeModel.measurement_unit.id = object?.measurement_unit?.id;
        cookingRecipeModel.measurement_unit.name = object?.measurement_unit?.name;
        cookingRecipeModel.homologate = object?.homologate || false;
        cookingRecipeModel.cooking_recipe_items = [];
        cookingRecipeModel.save_as_recipe = object.save_as_recipe;

        object?.cooking_recipe_items?.forEach((item) => {
            const receptionItem = CookingRecipeItemModel.jsonToModel(item);
            cookingRecipeModel.cooking_recipe_items.push(receptionItem);
        });

        return cookingRecipeModel;
    }

    public saveToAPI() {
        const toJson =  {
            id: this.id,
            name: this.name,
            enabled: this.enabled,
            cant_total: this.cant_total,
            unit_total: this.unit_total,
            days_expiration_alert: this.days_expiration_alert,
            expiration_days: this.expiration_days,
            measurement_unit_id: this.measurement_unit?.id,
            cooking_recipe_items : [],
            is_temp: this.isTemp,
            save_as_recipe: this.save_as_recipe
        };

        toJson.cooking_recipe_items = this.cooking_recipe_items.map(item => {
            return {
                id: item?.id,
                raw_material_id: item.raw_material.id,
                cant: item.cant,
            };
        });
        return toJson;
    }
}
