import {BaseModel} from '@core/models/_base.model';
import {ReceptionItem} from '@pages/reception/_models/receptionItem.model';
import {format, parse, toDate} from 'date-fns';
import { MatNativeDateModule } from '@angular/material/core';
// import {MaterialModel} from '@pages/material/_models/material.model';
// import {WarehouseModel} from '@pages/warehouse/_models/warehouse.model';

export class ChecklistItem extends BaseModel{
    id:number;
    name: string;
    type:string;
}

export class ChecklistModel extends BaseModel {
    id: number;
    name: string;
    date: Date;
    periodicity: object;
    version: string;
    type: string;
    checklistItems: ChecklistItem[];

    static jsonToModel(object) {
        const checklist = new ChecklistModel();
        checklist.id = object.id;
        checklist.name = object.name;
        checklist.date = object.date;
        checklist.periodicity = object.periodicity;
        checklist.version = object.version;
        checklist.type = object.type;
        checklist.checklistItems = [];
        object?.checklistItems?.forEach((item: any) => {
            const checklistItem = new ChecklistItem();
            checklistItem.id = item.id;
            checklistItem.name = item.name;
            checklistItem.type = item.type;
            checklist.checklistItems.push(checklistItem);
        });
        return checklist;
    }

    saveToAPI(){
        const toJson = {
            id: this.id,
            name: this.name,
            date: this.date,
            periodicity: this.periodicity,
            version: this.version,
            type: this.type,
            checklistItems:this.checklistItems
        };
        return toJson;
    }
}
